import { defineStore } from "pinia";
import axios from "axios"
import { useProfileStore } from "@/stores/profiles"
import ApiService from "@/core/services/apiServicev3";
import { useProductStore } from "@/stores/products";
import useApp from "@/composables/v2/useApp";
import { useAppStore } from "../app";
import { useAppComponentsStore } from "../app/components/components";

interface RootState {
  isLoading: boolean
}

export const useLabelsStore = defineStore({
  id: "labelsStore",
  state: () =>
    ({
      isLoading: false,
    } as RootState),
  getters: {
  },
  actions: {
    async createLabel(settings) {
      const payload = {
        label_type: settings.labelType === 'shipment' ? 'forward' : 'return',
        sale_id: settings.saleId,
        delivery_method: settings.source === 'internal' ? 'pickup' : 'send',
        pickup_location_id: settings.locationId,
        reference: settings.reference,
        connection_id: settings.connectionId,
        aantal: settings.aantal,
        laaddatum: settings.laaddatum,
        bezorgdatum: settings.bezorgdatum,
        toeslag: settings.toeslag,
        gewicht: settings.gewicht,
        BP: settings.options?.BP === 1 ? 1 : null,
        mailbox: settings.options?.mailbox === 1 ? 1 : null
      }

      Object.keys(payload).forEach(key => {
        if (payload[key] === null || payload[key] === '') {
          delete payload[key];
        }
      });

      const { data } = await ApiService.post('apiUrl', 'label', payload);

      return {
        status: 'success',
        data: { 
          taskId: data.task_id,
          itemId: data.item_id,
        }
      }
    }
  }
});