import { defineStore } from "pinia";
import axios from "axios"
import useHelpers from '@/views/pos/composables/useHelpers';
import { usePaymentsStore } from "@/views/pos/stores/payments";
import posConfig from "@/views/pos/config/pos";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/apiServicev3";
import { useCartStore } from "./cart";
import { Modal } from "bootstrap";
import { useCustomerCartStore } from "./customer";
import { useAppStore } from "@/stores/app";
import { useAuthStore } from "@/stores/auth";
import { useContentModalsStore } from "@/stores/content/modals";
import { useInvoicesStore } from "./invoices";
import { directPrint } from "@/utils/directPrint";
import { getPdfUrl } from "@/utils/getPdfUrl";
import { useContentNotificationsStore } from "@/stores/content/notifications";

const { createDate } = useHelpers();

interface Document {
  id: number
}

interface RootState {
  current: null | any
  cashiers: null | any
  profile: null | any
  isLockedIn: boolean
  isLoading: boolean
  paymentMethods: null | any
  paymentActions: null | any
  actionLoading: boolean
  token: null | any
  userLock: any
  session: any
  menuAction: any
}

export const usePOSStore = defineStore({
  id: "posStore",
  state: () =>
  ({
    current: null,
    cashiers: null,
    profile: null,
    isLockedIn: false,
    isLoading: true,
    paymentMethods: [],
    paymentActions: null,
    actionLoading: false,
    token: null,
    userLock: { state: false, token: null, pin: { code1: null, code2: null, code3: null, code4: null }, isLoading: false },
    session: { current: null, countAmount: null, isLoading: false },
    menuAction: { items: [], current: null, renderAmount: 0, isLoading: false }
  } as RootState),
  getters: {
    currentPOS(state) {
      return state.current;
    },
    posStatus(state) {
      return state.current?.pos_status;
    },
    loadingStatus(state) {
      return state.isLoading;
    },
    currentPOSId(state) {
      return state.current?.id;
    },
    pinTerminalId(state) {
      return state.current?.default_pin_terminal_id;
    },
    availablePaymentMethods(state) {
      return state.paymentMethods.filter(method => method.isUsable);
    },
    posProfile(state) {
      return state.profile;
    },
    cashiersStatus(state) {
      return state.cashiers;
    },
    currentCashierId(state) {
      return state.current?.cashier_id;
    },
    currentSessionId(state) {
      return state.current?.pos_session_id;
    },
    sessionCountAmount(state) {
      return state.session.countAmount;
    },
    currentPosName(state) {
      return state.current?.name;
    },
    sessionLoadingState(state) {
      return state.session.isLoading;
    },
    currentMenuAction(state) {
      return state.menuAction.current;
    },
    currentMenuActionRenderAmount(state) {
      return state.menuAction.renderAmount
    },
    currentProfileId(state) {
      return state.current?.profile_id;
    },
    billingCountryCode(state) {
      return state.profile?.billing_country_code;
    },
    currentCashierUserId(state) {
      return state.current?.user_id;
    },
    currentCashierDisplayName(state) {
      return state.current?.cashier_name;
    },
    isAutoPrintReceipt(state) {
      return state.current?.auto_print_a6;
    },
    isAutoPrintInvoice(state) {
      return state.current?.auto_print_a4;
    },
    printerReceiptId(state) {
      return state.current?.default_printer_a6_id;
    },
    printerInvoiceId(state) {
      return state.current?.default_printer_a4_id;
    }
  },
  actions: {
    async initPos(posId: number): Promise<void> {
      const authStore = useAuthStore();

      try {
        this.initShortcuts();
        await this.getPOS(posId);
        const cashier = await this.getCashiers(authStore.userId);
        await this.getProfile();

        if (cashier && cashier.access_pos === 1 && this.current.pos_status === 1) {
          if (this.currentCashierUserId === authStore.userId || this.current.user_id == null) {
            JwtService.updatePosToken(authStore.userId, cashier.id, cashier.display_name);
            this.isLockedIn = true;
            if (this.current.user_id == null) {
              await this.lockPos(posId);
            }
          } else {
            this.resetSession();
          }
        } else {
          this.resetSession();
        }
      } catch (error) {
        this.resetSession();
      } finally {
        this.isLoading = false;
      }
    },
    async getCashiers(userId: number) {
      try {
        const payload = {
          sort: ['id:desc'],
          matchingStrategy: 'all',
          facets: ['*'],
          filter: [
            [`user_id=${userId}`]
          ],
          offset: 0,
          limit: 20
        };

        const { data } = await ApiService.post('apiUrlSearch', `/indexes/pos_cashiers/search`, payload);
        return data.hits.length ? data.hits[0] : null;
      }
      finally {
        //
      }
    },
    async resetMenuAction() {
      this.menuAction.current = null
    },
    async getProfile() {
      const appStore = useAppStore();
      try {
        await ApiService.get('apiUrlSearch', `/indexes/profiles/documents/${this.current.profile_id}`)
          .then(({ data }) => {
            this.profile = data;
          })
      }
      finally {
        //
      }
    },
    async getCashier(userId: number) {
      const cashier = this.cashiers?.find(cashier => cashier.user_id === userId) || null;
      return cashier;
    },
    async getPOS(posId: number): Promise<void> {

      try {
        const { data } = await ApiService.get('apiUrlSearch', `/indexes/pos/documents/${posId}`);
        this.current = data;
      } catch (err) {
        //
      }
    },
    async createPaymentMethods() {
      const appStore = useAppStore();
      const cartStore = useCartStore();
      const customerStore = useCustomerCartStore();

      // const globalPaymentMethods = appStore.getConfig('payment_methods');
      const globalPaymentMethods = posConfig.paymentMethods;
      const posPaymentMethods = JSON.parse(this.current?.payment_methods) ?? []
      const availablePaymentMethods = globalPaymentMethods
        .filter(({ cId, id }) => posPaymentMethods.includes(cId ?? id))
        .reverse()
        .map(method => ({ ...method, isUsable: true }));
      // if (customerStore.onAccountStatus && cartStore.outstandingAmount > customerStore.onAccountFreeSpace) {
      //   availablePaymentMethods = availablePaymentMethods.filter((paymentMethod) => paymentMethod.id !== 'onAccount');
      // }

      // this.paymentMethods = availablePaymentMethods;
      this.paymentMethods = availablePaymentMethods;

      this.updateUsabilityPaymentMethods();
    },
    updateUsabilityPaymentMethods() {
      const cartStore = useCartStore();
      const customerStore = useCustomerCartStore();
      const onAccountMethod = this.paymentMethods.find(m => m.id === 'onAccount');

      if (onAccountMethod) {

        if (!customerStore.onAccountStatus || customerStore.onAccountStatus && cartStore.outstandingAmount > customerStore.onAccountBalance) {

          onAccountMethod.isUsable = false;
        } else {
          onAccountMethod.isUsable = true;
        }
      }
    },
    resetSession() {
      this.$reset();
      this.router.push({ path: '/pos' })
    },
    async lockPos(posId: number) {
      try {
        await ApiService.post('apiUrl', `/pos/lock/${posId}`);
      }
      finally {
        //
      }
    },
    async unlockPos(posId: number) {
      const appStore = useAppStore();

      const response = {
        status: 'error'
      }

      try {
        const { data } = await ApiService.post('apiUrl', `/pos/unlock/${posId}`);

        if (data.task_id) {
          await appStore.taskStatus(data.task_id);

          response.status = 'success';
        }
      }
      catch (error) {
        //
      }

      return response;
    },
    async createSale() {
      const payload = {
        pos_id: this.current.id,
        profile_id: this.current.profile_id,
        customer_id: this.current.default_customer_id,
        sale_date: createDate(),
        sale_status: 'concept'
      }

      try {
        const { data } = await ApiService.post('apiUrl', '/sale', payload);
        return data;
      }
      finally {
        //
      }
    },
    async processNewSale() {
      const paymentStore = usePaymentsStore();
      const posStore = usePOSStore();
      const contentModalStore = useContentModalsStore();

      paymentStore.isLoading = true;
      const { data } = await posStore.createSale();
      const document = await posStore.validateDocument(data.id, 'sales');

      if (document && document.id) {
        contentModalStore.closeModal('es_modal_pay_create');
        this.router.push({ path: '/pos/' + posStore.currentPOSId + '/checkout/' + data.id });
      }

      paymentStore.isLoading = false;
    },
    async deleteSale(saleId: number) {
      try {
        await ApiService.delete('apiUrl', '/sales', { id: saleId });
      }
      catch (error) {
        //
      }
    },
    createPaymentActions() {
      const actionItems = [] as any;

      for (const action of posConfig.paymentActions) {
        let active = true;
        if (action.config.check.state) {
          const fieldCheckPos = action.config.check.field;

          if (!this.current[fieldCheckPos]) {
            active = false;
          }
        }

        if (active) {
          const item = {
            label: action.label,
            icon: action.icon,
            isLoading: false,
            config: {
              operation: action.config.operation,
              type: action.config.type,
              endpoint: action.config.endpoint
            }
          }

          actionItems.push(item);
        }
      }

      this.paymentActions = actionItems;
    },
    async loginApp(userEmail: string, userPassword: string) {
      const payload = { email: userEmail, password: userPassword };
      const token = await axios.post("/user/create-token", payload)
        .then(({ data }) => {
          this.token = data.result.token;
        })
    },
    async verifyAuthPincode(cashierId: number, pinCode: string) {
      const payload = { pincode: pinCode };

      try {
        const data = await ApiService.post("apiUrl", `/pos/validate/cashier/${cashierId}`, payload);
        return data;
      }
      catch (err) {
        //
      }
    },
    async checkTaskStatus(taskId: number): Promise<void> {
      const appStore = useAppStore();
      let amountChecks = 0;
      return new Promise<void>((resolve, reject) => {
        const interval = setInterval(async () => {
          try {
            const response = await ApiService.get('apiUrlSearch', `/indexes/queue_tasks/documents/${taskId}`);
            const { data } = response;
            if (data.task_status === 3) {
              clearInterval(interval);
              resolve();
            }
            amountChecks += 1;
            if (amountChecks === posConfig.taskTotalChecks) {
              clearInterval(interval);
              reject(new Error('Maximum number of checks reached'));
            }
          } catch (error: any) {
            if (error && error.response.status === 404) {
              return;
            }
            clearInterval(interval);
            reject(error);
          }
        }, posConfig.tasksCheckTimer);
      });
    },
    // async directPrint(type: string, pdfURL: string) {
    //   this.actionLoading = true;

    //   const connectionId = type == 'invoice' ? this.currentPOS.default_printer_a4_id : type == 'receipt' ? this.currentPOS.default_printer_a6_id : ''

    //   const payload = {
    //     connection_id: connectionId,
    //     pdf_url: pdfURL
    //   };

    //   try {
    //     await ApiService.post('apiUrl', "/direct-print", payload);
    //   }
    //   finally {
    //     this.actionLoading = false;
    //   }
    // },
    async lockUser() {

      this.userLock.state = true;
      this.userLock.token = JwtService.getToken();

      JwtService.updatePosToken(this.currentCashierUserId, this.currentCashierId, this.currentCashierDisplayName);

      await this.unlockPos(this.currentPOSId);
      JwtService.destroyToken();

      this.router.push({ name: 'sign-in' });
    },
    async processUserLock(pinSection, event) {
      const pinValue = Number(this.userLock.pin[pinSection]);
      if (isNaN(pinValue) == true) {
        this.userLock.pin[pinSection] = null;
      } else {
        const codes = this.userLock.pin
        let pinCode = '';
        for (const code in codes) {
          if (codes[code]) {
            pinCode += codes[code]
          }
        }

        if (pinCode.length === 4) {
          this.isLoading = true;
          try {
            JwtService.saveToken(this.userLock.token);
            const status = await this.verifyAuthPincode(this.currentCashierId, pinCode);
            if (status?.data.code === 200) {
              const modal = document.getElementById('es_pos_lock');
              const instance = Modal.getOrCreateInstance(modal);
              instance.toggle();
              this.userLock.state = false;

              await this.lockPos(this.currentPOSId);
            } else {
              const input = document.getElementById('lock_code_1');
              input?.focus();
            }
          }

          finally {
            this.userLock.pin.code1 = null;
            this.userLock.pin.code2 = null;
            this.userLock.pin.code3 = null;
            this.userLock.pin.code4 = null;
            this.isLoading = false;
          }
        } else {
          event.nextSibling.focus();
        }
      }
    },
    async posSession(sessionId: number) {
      try {
        const { data } = await ApiService.get('apiUrlSearch', `/indexes/pos_sessions/documents/${sessionId}`)
        this.session.current = data;
      }
      finally {
        //
      }
    },
    async posSessionClose(payload) {
      const { data } = await ApiService.post('apiUrl', `/pos/session/close`, payload);
      return data;
    },
    async posSessionOpen(payload) {
      const { data } = await ApiService.post('apiUrl', '/pos/session/', payload);
      return data;
    },
    async processOpenClose() {
      const contentModalStore = useContentModalsStore();

      this.session.isLoading = true;

      try {
        if (this.currentSessionId) {
          await this.posSessionClose({ pos_session_id: this.currentSessionId, end_cash_amount: this.session.countAmount })
          this.router.push({ path: '/pos' });

        } else {
          const task = await this.posSessionOpen({ start_cash_amount: this.session.countAmount, pos_id: this.currentPOSId })
          await this.checkTaskStatus(task.task_id);
        }
      }
      finally {
        await this.getPOS(this.currentPOSId);
        this.session.countAmount = null;
        this.session.isLoading = false;

        contentModalStore.closeModal('es_modal_pos_open_close');
      }
    },
    async processMenuAction(action) {
      this.menuAction.current = action.process
      this.menuAction.renderAmount += 1
    },
    async validateDocument(documentId: number, indexName: string): Promise<Document | null> {
      const appStore = useAppStore();
      return new Promise<Document | null>((resolve, reject) => {
        let amountChecks = 0;
        const interval = setInterval(async () => {
          amountChecks += 1;
          if (amountChecks === 100) {
            clearInterval(interval);
            resolve(null); // Resolve with null if payment_url is not found after 100 checks
          } else {
            try {
              const response = await ApiService.get('apiUrlSearch', `/indexes/${indexName}/documents/${documentId}`)
              const { data } = response;
              if (data) {
                clearInterval(interval);
                resolve(data); // Resolve with data when the document is found
              }
            } catch (error: any) {
              if (error && error.response.status === 404) {
                return;
              }
              clearInterval(interval);
              reject(error);
            }
          }
        }, posConfig.tasksCheckTimer);
      });
    },
    async processCompleteActions(action) {
      action.isLoading = true;

      try {
        const invoiceStore = useInvoicesStore();
        const notificationStore = useContentNotificationsStore();

        if (action.config.operation === 'openReceipt') {
          if (invoiceStore.current.pdf_pos_receipt_url) {
            window.open(invoiceStore.current.pdf_pos_receipt_url, '_blank');
          }
        } else if (action.config.operation === 'openInvoice') {
          if (invoiceStore.current.pdf_url) {

            window.open(invoiceStore.current.pdf_url, '_blank');
          }
        } else if (action.config.operation === 'directPrint') {
          const connectionId = action.config.type == 'invoice' ? this.currentPOS.default_printer_a4_id : action.config.type == 'receipt' ? this.currentPOS.default_printer_a6_id : '';
          const response = await directPrint(connectionId, getPdfUrl(action.config.endpoint, invoiceStore.current?.uid));

          if (response.status === 'error') {
            notificationStore.setNotification('Fout', response.message, response.status);
          } else {
            notificationStore.setNotification('Succesvol', 'Printopdracht is succesvol verstuurd', response.status);
          }
        }
      }
      catch (error) {
        //
      }
      finally {
        action.isLoading = false;
      }
    },
    initShortcuts() {
      const shortcutsItems = [] as any;

      for (const item of posConfig.shortcuts) {
        const template = {
          label: item.label,
          action: item.action,
          icon: item.icon,
          session: item.session,
          isLoading: false,
        }
        shortcutsItems.push(template);
      }

      this.menuAction.items = shortcutsItems;
    },
    async initAutoPrint() {
      const cartStore = useCartStore();
      const notificationStore = useContentNotificationsStore();

      if (this.isAutoPrintReceipt && cartStore.isCompleted) {
        notificationStore.setNotification('Succesvol', 'Printopdracht kassabon is succesvol verstuurd', 'success');
      }
    }
  }
});