export default function useHelpers() {
  const createDate = () => {
    const dateTime = new Date();
    dateTime.setMilliseconds(0);
    dateTime.toISOString();
    return dateTime;
  }

  const roundToDecimalPlaces = (number, decimalPlaces = 2, countryCode?, totalVat = false, roundUp = true) => {
    const multiplier = 10 ** decimalPlaces;
    let roundedAmount;

    if (roundUp) {
      roundedAmount = Math.round(number * multiplier) / multiplier; // Afronden naar boven
    } else {
      roundedAmount = Math.floor(number * multiplier) / multiplier; // Afronden naar beneden (standaard)
    }

    if (totalVat) {
      // Als totalVat true is, rond het bedrag volgens de Nederlandse BTW-regels af
      return roundToDecimalPlaces(roundedAmount, 2, countryCode);
    }

    // Als totalVat false is, rond het bedrag normaal af
    return roundedAmount;
  }

  function roundToTwoDecimals(amount, countryCode) {
    if (countryCode === 'NL') {
      const roundedAmount = amount * 100; // Vermenigvuldig het bedrag met 100 om het derde cijfer achter de komma te krijgen
      const roundedInteger = Math.round(roundedAmount);
      return roundedInteger / 100;
    }
  }

  const removeSpaces = (value: string | number) => {
    if (value === null) {
      return null;
    }

    if (typeof value === "number") {
      return value.toString().replace(/\s/g, "");
    } else {
      return value.replace(/\s/g, "");
    }
  }

  return {
    createDate,
    roundToDecimalPlaces,
    removeSpaces
  }
}