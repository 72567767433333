import { defineStore } from "pinia";
import axios from "axios"
import { Composer } from 'vue-i18n';
import { useAppStore } from "./app";
import { useContentModalsStore } from "./content/modals";

interface RootState {
  searchValue: any
  results: any
  amountResults: any
  isLoading: boolean
  $i18n: any
}

let timeoutHandler;

export const useSearchStore = defineStore({
  id: "searchStore",
  state: () =>
    ({
      searchValue: null,
      results: null,
      amountResults: 0,
      isLoading: false,
      $i18n: {},
    } as RootState),
  getters: {
    searchStatus(state) {
      return state.amountResults > 0 ? true : false
    },
    i18n(): Composer {
      return this.$i18n.global as Composer;
    },
  },
  actions: {
    async search() {
      const appStore = useAppStore();
      this.isLoading = true;
      this.results = [];
      this.amountResults = 0;
    
      if (this.searchValue.length <= 2 || !this.searchValue) {
        // this.$reset();
        return;
      }
    
      clearTimeout(timeoutHandler);
    
      const payload = {
        q: this.searchValue,
        sort: ["id:desc"],
        matchingStrategy: "all",
        facets: ["*"],
        filter: [],
        offset: 0,
        limit: 20
      };
    
      try {
        const searchEndpoints = [
          {
            endpoint: `${appStore.searchApiEndpoint}/indexes/sales/search`,
            title: "titles.Sales",
            path: "/sales",
            icon: "handcart",
            fields: ["id", "reference", "customer_name", "sale_status"]
          },
          {
            endpoint: `${appStore.searchApiEndpoint}/indexes/customers/search`,
            title: "titles.Customers",
            path: "/customers",
            icon: "users",
            fields: ["id", "company", "firstname", "lastname", "zipcode"]
          },
          {
            endpoint: `${appStore.searchApiEndpoint}/indexes/products/search`,
            title: "titles.Products",
            path: "/products",
            icon: "shop",
            fields: ["ean", "id", "title"]
          },
          {
            endpoint: `${appStore.searchApiEndpoint}/indexes/sales_invoices/search`,
            title: "titles.Invoices",
            path: "/sales/invoices",
            icon: "document",
            fields: ["invoice_nr", "invoice_status", "customer_name"]
          },
          {
            endpoint: `${appStore.searchApiEndpoint}/indexes/products_stocks_locations/search`,
            title: "titles.Stock_Locations",
            path: "/products/stocks/locations",
            icon: "logistic",
            fields: ["location_name"]
          }
        ];
    
        const promises = searchEndpoints.map(async (endpoint) => {
          const { data } = await axios.post(endpoint.endpoint, payload);
          const items = await this.createListItems(data.hits, endpoint.fields);
    
          return {
            section: this.trans.t(endpoint.title),
            navigationPath: endpoint.path,
            icon: endpoint.icon,
            items
          };
        });
    
        const results = await Promise.all(promises);
    
        for (const result of results) {
          this.amountResults += result.items.length;
        }
    
        this.results = results;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },    
    async createListItems(items, titleFormat) {
      const emptyList = [] as any;
      const useableItems = items.slice(0, 3);

      for (const item of useableItems) {
        const itemLayout = {
          id: item.id,
          title: this.createTitleFormat(item, titleFormat)
        }

        emptyList.push(itemLayout);
      }

      return emptyList;
    },
    createTitleFormat(item, titleFormat) {
      let i = 0
      let title = ""
      for (const part of titleFormat) {
        const partValue = item[part]
        
        i++
        if (partValue) {
          const searchValue = this.searchValue
          const regex = new RegExp(searchValue, 'gi');
          const result = partValue.toString().replace(regex, '<b>' + searchValue + '</b>');
          title += result + (i == titleFormat.length ? '' : ' - ')
        }
      }

      if (title.endsWith("- ")) {
        title.slice(0, -2);
      }

      return title;
    },
    processNavigateItem(section, item, modalId: string) {
      const contentModalStore = useContentModalsStore();
      this.router.push({ path: `${section.navigationPath}/${item.id}` });
      contentModalStore.closeModal(modalId);

      this.$reset();
    }
  }
});

