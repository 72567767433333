import ApiService from "@/core/services/apiServicev3";

export default function useFiles() {

  async function createFilelist(fileIds) {
    const optionsList = [] as any;

    try {
      if (fileIds) {
        const { data } = await ApiService.get('apiUrlSearch', `/indexes/files/documents/${fileIds}`);
        optionsList.push({ name: data.name, url: data.file_full_url });
      }
    } catch (error) {
      //
    }
    return optionsList
  }

  return {
    createFilelist
  }
}