import { defineStore } from "pinia";
import { nextTick } from "vue";
import { Modal } from "bootstrap";
import { useAppStore } from "@/stores/app";
import { FacetOption, FacetsConfig, FacetData, Facet, FormFieldData } from "@/stores/app/components/components.d"
import Swal from "sweetalert2/dist/sweetalert2.js";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/apiServicev3";
import useDateTime from "@/composables/v3/components/helpers/useDateTime"
import useFiles from "@/composables/v3/useFiles";
import { useContentStore } from "@/stores/content";
import useShortcodes from "@/composables/v3/components/helpers/useShortcodes";
import config from "@/core/config/App"
import { formatConditionals } from "./utils/formatConditionals";
import { useContentModalsStore } from "@/stores/content/modals";
import { applyConditionalRules } from "./utils/applyConditionalRules";
import { isStringOnlyNumbers } from "@/stores/app/components/utils/isStringOnlyNumbers"
import priceDisplay from "@/composables/v3/components/helpers/usePriceDisplay";
import { useContentNotificationsStore } from "@/stores/content/notifications";
import { isJsonString } from "@/utils/isJsonString";

interface RootState {
  initalizedComponents: any
  $i18n: any
}

export const useAppComponentsStore = defineStore({
  id: "appComponents",
  state: () =>
  ({
    initalizedComponents: [],
  } as RootState),
  getters: {
    currentComponent(state) {
      return (componentName) => {
        const component = state.initalizedComponents.find(component => component.componentConfig.name === componentName);
        return component ? component : false;
      };
    },
    componentState(state) {
      return (componentName) => {
        const component = state.initalizedComponents.find(component => component.componentConfig.name === componentName);
        return component ? component.state : false;
      };
    },
    cctaState(state) { // currentComponentToolbarActions
      return (componentName) => state.initalizedComponents.find(component => component.componentConfig.name === componentName).toolbar.menu.state;
    },
    ccta(state) {
      return (componentName) => state.initalizedComponents.find(component => component.componentConfig.name === componentName).toolbar.menu;
    },
    cctac(state) { // currentComponentToolbarActionsCurrent
      return (componentName) => state.initalizedComponents.find(component => component.componentConfig.name === componentName).toolbar.menu.current;
    },
    cctd(state) { // CurrentComponentTransferData
      return (componentName) => state.initalizedComponents.find(component => component.componentConfig.name === componentName).data.transferData;
    },
    cccm(state) {
      return (componentName) => state.initalizedComponents.find(component => component.componentConfig.name === componentName).modal?.current;
    },
    cctfas(state) { // current component toolbar filter active state
      return (componentName) => state.initalizedComponents.find(component => component.componentConfig.name === componentName).toolbar.filter.active;
    },
    tableData(state) {
      return (componentName) => state.initalizedComponents.find(component => component.componentConfig.name === componentName).data.config.items[0];
    }
  },
  actions: {
    async initComponent(componentConfig: any, parentComponentConfig?) {

      if (Array.isArray(componentConfig)) {
        // Als componentConfig een array is, loop er doorheen en verwerk elk configuratie-object
        for (const config of componentConfig) {
          await this.processSingleComponent(config, parentComponentConfig);
        }
      } else {
        // Als componentConfig een enkel object is, verwerk het direct
        await this.processSingleComponent(componentConfig, parentComponentConfig);
      }

    },
    processSingleComponent(componentConfig: any, parentComponentConfig?) {
      const { name: componentName } = componentConfig;

      try {

        // Add the relevant component to the initialized components.
        const componentStructure = {
          componentConfig: componentConfig, // If component builder is updated, componentConfig should be dropped as everything should be the source builder.
          builderConfig: null,
          data: { config: null, columns: null, actions: null, transferData: {}, filters: null },
          state: false,
          renderAmount: 0,
          toolbar: { menu: { state: false, title: null, items: [], current: null, renderAmount: 0 }, filter: { state: false, active: false }, search: { state: false } },
          search: null,
          isLoading: true,
          isTransferable: false,
          parent: parentComponentConfig ? parentComponentConfig.componentConfig.name : null,
          modal: { current: null, routeId: null },
          filters: null,
          debounceTimer: false,
          isActive: false
        }

        if (componentConfig.displayMethod === "new" && parentComponentConfig) {
          componentStructure.componentConfig.routeId = parentComponentConfig.componentConfig.routeId;
        } else if (componentConfig.displayMethod === "existing" && parentComponentConfig) {
          componentStructure.componentConfig.routeId = parentComponentConfig.modal.routeId;
        }

        const componentIndex = this.initalizedComponents.findIndex(component => component.componentConfig.name === componentName);

        if (componentIndex !== -1) {
          // If the component already exists in the array, replace it with the new one
          this.initalizedComponents[componentIndex] = componentStructure;
        } else {
          // Otherwise, push the new component object into the array
          this.initalizedComponents.push(componentStructure);
        }

        // Start Process Component background
        this.processComponent(componentName);

      }
      catch (error) {
        console.log(error)
      }

    },
    async searchInComponent(componentName: string, method?) {
      const component = this.findComponent(componentName);
      clearTimeout(component.debounceTimer);

      component.debounceTimer = setTimeout(async () => {

        const appStore = useAppStore();
        const searchValue = method === 'clear' ? component.search = '' : component.search

        const { dbSearch, endpoint } = component.componentConfig.dispatch;
        const currentFacets = component.data.config != null ? [...component.data.config.facets] : []
        const activeFacets = component.data.config.facets
        const filters = component.data.filters;

        const bodyParameters = {
          q: searchValue,
          matchingStrategy: "all",
          sort: [
            "id:desc"
          ],
          facets: ["*"],
          limit: dbSearch.limit,
          filter: await this.createSearchFilter(filters),
        };

        const { data } = await ApiService.post('apiUrlSearch', `/indexes/${dbSearch.indexName}/${dbSearch.indexType}`, bodyParameters);

        const template = {
          items: data.hits,
          facets: await this.createFacets(data.facetDistribution, endpoint, currentFacets, null),
          totalHits: data.estimatedTotalHits,
          lastUsedFilter: null,
          offset: data.offset,
          limit: data.limit,
          currentPage: data.offset === 0 ? 1 : data.offset / data.limit + 1
        }

        component.data.config = template
      }, config.timerTableSearch);
    },
    findComponent(componentName) {
      return this.initalizedComponents.find(component => component.componentConfig.name === componentName);
    },
    async createTags(config: any, columns: any) {
      const appStore = useAppStore();

      const exists = columns.some(column => column.type === 'status');
      let tagExists;

      if (exists) {
        tagExists = config.items.some(item => Object.prototype.hasOwnProperty.call(item, 'tags') && item.tags !== null);
      }

      if (tagExists) {
        const bodyParameters = {
          q: '',
          sort: ["id:desc"],
          matchingStrategy: "all",
          facets: ["*"],
          offset: 0,
          limit: 100,
        };

        const { data } = await ApiService.post('apiUrlSearch', `/indexes/tags/search`, bodyParameters);
        return data.hits;
      }
    },
    async processComponent(componentName: string) {
      const component = this.findComponent(componentName);

      component.isLoading = true;

      if (component) {
        component.builderConfig = await this.builderComponentConfig(componentName);
        const { builderConfig, componentConfig, data } = component;

        switch (componentConfig.type) {
          case 'table':
            data.config = await this.createComponentData(componentConfig);
            data.columns = JSON.parse(builderConfig.columns);
            data.actions = await this.createTableActions(component);
            data.tags = await this.createTags(data.config, data.columns);
            await this.initComponentToolbar(componentName);
            break;
          case 'form':
            data.config = await this.createComponentFormData(component);
            break;
          case 'tree':
            data.config = await this.createComponentTreeData(componentConfig);
            // data.columns = JSON.parse(builderConfig.columns);
            data.actions = await this.createTableActions(component);
            await this.initComponentToolbar(componentName);
            break;
          case 'custom':
            data.config = await this.createComponentData(componentConfig);
            data.actions = await this.createTableActions(component);
            await this.initComponentToolbar(componentName);
            break;
          default:
        }

        component.isActive = builderConfig.toggle === 'open' ? true : false;
        component.isLoading = false;
        component.state = true;
      }
    },
    async createComponentTreeData(componentConfig: any, offset?: number | null) {
      const appStore = useAppStore();
      const component = this.findComponent(componentConfig.name);
      const activeFacets = component.data.config?.facets || []

      const componentFilters = component.builderConfig.filters ? await this.createComponentFilters(JSON.parse(component.builderConfig.filters), component.componentConfig) : []
      const tableFilters = await this.createTableFilters(activeFacets);
      const combinedFilters = { component: componentFilters, facets: tableFilters };
      component.data.filters = combinedFilters;

      const searchValue = component.search;
      const currentFacets = component.data.config != null ? [...component.data.config.facets] : null
      const { endpoint, dbSearch } = componentConfig.dispatch;
      let data;
      let totalItems;

      // Instant external DB search
      if (dbSearch.state) {
        const config = {
          headers: { Authorization: `Bearer ${JwtService.getToken()}` },
        };
        const bodyParameters = {
          q: searchValue,
          sort: ["id:desc"],
          matchingStrategy: "all",
          facets: ["*"],
          filter: await this.createSearchFilter(combinedFilters),
          offset: offset ? offset : 0,
          limit: dbSearch.limit,
        };

        bodyParameters.filter.push(['parent_id IS NULL'])

        const [searchResponse, testResponse] = await Promise.all([
          ApiService.post('apiUrlSearch', `/indexes/${dbSearch.indexName}/${dbSearch.indexType}`, bodyParameters),
          ApiService.get('appUrlSearch', `/indexes/${dbSearch.indexName}/documents`),
        ]);

        data = searchResponse.data;
        totalItems = testResponse.data.total;
      }

      const template = {
        items: await this.createTree(data.hits),
        facetsConfig: data.facetDistribution,
        facets: component.state ? await this.createFacets(data.facetDistribution, endpoint, currentFacets, null) : [],
        totalHits: data.estimatedTotalHits,
        offset: data.offset,
        limit: data.limit,
        currentPage: data.offset === 0 ? 1 : data.offset / data.limit + 1,
        totalItems: totalItems
      }

      return template;
    },
    async createTreeNodeChildren(componentName: string, parentId: number) {
      const contentStore = useContentStore();
      const component = this.findComponent(componentName);
      const { dbSearch } = component.componentConfig.dispatch;

      const componentFilters = component.builderConfig.filters ? await this.createComponentFilters(JSON.parse(component.builderConfig.filters), component.componentConfig) : []
      const combinedFilters = { component: componentFilters, facets: [] };
      const filters = await this.createSearchFilter(combinedFilters)

      filters.push([`parent_id=${parentId}`])

      const bodyParameters = {
        q: '',
        sort: ['id:desc'],
        matchingStrategy: 'all',
        facets: ['*'],
        filter: filters,
        offset: 0,
        limit: 1000,
      };

      const { data } = await ApiService.post('apiUrlSearch', `/indexes/${dbSearch.indexName}/${dbSearch.indexType}`, bodyParameters);

      const level = data.hits.map((item) => ({
        id: item.id,
        label: item.title ?? item.name,
        leaf: !item.children?.length,
        position: item.position,
        // children: item.children
      }));

      level.sort((a, b) => a.position - b.position);

      return level;
    },
    async createTree(data) {
      const treeProperties = await Promise.all(data.map(async item => ({
        id: item.id,
        parentId: item.parent_id || null,
        label: item.name ?? item.title,
        name: item.name ?? item.title,
        position: item.position || 0,
        leaf: item.children?.length ? false : true,
        // children: item.children
      })));

      const lookup = new Map(treeProperties.map(record => [record.id, record]));
      lookup.set(null, { children: [] });

      for (const { id, parentId, position } of treeProperties) {
        const pId = parentId !== null ? parentId : null;
        const record = lookup.get(id);
        const parent = lookup.get(pId);

        if (parent) {
          parent.children = parent.children ?? [];
          parent.children[position] = record;
        }
      }

      return lookup.get(null).children;
    },
    /**
     * Creates a FormData object for a component.
     * @param {Object} component - The component object to create the FormData for.
     * @returns {Array} An array of field templates.
     */
    async createComponentFormData(component) {
      try {
        const appStore = useAppStore();
        const contentStore = useContentStore();
        const { currentDateTime } = useDateTime();
        const { createFilelist } = useFiles();

        const { indexName, indexType, dispatchMethod } = component.componentConfig.dispatch.dbSearch;
        const { routeId } = component.componentConfig;
        let data: FormFieldData[] = [];
        const fields = JSON.parse(component.builderConfig.fields);
        const conditionals = await formatConditionals(component.builderConfig.conditionals);

        const displayMethod = component.componentConfig.displayMethod;
        let existingData = {};

        if (displayMethod === 'existing') {
          const { data } = await ApiService[dispatchMethod]('apiUrlSearch',
            `/indexes/${indexName}/${indexType}/${routeId}`
          );
          existingData = data;
        }

        for (const field of fields) {
          // const fieldValue = existingData[field.field];
          // const defaultValue =
          //   displayMethod === 'existing'
          //     ? (() => {
          //         switch (field.type) {
          //           case 'selectMultiSearch':
          //             return JSON.parse(fieldValue);
          //           case 'number':
          //           case 'sliderNumber':
          //             return Number(fieldValue);
          //           default:
          //             return fieldValue;
          //         }
          //       })()
          //     : displayMethod === 'new'
          //     ? (() => {
          //         switch (field.type) {
          //           case 'dateTime':
          //             return currentDateTime();
          //           case 'number':
          //             return 0;
          //           case 'text':
          //             return ''
          //           default:
          //             return null;
          //         }
          //       })()
          //     : null;

          const template = {
            name: field.field,
            label: field.label,
            type: field.type,
            defaultValue: null,
            options: [],
            config: await this.createFieldSettings(field.type, field.options, existingData),
            validation: field.validation,
            // conditionals: await formatConditionals(conditionals),
            hidden: false,
          } as FormFieldData;

          data.push(template);
        }

        await Promise.all(
          data.map(async (field) => {

            field.defaultValue = await this.createDefaultValueField(field, existingData, displayMethod);

            if (field.type === 'selectSearch' || field.type === 'selectMultiSearch') {
              const filters = field.config?.filters ? await this.createFieldFilters(field.config.filters, data, routeId) : [];
              const createdOptions = await this.createSelectOptions(field.config, null, filters, field.defaultValue);
              field.options = await this.helperReorderArray(createdOptions, field.defaultValue);
            } else if (field.type === "uploadImageSingle") {
              field.options = await createFilelist(field.defaultValue);
            }

            if (displayMethod === 'new' && field.defaultValue) {
              this.transferDatav2(component, field, displayMethod);
            }

          })
        );

        data = await applyConditionalRules(data, conditionals);

        return {
          fields: data,
          conditionals: conditionals,
          fetchData: existingData
        }

      } catch (error) {
        console.error(error);
        return null;
      }
    },

    async createDefaultValueField(field, existingData, displayMethod) {
      const contentStore = useContentStore();
      const { currentDateTime } = useDateTime();
      const { extractShortcode, buildString } = useShortcodes();

      if (displayMethod === 'existing') {
        const fieldValue = existingData[field.name];
        switch (field.type) {
          case 'selectMultiSearch':
            return fieldValue ? JSON.parse(fieldValue) : fieldValue;
          case 'number':
          case 'sliderNumber':
            return Number(fieldValue);
          default:
            return fieldValue;
        }
      } else if (displayMethod === 'new') {
        const shortcode = field.config.defaultValue ? extractShortcode(field.config.defaultValue) : null
        const defaultValue =
          shortcode
            ? buildString(shortcode, contentStore)
            : null;

        switch (field.type) {
          case 'dateTime':
            return defaultValue ? defaultValue : currentDateTime();
          case 'number':
            return defaultValue ? defaultValue : 0;
          case 'text':
            return defaultValue ? defaultValue : '';
          default:
            return defaultValue ? defaultValue : null;
        }
      }
    },
    async createFieldFilters(fieldFilters, fields, routeId) {
      const contentStore = useContentStore();

      const newFieldFilters = fieldFilters.map(filter => {
        const newFilter = { ...filter }; // Create a copy of the filter object
        if (newFilter.type === 'currentForm') {
          newFilter.value = fields.find(field => field.name === newFilter.fieldName)?.defaultValue || '';
        } else if (newFilter.type === "id") {
          newFilter.value = routeId;
        } else if (newFilter.type === "routeData") {
          newFilter.value = contentStore.data[newFilter.value];
        }
        return newFilter;
      });

      return newFieldFilters;
    },
    async createFieldSettings(fieldType, fieldConfig, existingData) {
      let optionsTemplate;
      if (fieldType === "number") {
        const minimumNumber = isStringOnlyNumbers(fieldConfig?.minimumNumber) ? Number(fieldConfig.minimumNumber) : existingData[fieldConfig?.minimumNumber];
        const maximumNumber = isStringOnlyNumbers(fieldConfig?.maximumNumber) ? Number(fieldConfig.maximumNumber) : existingData[fieldConfig?.maximumNumber];
        optionsTemplate = {
          minimumNumber: minimumNumber || -Infinity,
          maximumNumber: maximumNumber || Infinity
        }
      } else {
        optionsTemplate = {
          link: fieldConfig?.link ?? null,
          method: fieldConfig?.selection ?? null,
          dispatchMethod: fieldConfig?.dispatchMethod ?? null,
          indexName: fieldConfig?.indexName ?? null,
          indexType: fieldConfig?.indexType ?? null,
          labelField: fieldConfig?.labelField ?? null,
          filters: fieldConfig?.route_filter ? await this.createFieldSettingsFilters(fieldConfig.route_filter) : null,
          autoDispatch: fieldConfig?.autoDispatch ?? null,
          defaultValue: fieldConfig?.defaultValue ?? null,
          defaultValueSource: fieldConfig?.defaultValueSource ?? null
        }
      }

      return optionsTemplate;
    },
    async createFieldSettingsFilters(data) {
      const filters = [] as any;
      for (const filter of data) {
        const template = {
          type: filter.type_filterRoute,
          fieldName: filter.field_filterRoute,
          operator: filter.operator_filterRoute,
          method: filter.operator_filterMethod,
          value: filter.value
        }

        filters.push(template)
      }

      return filters
    },
    async helperReorderArray(arr, defaultValue) {
      arr.sort((a, b) => {
        const aIndex = Array.isArray(defaultValue) ? defaultValue.indexOf(a.value) : (a.value === defaultValue ? 0 : -1);
        const bIndex = Array.isArray(defaultValue) ? defaultValue.indexOf(b.value) : (b.value === defaultValue ? 0 : -1);

        // Neither a nor b is in the default value array, do not change order
        if (aIndex === -1 && bIndex === -1) {
          return 0;
        }

        // Only a is in the default value array, move it to the front
        if (aIndex !== -1 && bIndex === -1) {
          return -1;
        }

        // Only b is in the default value array, move it to the front
        if (aIndex === -1 && bIndex !== -1) {
          return 1;
        }

        // Both a and b are in the default value array, sort them by their index in the array
        return aIndex - bIndex;
      });

      return arr;
    },
    async createSelectOptions(settings, searchValue?, newFilters?, fieldValue?) {
      const appStore = useAppStore()
      const { method, link, indexName, indexType, dispatchMethod, labelField } = settings;

      let options = [] as any;
      let propName;

      if (method === "settings") {
        propName = "label"
        options = appStore.getConfig(link) || []
      } else if (method === "external") {
        propName = labelField || "name"

        try {
          const bodyParameters = {
            q: searchValue ? searchValue : '',
            matchingStrategy: "all",
            sort: [
              "id:desc"
            ],
            filter: newFilters ? await this.createFilterArrayv2(newFilters) : []
          }

          const { data } = await ApiService[dispatchMethod]('apiUrlSearch',
            `/indexes/${indexName}/${indexType}`,
            bodyParameters
          )
          options = data?.hits || [];
        }
        finally {
          // 
        }
      }

      const optionsList = options.map(item => ({ value: item.id, label: item[propName] }));

      if (typeof fieldValue === "string" || typeof fieldValue === "number") {
        const matchedOption = optionsList.find(option => option.value === fieldValue);
        if (!matchedOption) {
          try {
            const { data } = await ApiService.get('apiUrlSearch', `/indexes/${indexName}/documents/${fieldValue}`);
            optionsList.push({ value: fieldValue, label: data[labelField] ?? fieldValue });
          } catch (error) {
            console.log(error); // handle the error here
          }
        }
      } else if (fieldValue && typeof fieldValue === "object") {
        const promises = [] as any;
        for (const key in fieldValue) {
          const value = fieldValue[key];
          const matchedOption = optionsList.find(option => option.value === value);
          if (!matchedOption) {
            promises.push(
              ApiService.get('apiUrlSearch', `/indexes/${indexName}/documents/${value}`)
                .then(({ data }) => {
                  const option = { value: data?.id ?? '', label: data[labelField] ?? value };
                  if (!optionsList.find(opt => opt.value === option.value)) {
                    optionsList.push(option);
                  }
                })
                .catch(error => {
                  console.log(error); // handle the error here
                })
            );
          }
        }

        try {
          await Promise.all(promises);
        } catch (error) {
          console.log(error); // handle the error here
        }
      }

      return optionsList;
    },
    async createFilterArrayv2(data) {
      const filters = [] as any;
      for (const filter of data) {
        if (filter.type === "currentForm" || filter.type === "id" || filter.type === "customValue" || filter.type === "routeData") {

          let string;

          // Check for different operators
          switch (filter.operator) {
            case "equal":
              string = `${filter.fieldName} = '${filter.value}'`;
              break;
            case "notEqual":
              string = `${filter.fieldName} != '${filter.value}'`;
              break;
            case "contains":
              string = `${filter.fieldName} LIKE '%${filter.value}%'`;
              break;
            case "IS NULL":
              string = `${filter.fieldName} IS NULL`;
              break;
            default:
              // default to equal operator if not specified or unknown
              string = `${filter.fieldName} = '${filter.value}'`;
              break;
          }

          filters.push([string]);
        }
      }

      return filters;
    },
    async createTableActions(component) {
      const { componentConfig } = component;

      const template = Object.assign({}, {
        state: false,
        selectedItems: [],
        count: 0,
        totalCount: 0,
        maxItemsPage: 0,
        pageSelected: false,
        totalSelected: false,
        items: await this.createTableActionsItems(componentConfig.typeActions)
      });

      return template;
    },
    async createTableActionsItems(actions) {
      return actions.map(action => ({
        dispatch: action.dispatch,
        icon: action.icon,
        label: action.label,
        state: action.state,
        type: action.type
      })).filter(action => action.state);
    },

    async processTableSelection(componentName: any, items: string | any[], method?: string, ref?: any) {
      const component = this.findComponent(componentName);
      const { actions } = component.data;

      if (method === 'deselectAll') {
        ref!.clearSelection() // Clear selection
      }

      if (!items && method !== 'deselectAll') {
        items = component.data.config.items;
      }

      actions.state = Boolean(items.length);
      actions.selectedItems = items;
      actions.count = method === 'selectAll' ? component.data.config.totalHits : items.length;
      actions.totalCount = component.data.config.totalHits;
      actions.maxItemsPage = component.data.config.limit;
      actions.pageSelected = items.length === actions.maxItemsPage || items.length === actions.totalCount;
      actions.totalSelected = method === 'selectAll' ? true : items.length === actions.totalCount && component.data.filters.length === 0 ? true : false;
    },
    async createTableActionPayload({ component, facets }, selectedItems, totalSelected, identifierKey?: string) {

      const idKey = identifierKey || 'id'; // Use 'id' as default if identifierKey is not provided
      let id;

      if (totalSelected) {
        id = 'all';
      } else {
        const selectedIds = selectedItems
          .map(item => Object.hasOwnProperty.call(item, idKey) ? item[idKey] : undefined)
          .filter(id => id !== undefined);
        id = selectedIds.length > 0 ? selectedIds : undefined;
      }

      const payload = id === 'all' ?
        [component, facets].reduce((acc, arr) => {
          return arr.reduce((innerAcc, { fieldName, key, value }) => {
            return { ...innerAcc, [fieldName || key]: value };
          }, acc);
        }, { id }) :
        { id };

      return payload;
    },
    async processTreeAction(componentName: string, action: any, rowId: number) {
      const appStore = useAppStore();
      const component = this.findComponent(componentName);
      const { type, dispatch } = action;


      if (type === "delete") {

        Swal.fire({
          title: this.trans.t("titles.Confirm_Action"),
          text: this.trans.t('tree.confirm.text'),
          buttonsStyling: false,
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.trans.t("titles.Delete"),
          cancelButtonText: this.trans.t("titles.Cancel"),
          customClass: {
            confirmButton: "btn btn-light-danger",
            cancelButton: "btn btn-light"
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            component.isLoading = true;
            // const checkTask = payload.id !== 'all' && payload.id?.length === 1;

            try {
              await ApiService.delete('apiUrl', `/${dispatch.endpoint}`, { id: rowId })
                .then(async ({ data }) => {
                  await appStore.taskStatus(data.result.task_id);
                })
                .catch((error) => {
                  Swal.fire({
                    text: error,
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: this.trans.t('titles.Continue'),
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  });
                })
            }
            finally {
              this.reloadComponent(componentName)
              component.isLoading = false;
            }
          }
        })
      }
    },
    async processTableAction(componentName: string, actionData) {
      const appStore = useAppStore();
      const component = this.findComponent(componentName);
      const actions = component.data.actions;
      const action = actions.items.find(action => action.label === actionData.label);

      const { type, dispatch } = action;
      const { totalSelected, selectedItems, count } = actions;

      if (type === 'delete') {
        const payload = await this.createTableActionPayload(component.data.filters, selectedItems, totalSelected, dispatch.identifierKey);

        Swal.fire({
          title: this.trans.t("titles.Confirm_Action"),
          text: this.trans.t('table.confirm.text', count),
          buttonsStyling: false,
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.trans.t("titles.Confirm"),
          cancelButtonText: this.trans.t("titles.Cancel"),
          customClass: {
            confirmButton: "btn btn-light-primary",
            cancelButton: "btn btn-light"
          },
        }).then(async (result) => {
          if (result.isConfirmed && payload.id.length) {
            component.isLoading = true;
            const checkTask = payload.id !== 'all' && payload.id?.length === 1;

            try {
              await ApiService.delete('apiUrl', `/${dispatch.endpoint}`, payload)
                .then(async ({ data }) => {
                  if (checkTask) {
                    await appStore.taskStatus(data.result.task_id);
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    text: error,
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: this.trans.t('titles.Continue'),
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  });
                })
            }
            finally {
              this.reloadComponent(componentName)
              component.isLoading = false;
            }
          }
        })
      }
    },
    async builderComponentConfig(componentName: string) {
      const appStore = useAppStore();
      const component = appStore.allComponents.find(c => c.name === componentName) || null;
      return component;
    },
    async test(componentName: string, paginationNumber: number) {
      const component = this.findComponent(componentName);
      const offset = component.data.config.offset === component.componentConfig.limit ? 0 : component.data.config.limit * (paginationNumber - 1);

      component.data.config = await this.createComponentData(component.componentConfig, offset)
    },
    async updateComponentData(componentName: string, lastUsedFilter: string | null = null) {
      const component = this.findComponent(componentName);

      component.isLoading = true;
      component.data.config = await this.createComponentData(component.componentConfig, null, lastUsedFilter);
      component.isLoading = false;
    },
    async createTableFilters(facets) {
      const filters = [] as any;

      for (const facet of facets) {
        if (facet.defaultValue != null && facet.defaultValue != '') {
          filters.push({ key: facet.id, value: facet.defaultValue })
        }
      }

      return filters;
    },
    async createSearchFilter(filters) {
      const array = [] as any;

      for (const filter of filters.component) {
        let string = '';
        if (filter.type === 'customValue') {
          if (filter.operator === 'IS NULL') {
            string = `${filter.fieldName} ${filter.operator}`;
          } else {
            string = `${filter.fieldName}${filter.operator}'${filter.value}'`;
          }
        } else {
          string = `${filter.fieldName}${filter.operator}'${filter.value}'`;
        }
        array.push([string])
      }

      for (const facet of filters.facets) {
        if (facet.value != null && facet.value != '') {
          if (Array.isArray(facet.value)) {
            const valuesString = facet.value.map(val => `'${val}'`).join(', ');
            const string = `${facet.key} IN [${valuesString}]`;
            array.push([string]);
          } else {
            const string = `${facet.key}='${facet.value}'`;
            array.push([string]);
          }
        }
      }

      return array
    },
    async createComponentFilters(data, componentConfig) {
      const contentStore = useContentStore();
      const routeId = componentConfig.routeId;
      const filters = [] as any;

      let value;

      // TODO: Type should be 'source'. Should change it in builder v2.0.
      for (const filter of data) {
        if (filter.type === 'id') {
          value = routeId
        } else if (filter.type === "routeData") {
          value = contentStore.routeData[filter.value]
        } else if (filter.type === "customValue") {
          value = filter.value ?? null
        }

        const template = {
          type: filter.type,
          fieldName: filter.field,
          operator: filter.operator,
          // method: filter.operator_filterMethod,
          value: value
        }

        filters.push(template)
      }

      return filters
    },
    async processFilterFacets(componentName: string) {
      const component = this.findComponent(componentName);

      if (component.toolbar.filter.active) {
        return;
      }

      component.toolbar.filter.active = true;

      const currentFacets = component.data.config?.facets ?? null;
      const { endpoint } = component.componentConfig.dispatch;
      component.data.config.facets = await this.createFacets(component.data.config.facetsConfig, endpoint, currentFacets, null);
    },
    async createComponentData(componentConfig: any, offset: number | null = 0, lastUsedFilter: string | null = null) {
      const component = this.findComponent(componentConfig.name);
      const activeFacets = component.data.config?.facets || []

      const componentFilters = component.builderConfig.filters ? await this.createComponentFilters(JSON.parse(component.builderConfig.filters), component.componentConfig) : []
      const tableFilters = await this.createTableFilters(activeFacets);
      const combinedFilters = { component: componentFilters, facets: tableFilters };
      component.data.filters = combinedFilters;

      const searchValue = component.search;
      const currentFacets = component.data.config != null ? [...component.data.config.facets] : null
      const { endpoint, dbSearch } = componentConfig.dispatch;
      let data;
      let totalItems;

      // Instant external DB search
      if (dbSearch.state) {
        const bodyParameters = {
          q: searchValue,
          sort: ["id:desc"],
          matchingStrategy: "all",
          facets: ["*"],
          filter: await this.createSearchFilter(combinedFilters),
          offset: offset ? offset : 0,
          limit: dbSearch.limit,
        };

        try {
          const [searchResponse] = await Promise.all([
            ApiService.post('apiUrlSearch', `/indexes/${dbSearch.indexName}/${dbSearch.indexType}`, bodyParameters),
            // ApiService.get('apiUrlSearch', `/indexes/${dbSearch.indexName}/documents`)
          ]);

          data = searchResponse.data;
          // totalItems = testResponse.data.total;
        }
        catch (error) {
          data = [];
          // totalItems = 0
        }
      }

      // console.log(searchResponse.data)

      const currentPage = data.offset === 0 ? 1 : data.offset / data.limit + 1;
      const totalHits = data.estimatedTotalHits || 0

      const template = {
        items: data.hits || [],
        facetsConfig: data.facetDistribution || {},
        facets: component.state ? await this.createFacets(data.facetDistribution, endpoint, currentFacets, lastUsedFilter) : [],
        totalHits: totalHits,
        offset: data.offset || 0,
        limit: data.limit || 0,
        currentPage: currentPage,
        totalPages: data.estimatedTotalHits / data.limit,
        displayedRecords: Math.min(currentPage * data.limit, totalHits)
        // totalItems: totalItems
      }

      return template;
    },
    async createFacets(facetsConfig: any, endpoint: string, facetsConfigCurrent: any, lastUsedFilter) {
      const appStore = useAppStore();
      const possibleFacets = appStore.getConfig('facets')[endpoint] || [];
      const facets = [] as any;

      for (const possibleFacet of possibleFacets) {

        for (const [key, value] of Object.entries(facetsConfig)) {
          let defaultValue = null;
          if (possibleFacet.key === key) {
            let currentFacetData;
            let options;

            if (facetsConfigCurrent) {
              currentFacetData = facetsConfigCurrent.find(facet => facet.id === possibleFacet.key);

              if (currentFacetData) {
                if (currentFacetData.defaultValue != null) {
                  defaultValue = currentFacetData.defaultValue
                }
              }

              if (lastUsedFilter === key) {
                options = currentFacetData.options
              } else {
                options = await this.createFacetOptions(possibleFacet.key, value as Record<string, number>);
              }
            }

            const facet = {
              id: possibleFacet.key,
              label: possibleFacet.label,
              defaultValue: defaultValue,
              options: options
            };

            if (facet.options.length > 1 || facet.defaultValue) {
              facets.push(facet);
            }
          }
        }
      }
      return facets;
    },
    async createFacetOptions(facetId: string, options: Record<string, number>) {
      const appStore = useAppStore();
      const facetOptions: FacetOption[] = [];

      const configMap = {
        sale_status: 'sale_statuses',
        customer_status: 'customer_statuses',
        invoice_status: 'invoice_statuses',
        product_status: 'product_statuses',
        specification_status: 'specification_statuses',
        supplier_status: 'supplier_statuses',
        location_status: 'location_statuses',
        picklist_status: 'picklist_statuses',
        discount_group_status: 'discount_group_statuses',
        discount_status: 'discount_statuses',
        quotation_status: 'quotation_statuses',
        price_status: 'price_statuses',
        pos_status: 'pos_statuses',
        profile_status: 'profile_statuses',
        payment_status: 'payment_statuses',
        connection_status: 'connection_statuses',
        country_code: 'countries',
        language: 'languages',
        stock_type: 'stock_types',
        cash_log_type: 'cash_log_types',
        product_type: 'product_types',
        specification_type: 'specification_types',
        connection_category: 'connection_categories',
        payment_method: 'payment_methods',
        pos_id: 'pos',
        profile_id: 'profiles',
        tag_category: 'tag_categories',
        tag_status: 'tag_statuses',
        role_status: 'role_statuses',
        user_status: 'user_statuses',
        label_status: 'label_statuses',
        label_type: 'label_types',
      };

      let config;
      let configLabelKey = 'label';

      switch (facetId) {
        case 'sale_status':
        case 'customer_status':
        case 'invoice_status':
        case 'product_status':
        case 'specification_status':
        case 'supplier_status':
        case 'location_status':
        case 'picklist_status':
        case 'discount_group_status':
        case 'discount_status':
        case 'quotation_status':
        case 'price_status':
        case 'pos_status':
        case 'profile_status':
        case 'payment_status':
        case 'connection_status':
        case 'tag_status':
        case 'role_status':
        case 'user_status':
        case 'label_status':
          config = appStore.getConfig('statuses')[configMap[facetId]];
          break;
        case 'country_code':
        case 'payment_method':
        case 'language':
          config = appStore.getConfig(configMap[facetId]);
          break;
        case 'stock_type':
        case 'product_type':
        case 'specification_type':
        case 'cash_log_type':
        case 'connection_category':
        case 'tag_category':
        case 'label_type':
          config = appStore.getConfig('types')[configMap[facetId]];
          break;
        case 'pos_id':
        case 'profile_id': {
          configLabelKey = 'name';

          const payload = {
            q: null,
            sort: ["id:desc"],
            matchingStrategy: "all",
            facets: ["*"],
            filter: [],
            offset: 0,
            limit: 20
          }
          try {
            const { data } = await ApiService.post('apiUrlSearch', `/indexes/${[configMap[facetId]]}/search`, payload);
            config = data.hits;
            // do something with the config data
          } catch (error) {
            // handle errors here
          }
          break;
        }
        default:
          break;
      }

      for (const [key, value] of Object.entries(options)) {
        if (value > 0) { // Only add if count (value) is higher than 0
          const label = config ? config.find((item) => item.id == key)?.[configLabelKey] ?? key : key;
          const option: FacetOption = { value: key, label, count: value };
          facetOptions.push(option);
        }
      }

      return facetOptions;
    },
    async initComponentToolbar(componentName: string) {
      const component = this.findComponent(componentName);
      const { toolbar } = component.componentConfig;

      if (toolbar.state) {
        await Promise.all([
          this.processToolbarMenu(component, toolbar.menu),
          this.processToolbarFilter(component, toolbar.filter),
          this.processToolbarSearch(component, toolbar.search)
        ]);
      }
    },
    async processToolbarSearch(component: any, { state }: any) {
      component.toolbar.search.state = state;
    },
    async processToolbarFilter(component: any, { state }: any) {
      component.toolbar.filter.state = state;
    },
    async processToolbarMenu(component: any, { state, items, title }: any) {
      if (state) {
        const menuItems = items.map(item => ({
          label: item.label,
          method: item.method,
          target: item.target,
          icon: item.icon,
          custom: item.custom ?? false
        }));

        component.toolbar.menu.items = [...menuItems];
        component.toolbar.menu.title = title;
        component.toolbar.menu.state = state;
      }
    },
    processTableNavigate(componentName, scopeValue, options) {
      const contentModalsStore = useContentModalsStore();

      const component = this.findComponent(componentName);
      const { modal, toolbar, builderConfig } = component;

      const { method, methodTarget, custom } = options;

      switch (method) {
        case 'modal': {
          modal.routeId = scopeValue;
          modal.current = methodTarget;
          // modal.itemId = scopeValue;
          toolbar.menu.renderAmount += 1;

          // TODO: New addon with new modals structure.
          contentModalsStore.openModal(methodTarget, { type: 'modal', custom: custom }, componentName);

          break;
        }
        case 'route':
          this.router.push({ path: `${methodTarget}/${scopeValue}` });
          break;

        default:
          break;
      }
    },
    processTreeNavigate(componentName, routeId, action) {
      const contentModalsStore = useContentModalsStore();
      const component = this.findComponent(componentName);
      const { modal, toolbar } = component;
      const { method, target, custom } = action

      switch (method) {
        case 'modal': {
          modal.routeId = routeId;
          modal.current = target;
          toolbar.menu.renderAmount += 1;

          contentModalsStore.openModal(target, { type: 'modal', custom: custom }, componentName);
          break;
        }
        // case 'route':
        //   this.router.push({ path: `${methodTarget}/${scopeValue}` });
        //   break;

        default:
          break;
      }
    },
    async processToolbarAction(item, componentName: string) {
      const appStore = useAppStore();
      const contentModalsStore = useContentModalsStore();
      const component = this.findComponent(componentName)
      component.toolbar.menu.current = item;
      component.toolbar.menu.renderAmount += 1;

      const { method, target, custom } = item;

      if (method === "modal") {

        contentModalsStore.openModal(item.target, { type: method, custom: custom ?? false }, componentName);

      } else if (method === "link") {
        this.router.push({ path: target })
      } else if (method === "instant") {
        // TODO: It is static now. Should be extended to be fully variable.

        component.isLoading = true;

        const { data } = await ApiService.post('apiUrl', target);
        await appStore.validateDocument(data.result.product_id, 'products',);

        this.reloadComponent(componentName);

      }
    },
    async reloadComponent(componentName: string) {
      const component = this.findComponent(componentName)
      await this.processComponent(componentName)
      component.renderAmount += 1
    },
    async reloadAllComponents() {
      const components = this.initalizedComponents;

      await Promise.all(
        components.map(async (component) => {
          await this.reloadComponent(component.componentConfig.name);
        })
      );
    },
    async transferDatav2(component, fieldData, displayMethod) {
      const transferData = component.data.transferData;
      const { defaultValue, name, validation } = fieldData;

      if (typeof defaultValue === "undefined" && displayMethod !== "existing") {
        delete transferData[name];
      } else {
        transferData[name] = validation === "json" ? JSON.stringify(defaultValue) : defaultValue ?? '';
      }

      if (Object.keys(transferData).length) {
        component.isTransferable = true;
      } else {
        component.isTransferable = false;
      }
    },
    async transferDataTable(component: any, fieldName: string, fieldValue?: any, rowId?: any) {
      const { name } = component.componentConfig
      const targetComponent = this.findComponent(name);
      const targetColumn = targetComponent.data.columns.find(row => row.field === fieldName);
      const targetRow = targetComponent.data.config.items.find(row => row.id === rowId);

      const defaultValue = (typeof fieldValue !== 'undefined') ? fieldValue : targetRow.fieldName;

      const transferData = targetComponent.data.transferData;
      if (typeof defaultValue === 'undefined' && targetComponent.componentConfig.displayMethod !== 'existing') {
        delete transferData[targetColumn.field];
      } else {
        transferData[targetColumn.field] = defaultValue ?? '';
      }

      targetComponent.isTransferable = Boolean(Object.keys(transferData).length);

      if (targetColumn.options.autoDispatch) {
        this.dispatchTransfer(name, rowId)
      }

      // this.rerenderFields(component, fieldName);
    },
    async uploadFile(file, payload?: any) {
      const imageList = new FormData() as any
      imageList.append('file[]', file.raw);

      if (payload) {
        for (const item of payload) {
          // Append key-value pairs from payload
          imageList.append(item.key, item.value);
        }
      }

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      const { data } = await ApiService.post('apiUrl', `/file`, imageList, config);
      return data.result.items[0].id;
    },
    async createDispatchParams(params: any, componentName) {
      const component = this.findComponent(componentName);
      // const contentStore = useContentStore();

      const template = [] as any;
      if (params.state) {
        for (const param of params.lines) {
          if (param.source === 'component') {
            template.push({ key: [param.key], value: component.componentConfig[param.value] });
          }
        }
      }

      return template;
    },
    async transferData(component: any, fieldName: string, fieldValue?: any, subField?: any) {
      const { name } = component.componentConfig
      const targetComponent = this.findComponent(name);
      const { autoDispatch, params } = targetComponent.componentConfig.dispatch.transfer;
      const targetField = targetComponent.data.config.fields.find(field => field.name === fieldName);

      let defaultValue = (typeof fieldValue !== 'undefined') ? fieldValue : targetField.defaultValue;

      if (targetField.type === "uploadImageSingle") {
        defaultValue = await this.uploadFile(fieldValue);
      } else if (targetField.type === "uploadImageMulti") {
        component.isLoading = true;
        defaultValue = await this.uploadFile(fieldValue);

      } else if (targetField.type === "uploadFiles") {
        component.isLoading = true;
        const payload = await this.createDispatchParams(params, name);

        await this.uploadFile(fieldValue, payload);
        component.isLoading = false;
        this.processListenComponents(name);
        return;
      } else if (targetField.type === "configuration") {
        const value = JSON.parse(fieldValue);

        const configField = value.find(item => item.key === subField.key);
        configField.value = subField.value

        defaultValue = value
      }

      const transferData = targetComponent.data.transferData;

      if (typeof defaultValue === 'undefined' && targetComponent.componentConfig.displayMethod !== 'existing') {
        delete transferData[targetField.name];
      } else {
        transferData[targetField.name] = (targetField.validation === 'json') ? JSON.stringify(defaultValue) : defaultValue ?? '';
      }

      targetComponent.isTransferable = Boolean(Object.keys(transferData).length);

      if (autoDispatch || targetField.autoDispatch) {
        this.dispatchTransfer(name, defaultValue);
      }

      this.rerenderFields(component, fieldName);
      targetComponent.data.config.fields = await applyConditionalRules(targetComponent.data.config.fields, targetComponent.data.config.conditionals)
    },
    async uploadMedia(component: any, mediaFile: any) {
      const { endpoint, dispatchMethod, navigate, params } = component.componentConfig.dispatch.transfer;

      const imageList = new FormData() as any
      imageList.append('file[]', mediaFile.raw);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      const { data } = await ApiService.post2('apiUrl', `/file`, imageList, config);
      return data.result.items[0].id

    },
    async transferDataMulti(component: any, fieldData: any, draggingNodeId: number) {
      const targetComponent = this.findComponent(component.componentConfig.name);

      const transferData = targetComponent.data.transferData;

      for (const field of fieldData) {
        transferData[field.fieldName] = field.fieldValue;
      }

      targetComponent.isTransferable = Boolean(Object.keys(transferData).length);

      this.dispatchTransfer(component.componentConfig.name, draggingNodeId)
    },
    toggleModal(modalId) {
      const modal = document.getElementById(modalId);

      if (!modal) {
        // Modal element not found, exit early
        return;
      }

      const instance = Modal.getOrCreateInstance(modal);

      if (typeof instance.toggle === 'function') {
        instance.toggle();
      }
    },
    async dispatchTransfer(componentName: string, id?: number) {
      const contentNotificationsStore = useContentNotificationsStore();

      const appStore = useAppStore();
      const contentStore = useContentStore();
      const component = this.findComponent(componentName);
      const pComponent = this.findComponent(component.parent);
      const { name, routeId, modalId, type, dispatch } = component.componentConfig;
      const { transfer, dbSearch } = dispatch
      const { endpoint, dispatchMethod, navigate, params, validateDocument } = transfer;
      const { indexName: vdIndexName, state: vdState } = validateDocument;
      // const { indexName } = dbSearch;
      const { state, path, method, source } = navigate;
      const { transferData } = component.data;

      try {
        component.isLoading = true;

        let response;
        let itemId;

        if (dispatchMethod === "post") {
          if (params.state) {
            for (const param of params.lines) {
              if (param.source) {
                if (param.source === 'routeData') {
                  transferData[param.key] = contentStore.routeData[param.value];
                } else if (param.source === 'fieldValue') {
                  transferData[param.key] = id;
                } else {
                  transferData[param.key] = param.source === "component"
                    ? component.componentConfig[param.value]
                    : param.value;
                }
              }
            }
          }

          response = await ApiService.post('apiUrl', `/${endpoint}`, transferData);
          itemId = response.data.item_id;
        } else if (dispatchMethod === "put") {
          if (type === 'tree' || type === "table") {
            response = await ApiService.put('apiUrl', `/${endpoint}/${id}`, transferData);
          } else {
            response = await ApiService.put('apiUrl', `/${endpoint}/${routeId}`, transferData);
          }
        }

        if (vdState && response.data.task_id) {
          try {
            if (dispatchMethod === "post") {
              await appStore.validateDocument(response.data.item_id, vdIndexName);
            } else {
              await appStore.taskStatus(response.data.task_id);
            }
          }
          catch (error) {
            //
          }

          component.isTransferable = false;
          component.data.transferData = {};
        }

        if (state) {
          const contentModalsStore = useContentModalsStore();

          contentModalsStore.closeModal(modalId);

          if (method === "redirect") {
            this.router.push({ path: path + '/' + (dispatchMethod === "post" ? itemId : routeId) });
          } else if (method === "reload") {
            let componentName = "";
            if (source === "parentComponent") {
              componentName = pComponent.componentConfig.name;
            } else if (source === "component") {

              componentName = name;

            }
            this.reloadComponent(componentName);
          }

        }

        contentNotificationsStore.setNotification('Succesvol', 'Update verwerkt.', 'success')

      } catch (error: any) {
        console.log(error)
        const errorResponse = error.response.data.result.errors;
        contentNotificationsStore.setNotification('Fout', errorResponse, 'error')
      } finally {
        component.isLoading = false;
        this.processListenComponents(componentName);
      }
    },
    async processListenComponents(componentName) {
      const matchingComponents = await this.checkListenToState(componentName);
      await Promise.all(matchingComponents.map((matchingComponent) => {
        const { name } = matchingComponent.componentConfig;
        return this.reloadComponent(name);
      }));
    },
    async checkListenToState(componentName) {
      const components = this.initalizedComponents;
      const matchingComponents = [] as any;

      for (const component of components) {
        if (component.componentConfig.listenTo) {
          const { listenTo } = component.componentConfig || {};

          if (listenTo.state) {
            const matchingComponent = listenTo.components.find(
              (comp) => comp.name === componentName
            );

            if (matchingComponent) {
              matchingComponents.push(component);
            }
          }
        }
      }

      return matchingComponents;
    },
    async rerenderSelectOptions(component: any, fieldName: string, searchValue: string) {
      const cComponent = this.findComponent(component.builderConfig.name);
      const { routeId } = cComponent.componentConfig;
      const fields = cComponent.data.config.fields;
      const cField = cComponent.data.config.fields.find(f => f.name === fieldName);
      // TODO: Deleted it 8-2 // const fieldsData = cComponent.data.config.fields

      const filters = cField.config?.filters ? await this.createFieldFilters(cField.config.filters, fields, routeId) : [];

      const createdOptions = await this.createSelectOptions(cField.config, searchValue, filters)
      cField.options = createdOptions;
    },
    rerenderFields(component, fieldName) {
      const fieldsToCheck = component.data.config.fields
        .filter(({ config }) => config && config.filters && config.filters.some(({ type, fieldName: name }) => type === 'currentForm' && name === fieldName))
        .filter(({ name }) => name !== fieldName);

      for (const field of fieldsToCheck) {
        field.defaultValue = null;
        field.options = [];
      }
    },
    toggleComponent(componentName: string) {
      const component = this.findComponent(componentName);
      component.isActive = !component.isActive
    }
  }
});