import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useAuthStore } from '@/stores/auth';
import { useI18n } from 'vue-i18n';

export default function useDateTime() {
  const authStore = useAuthStore();

  function currentDateTime() {
    const date = new Date();
    date.setMilliseconds(0);
    return date.toISOString();
  }

  function showDateTime(data: string) {
    let lang = 'en';
    if (authStore.user.language == 'nld') {
      lang = 'nl'
    } else if (authStore.user.language == 'eng') {
      lang = 'en'
    }

    require('dayjs/locale/' + lang)
    dayjs.locale(lang)
    dayjs.extend(utc)
    dayjs.extend(timezone)

    dayjs.tz.setDefault(authStore.user.timezone)

    const dateTime = dayjs(data).format(authStore.user.date_format)
    const toolTip = lang === 'en' ? dayjs(data).format('dddd, MMM D, H:mm') : dayjs(data).format('dddd, D MMM, H:mm')

    const object = {
      date: dateTime,
      tooltip: toolTip,
    }
    return data != null ? object : null;
  }

  function getTimeSince(dateString: string): string {
    const { t } = useI18n();
    const date: Date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return "Invalid date";
    }

    const now: Date = new Date();
    const diff: number = now.getTime() - date.getTime(); // Calculate the difference relative to the future date
    const minute: number = 60 * 1000;
    const hour: number = 60 * minute;
    const day: number = 24 * hour;
    const week: number = 7 * day;
    const month: number = 30 * day;
    const year: number = 365 * day;

    if (diff <= 0) {
      return "Just now";
    } else if (diff < minute) {
      const seconds = Math.ceil(diff / 1000);
      return t('titles.seconds_ago', seconds);
    } else if (diff < hour) {
      const minutes = Math.ceil(diff / minute);
      return t('titles.minutes_ago', minutes);
    } else if (diff < day) {
      const hours = Math.ceil(diff / hour);
      return t('titles.hours_ago', hours);
    } else if (diff < week) {
      const days = Math.ceil(diff / day);
      return t('titles.days_ago', days);
    } else if (diff < month) {
      const weeks = Math.ceil(diff / week);
      return t('titles.weeks_ago', weeks);
    } else if (diff < year) {
      const months = Math.ceil(diff / month);
      return t('titles.months_ago', months);
    } else {
      const years = Math.ceil(diff / year);
      return t('titles.years_ago', years);
    }
  }

  function formatDate(dateString, formatType) {

    const userLanguage = authStore.userLanguage;
    const userTimezone = authStore.userTimezone;

    let langCode = 'en';
    if (userLanguage === 'nld') {
      langCode = 'nl';
    } else if (userLanguage === 'eng') {
      langCode = 'en';
    }

    require('dayjs/locale/' + langCode);
    dayjs.locale(langCode);
    dayjs.extend(utc);
    dayjs.extend(timezone);

    dayjs.tz.setDefault(userTimezone);

    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return { error: "Invalid date" };
    }

    let formattedDate;

    switch (formatType) {
      case 'dayMonthYear':
        formattedDate = dayjs(date).format('DD-MM-YYYY'); // dd-mm-yyyy format
        break;
      case 'longFormat':
        formattedDate = dayjs(date).format('dddd D MMMM, YYYY'); // dddd/mmmm/yyyy format
        break;
      case 'longFormatWithMonthName':
        formattedDate = dayjs(date).format('D MMMM YYYY'); // 2 May 2024 format
        break;
      case 'hoursMinutes':
        formattedDate = dayjs(date).format('HH:mm'); // hours:minutes format
        break;
      case 'hoursMinutesSeconds':
        formattedDate = dayjs(date).format('HH:mm:ss'); // hours:minutes:seconds format
        break;
      default:
        formattedDate = dayjs(date).format('DD-MM-YYYY'); // Default to dd-mm-yyyy format
        break;
    }

    return formattedDate;
  }
  return {
    showDateTime,
    currentDateTime,
    getTimeSince,
    formatDate
  }
}