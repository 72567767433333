import { defineStore } from "pinia";
import axios from "axios"
import { useAppStore } from "./app";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/apiServicev3";

interface RootState {
  isLoading: boolean
}

export const useCustomerStore = defineStore({
  id: "customerStore",
  state: () =>
    ({
      isLoading: false,
    } as RootState),
  getters: {
  },
  actions: {
    async customer(customerId: number) {
      try {
        const { data } = await ApiService.get("apiUrlSearch", `/indexes/customers/documents/${customerId}`);

        return data;
      }
      catch(error) {
        //
      }
    }
  }
});