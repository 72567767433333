import ApiService from "@/core/services/apiServicev3";

/**
 * Sends a print request to the API with the given connectionId and pdfURL.
 * @param {string} connectionId - The ID of the printer connection.
 * @param {string} pdfURL - The URL of the PDF to be printed.
 * @returns {Promise<{status: string, message: string}>} - The status of the print request.
 */
export const directPrint = async (connectionId: string, pdfURL: string): Promise<{status: string, message: string}> => {

  const response = {
    status: 'error',
    message: 'An error occurred while sending the print request.'
  } as any;
 
  const payload = {
    connection_id: connectionId,
    pdf_url: pdfURL
  };

  try {
    await ApiService.post('apiUrl', "/direct-print", payload);
    response.status = 'success';
    response.message = 'Print request sent successfully.';
  } catch (error) {
    response.message = error instanceof Error ? error.message : 'Unknown error occurred.';
    // Optionally log the error or handle it appropriately
  }

  return response;
};