export default function removeSpaces(value: string | number) {

  if (value === null || value === undefined) {
    return null;
  }

  if (typeof value === "number") {
    return value.toString().trim().replace(/^\s+|\s+$/g, "");
  } else {
    return value.trim().replace(/^\s+|\s+$/g, "");
  }
};