import { defineStore } from "pinia";
import axios from "axios"
import { useAppStore } from "./app";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/apiServicev3";

interface RootState {
  isLoading: boolean
}

export const useProfileStore = defineStore({
  id: "profileStore",
  state: () =>
    ({
      isLoading: false,
    } as RootState),
  getters: {
  },
  actions: {
    async profile(id: number) {
      try {
        const { data } = await ApiService.get('apiUrlSearch', `/indexes//profiles/documents/${id}`);

        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    }
  }
});