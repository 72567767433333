import { defineStore } from "pinia";
import ApiService from "@/core/services/apiServicev3";
import { useProductStore } from "@/stores/products";
import { useAppStore } from "../app";
import { useAppComponentsStore } from "../app/components/components";
import config from "@/core/config/App";

interface RootState {
  search: any
  saleLineOptions: {
    searchValue: string | null
    saleLineId: number | null
    productId: number | null
    list: any
  } 
  isLoading: boolean
}

export const useSalesStore = defineStore({
  id: "salesStore",
  state: () =>
    ({
      saleLineOptions: {
        searchValue: null,
        saleLineId: null,
        productId: null,
        list: []
      },
      isLoading: false,
    } as RootState),
  getters: {
  },
  actions: {
    async getSale(saleId: number) {

      const response = {
        status: 'error',
        data: null
      }

      try {
        const { data } = await ApiService.get('apiUrlSearch', `/indexes/sales/documents/${saleId}`);
        response.status = 'success'
        response.data = data;
      }
      catch (e) {
        //
      }

      return response;
    },
    async validateSale(documentId: number, status: string): Promise<any | null> {
      return new Promise<any | null>((resolve, reject) => {
        let amountChecks = 0;
        const interval = setInterval(async () => {
          amountChecks += 1;
          if (amountChecks === config.documentTotalChecks) {
            clearInterval(interval);
            resolve(null); // Resolve with null if payment_url is not found after 100 checks
          } else {
            try {
              const { data } = await ApiService.get('apiUrlSearch', `/indexes/sales/documents/${documentId}`);
              if (data.sale_status === status) {
                clearInterval(interval);
                resolve(data); // Resolve with data when the document is found
              }
            } catch (error: any) {
              if (error && error.response.status === 404) {
                return;
              }
              clearInterval(interval);
              reject(error);
            }
          }
        }, config.documentCheckTimer);
      });
    },
    async deleteSale(payload: any) {
      return ApiService.delete('apiUrl', "/api/sales", payload)
      .then(({ data }) => {
        //
      })
      .catch(({ response }) => {
        //
      });
    },
    async saleLines(saleId: number) {
      const payload = {
        q: '',
        sort: ["id:desc"],
        matchingStrategy: "all",
        facets: ["*"],
        filter: [[`sale_id=${saleId}`]],
        offset: 0,
        limit: 1000
      };

      const { data } = await ApiService.post('apiUrlSearch', '/indexes/sales_lines/search', payload);

      return data.hits;
    },
    async quotationLines(quotationId: number) {
      const payload = {
        q: '',
        sort: ["id:desc"],
        matchingStrategy: "all",
        facets: ["*"],
        filter: [[`quotation_id=${quotationId}`]],
        offset: 0,
        limit: 1000
      };

      const { data } = await ApiService.post('apiUrlSearch', '/indexes/sales_quotations_lines/search', payload);

      return data.hits;
    },
    async convertToSale(quotationId: number) {
      const { data } = await ApiService.post('apiUrl', `/sales/quotation/convert`, { id: quotationId } );
      return data.item_id;
    },
    async applyDiscounts(lines: any, customerId: number, resource: string) {
      const productStore = useProductStore();
      const appStore = useAppStore();
      try {
        const tasks = lines.map(async (line) => {
          const { id, product_id: productId } = line;

          const { status, data: productCheck } = await productStore.getProductPrice(productId, customerId);

          if (status === 'success') {
            const payload = {
              price: productCheck.baseInclVat,
              discount_percentage: productCheck.discountPercentage
            };
      
            const { data } = await ApiService.put('apiUrl', `${resource}/${id}`, payload);
  
            await appStore.taskStatus(data.task_id);
          }
        
        });
    
        await Promise.all(tasks);
      } catch (error) {
        // Handle any errors here, e.g., logging or notifying the user
        console.error("Error in applyDiscounts:", error);
      }
    },
    async productOptions(query?: string) {
      const payload = {
        q: query === 'null' ? '' : query,
        sort: ["id:desc"],
        matchingStrategy: "all",
        facets: ["*"],
        filter: [[`product_id=${this.saleLineOptions.productId}`]],
        offset: 0,
        limit: 20
      };

      const { data } = await ApiService.post('apiUrlSearch', '/indexes/products_options/search', payload);

      return data.hits;
    },
    async searchProductOptions(query: string, cb: (arg: any) => void) {
      const options = await this.productOptions(query);

      const list = [] as any;
      options.map((item) => {
        list.push({ value: item.name, id: item.id, price: item.price })
      })

      this.saleLineOptions.list = list.sort((a, b) => a.value.localeCompare(b.value));
      cb(this.saleLineOptions.list)
    },
    async addOptionToLine(option) {
      const appStore = useAppStore();

      const componentsStore = useAppComponentsStore();

      const payload = {
        sale_line_id: this.saleLineOptions.saleLineId,
        price: option.price,
        name: option.value,
        product_option_id: option.id
      };
      
      const { data } = await ApiService.post('apiUrl', '/sales/lines/option', payload);

      await appStore.validateDocument(data.item_id, 'sales_lines_options');

      componentsStore.reloadAllComponents();
      this.saleLineOptions.searchValue = null;
    }
  }
});