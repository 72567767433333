import { App } from "vue";
import VueAxios from "vue-axios";
import axios, { AxiosResponse } from "axios";
import JwtService from "@/core/services/JwtService";
import { useAuthStore } from "@/stores/auth";
import { useRouter } from "vue-router";

class ApiService {
  

  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    this.setBaseUrl("apiUrl")
  }

  /**
   * @description set the default HTTP request headers
   * @param type: string
   */
  private static setBaseUrl(type: string): void {
    if (type === "apiUrl") {
      ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    } else if (type === "apiUrlSearch") {
      ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_SEARCH_URL;
    }
  }

  /**
   * @description set the default HTTP request headers
   * @param type: string
   */
  public static setHeader(type: string): void {
    ApiService.setBaseUrl(type);
    axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
    axios.defaults.headers.common["Accept"] = "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param type: string
   * @param resource: string
   * @param params: any
   * @returns Promise<AxiosResponse>
   */
  public static query(type: string, resource: string, params: any): Promise<AxiosResponse> {
    ApiService.setHeader(type);
    return axios.get(resource, { params });
  }

  /**
   * @description send the GET HTTP request
   * @param type: string
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    type: string,
    resource: string,
    params?: any
    // slug = "" as string
  ): Promise<AxiosResponse> {
    ApiService.setHeader(type);
    return axios.get(`${resource}`, params);
  }

  /**
   * @description set the POST HTTP request
   * @param type: string
   * @param resource: string
   * @param params: any
   * @returns Promise<AxiosResponse>
   */
  public static post(type: string, resource: string, params?: any, config?: any): Promise<AxiosResponse> {
    ApiService.setHeader(type);
    return axios.post(`${resource}`, params, config);
  }

  public static post2(
    type: string,
    resource: string,
    params: any,
    config: any
  ): Promise<AxiosResponse> {
    ApiService.setHeader(type);
    return axios.post(`${resource}`, params, config);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param type: string
   * @param resource: string
   * @param slug: string
   * @param params: any
   * @returns Promise<AxiosResponse>
   */
  public static update(
    type: string,
    resource: string,
    slug: string,
    params: any
  ): Promise<AxiosResponse> {
    ApiService.setHeader(type);
    return axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param type: string
   * @param resource: string
   * @param params: any
   * @returns Promise<AxiosResponse>
   */
  public static put(type: string, resource: string, params: any): Promise<AxiosResponse> {
    ApiService.setHeader(type);
    return axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param type: string
   * @param resource: string
   * @param params: any
   * @returns Promise<AxiosResponse>
   */
  public static delete(
    type: string,
    resource: string,
    params: any
  ): Promise<AxiosResponse> {
    ApiService.setHeader(type);
    return axios.delete(`${resource}`, { data: params });
  }
}

axios.interceptors.response.use(
  (response) => response,
  async (error) => {

  const authStore = useAuthStore();
  // const router = useRouter();

    // Check for 403 status code
    if (error.response && error.response.status === 403) {
      // Handle 403 error here
      // Access your Pinia store to manage state (e.g., logout)
      try {
        authStore.purgeAuth(); // Replace with your actual logout action
        // router.push( { name: 'sign-in' })
      } catch (error) {
        //
      }
    }

    return Promise.reject(error);
  }
);

export default ApiService;