import { nextTick } from "vue";
import { defineStore } from "pinia";
import { useAppStore } from "@/stores/app";
import { usePOSStore } from "./pos";
import { useProductStore } from "@/stores/products";
import { usePaymentStore } from "@/stores/sales/payments";
import { useInvoiceStore } from "@/stores/sales/invoices";
import { useTerminalStore } from "@/stores/pos/terminals";
import { useInvoicesStore } from "./invoices";
import ApiService from "@/core/services/apiServicev3";
import removeSpaces from "@/composables/v3/components/helpers/useRemoveSpaces";

interface RootState {
  sale: any;
  sales: any;
  searchValue: any;
  searchProductLineValue: any;
  refundMethods: any;
  searchOptions: any
  returnableLines: any;
  returningLines: any;
  isLoading: boolean;
  isScannerSearch: boolean;
  isCompleted: boolean;
  isReturnable: boolean;
  renderQuantity: number;
  selectedRefundMethodId: number | null | string;
}

type PaymentPayload = {
  payment_method: string | number | null;
  sale_id: any;
  pos_id: any;
  amount_total: any;
  payment_status?: string;
  payment_provider?: string;
  connection_id?: any;
};

export const useReturnsStore = defineStore({
  id: "returnStore",
  state: () =>
  ({
    sale: null,
    sales: null,
    searchValue: null,
    searchProductLineValue: null,
    searchOptions: [],
    refundMethods: [
      {
        id: 'pin',
        label: 'PIN',
        icon: 'credit-card',
        isSelected: false
      },
      {
        id: 'cash',
        label: 'Contant',
        icon: 'bill',
        isSelected: false
      }
    ],
    returnableLines: [],
    isLoading: false,
    isCompleted: false,
    isScannerSearch: false,
    selectedRefundMethodId: null,
    renderQuantity: 0,
  } as RootState),
  getters: {
    isSaleLoaded: (state) => {
      return state.sale;
    },
    isReturningLines: (state) => {
      return state.returnableLines.map(item => item.returnQuantity).reduce((acc, curr) => acc + curr, 0) > 0;
    },
    returningLines: (state) => {
      return state.returnableLines.filter(line => line.returnQuantity > 0);
    },
    returnQuantity: (state) => {
      return state.returnableLines.map(item => item.returnQuantity).reduce((acc, curr) => acc + curr, 0);
    },
    returnAmount: (state) => {
      return state.returnableLines.map(item => item.returnQuantity * item.price).reduce((acc, curr) => acc + curr, 0);
    },
    isReturnable: (state) => {
      return !!state.selectedRefundMethodId && state.returningLines.length;
    },
    productQuantity: (state) => {
      return state.returnableLines.map(item => item.quantity).reduce((acc, curr) => acc + curr, 0);
    },
    linesQuantity: (state) => {
      return state.returnableLines.length;
    },
    saleId: (state) => {
      return state.sale?.id;
    }
  },
  actions: {
    async processRefundMethod(methodId: number) {
      this.selectedRefundMethodId = methodId;
    },

    async processSearch(searchValue) {
      this.isLoading = true;

      try {
        const payload = {
          sort: ['id:desc'],
          matchingStrategy: 'all',
          facets: ['*'],
          filter: [
            [`id=${searchValue}`],
          ],
          offset: 0,
          limit: 1,
        }

        const { data } = await ApiService.post('apiUrlSearch', `indexes/sales/search`, payload)

        if (data.hits && data.hits.length) {
          this.sale = data.hits[0];
          this.getReturnableInvoiceLines();
          nextTick(() => {
            const searchBox = document.getElementById('searchProduct') as HTMLInputElement | null;
            searchBox!.value = '';
            searchBox!.focus()
          })
        } else {
          this.$reset();
        }
      }
      catch (error) {
        //
      }
      finally {
        this.searchValue = null;
        this.isLoading = false;
      }
    },
    clearSearch() {
      this.$reset();
    },
    async getReturnableInvoiceLines() {
      const posStore = usePOSStore();
      posStore.createPaymentActions();

      const list = [] as any

      const returnableLines = this.sale.sales_lines;

      for (const line of returnableLines) {
        const lineQuantity = parseFloat(line.quantity);
        const billedQuantity = parseFloat(line.billed_quantity);
        const totalInclVat = parseFloat(line.net_total_with_vat);

        const item = {
          id: line.id,
          productId: line.product_id,
          name: line.name,
          quantity: lineQuantity,
          billedQuantity: billedQuantity,
          price: totalInclVat / lineQuantity,
          returnQuantity: 0,
          range: { min: 0, max: billedQuantity },
          totals: {
            totalInclVat: totalInclVat
          }
        }

        list.push(item)
      }

      this.returnableLines = list;
    },
    async processReturn() {
      const paymentStore = usePaymentStore();
      const posInvoiceStore = useInvoicesStore();
      const posStore = usePOSStore();
      const terminalStore = useTerminalStore();

      try {

        this.isLoading = true;

        const negativePaymentAmount = -Math.abs(this.returnAmount);

        const paymentPayload: PaymentPayload = {
          payment_method: this.selectedRefundMethodId,
          sale_id: this.saleId,
          pos_id: posStore.currentPOSId,
          amount_total: negativePaymentAmount,
        };

        if (this.selectedRefundMethodId === "cash") {
          paymentPayload.payment_status = 'paid';
        } else if (this.selectedRefundMethodId === "pin") {
          paymentPayload.payment_status = 'pending';
          paymentPayload.payment_provider = 'Adyen';
          paymentPayload.connection_id = posStore.pinTerminalId;
        } else if (this.selectedRefundMethodId === "onAccount") {
          paymentPayload.payment_status = 'pending';
        }

        // First create an payment
        const { status: paymentStatus, data: payment } = await paymentStore.createPayment(paymentPayload);

        if (paymentStatus !== 'success') {
          return;
        }

        if (this.selectedRefundMethodId === 'pin') {
          terminalStore.isActive = true;
          const { status: terminalStatus, data: terminal } = await terminalStore.validatePayment(payment.item_id);

          if (terminalStatus !== 'success') {
            return;
          }
        }

        const { status: invoicePaymentStatus, data: invoicePaymentData } = await this.processInvoiceAndPayment(this.returningLines, this.saleId, payment.item_id, negativePaymentAmount);

        if (invoicePaymentStatus === 'success') {
          this.isCompleted = true;
          posStore.createPaymentActions();
          posInvoiceStore.current = invoicePaymentData;
        }
      }
      catch (error) {
        //
      }
      finally {
        this.isLoading = false;
      }

    },
    async processInvoiceAndPayment(returnableLines, saleId, paymentId, returnAmount) {
      const invoiceStore = useInvoiceStore();
      const appStore = useAppStore();

      const response = {
        status: 'error',
        data: null
      }

      const invoiceableLines = this.formatInvoiceableLines(returnableLines);
      const { status: invoiceStatus, data: invoice } = await invoiceStore.createInvoice(saleId, invoiceableLines);

      if (invoiceStatus === 'success') {
        const invoiceDoc = await appStore.validateDocument(invoice.item_id, 'sales_invoices');
        const { status: invoicePaymentStatus, data: invoicePayment } = await invoiceStore.createInvoicePayment(invoice.item_id, paymentId, returnAmount);

        if (invoicePaymentStatus === 'success') {
          response.status = 'success';
          response.data = invoiceDoc
        }
      }

      return response;
    },
    formatInvoiceableLines(lines) {
      const invoiceableLines = [] as any;

      for (const line of lines) {
        invoiceableLines.push({ id: line.id, quantity: `-${line.returnQuantity}` })
      }

      return invoiceableLines;
    },
    reset() {
      const terminalStore = useTerminalStore();
      this.$reset();
      terminalStore.$reset();
    },
    async createList(items) {
        const list = [] as any
        const promises = Promise.all(
          await items.map(async (item) => {
            list.push({ value: item.id, label: item.title })
          })
        );
        await promises

        return list
    },
    async querySearch(query: string) {
      const productStore = useProductStore();
      const formattedValue = removeSpaces(query);

      const payload = {
        q: formattedValue ?? '',
        sort: ['id:desc'],
        matchingStrategy: 'all',
        facets: ['*'],
        filter: [],
        offset: 0,
        limit: 20
      }

      const products = await productStore.getProducts(payload);
      const list = await this.createList(products)
      this.searchOptions = list;

      if (this.isScannerSearch && products.length === 1) {
        const product = products[0];
        this.isScannerSearch = false;
        this.processSelectSearchItem(product.id);
        this.searchOptions = [];
      } 
    },
    async processSelectSearchItem(item) {
      this.searchProductLineValue = null;
      this.renderQuantity += 1;
      nextTick(() => {
        const searchBox = document.getElementById('searchProduct') as HTMLInputElement | null;
        searchBox!.value = '';
        searchBox!.focus()
      })
      this.processLineQuantity(item);
    },
    async processLineQuantity(productId: number) {
      const line = this.returnableLines.find(line => line.productId === productId);
      if (line) {
        line.returnQuantity += 1;
      }
    }
  }
});