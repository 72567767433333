import { createI18n } from "vue-i18n";

const messages = {
  eng: {
    validation: {
      requiredText: "is a required field",
    },
    nonAuthorized: {
      signIn: {
        title: "Welcome back",
        subTitle: "New Here?",
        subSubTitle: "Create an Account",
        notAuthorized: "Login attempt failed.",
        emailAddress: "E-mail",
        password: "Password",
        passwordLost: "Forgot Password?",
        button: {
          tryAgain: "Try again"
        }
      },
      signUp: {
        title: "Create an account for your business",
        subTitle: "Already have an account?",
        subAddonTitle: "Sign in here",
        name: "Name",
        emailAddress: "E-mail",
        password: "Password",
        confirmPassword: "Confirm Password",
        checkboxAgreeTitle: "I Agree to the",
        checkboxAgreeSubTitle: "Terms and conditions",
        buttonSubmit: "Create Account",
        buttonTryAgain: "Try again"
      },
      passwordReset: {
        title: "Forgot your password?",
        titleToken: "Reset your password",
        emailAddress: "E-mail",
        password: "Password",
      },
      buttons: {
        progressText: 'Please wait..',
        continue: 'Continue',
        Submit: 'Submit',
        Cancel: 'Cancel',
        Loading: 'Loading',
        Back: 'Back',
      }
    },
    authorized: {
      module: {
        sales: {
          quotations: {
            pageTitle: "Quotations",
            dropdownCreateText: "Create Quotation"
          },
          invoices: {
            dropdownCreateText: 'Create Invoice'
          }
        }
      },
      languageLabels: {
        eng: "English",
        nld: "Dutch"
      }
    },
    account: {
      signOut: 'Sign Out',
      language: 'Language'
    },
    error: {
      title: 'Page Not Found',
      message: "The page you requested does not exist.",
      button: 'Go to Dashboard'
    },
    buttons: {
      progressText: 'Please wait..',
      Continue: 'Continue',
      Submit: 'Submit',
      Cancel: 'Cancel',
      Loading: 'Loading',
      Back: 'Back',
    },
    modals: {
      textLanguageTitle: 'Translations'
    },
    table: {
      selection: {
        text: 'All {count} items in this overview have been selected. {followUpText}',
        followUpText: 'Select all {totalCount} items.',
        followUpTextAll: 'Deselect.'
      },
      confirm: {
        text: 'This operation affects {n} item. Are you sure you want to continue? | This operation affects {n} items. Are you sure you want to continue?',
      },
      selected: 'Selected'
    },
    tree: {
      confirm: {
        text: 'Are you sure you want to continue?'
      }
    },
    phrases: {
      posClosed: "The cash register is currently closed. Open the cash register first.",
      generalApiError: "Sorry, looks like there are some errors detected, please try again.",
      No_invoices_and_payments_found: "No invoices and payments found.",
      Drop_file_here_or_click_to_upload: "Drop file(s) here or click to upload",
      Terminal_no_response: "The PIN terminal is not reachable. Check that the PIN terminal is turned on and connected to the Internet.",
      Terminal_session_cancelled: "The PIN payment has been cancelled. You can start a new payment."
    },
    titles: {
      delete: 'Delete',
      filter: 'Filter',
      createLabel: 'Create Label',
      selectLines: 'Select Lines',
      VAT_rate: 'VAT rate',
      Basis: 'Basis',
      Amount: 'Amount',
      Subtotal: 'Subtotal',
      Discount: 'Discount',
      Total_excl_VAT: 'Total excl. VAT',
      VAT: 'VAT',
      Total_incl_VAT: 'Total incl. VAT',
      Label: 'Label',
      Translations: 'Translations',
      Subscription: 'Subscription',
      Subscriptions: 'Subscriptions',
      Customer: 'Customer',
      Customers: 'Customers',
      Product: 'Product',
      Products: 'Products',
      Connections: 'Connections',
      Connection: 'Connection',
      Profiles: 'Profiles',
      Profile: 'Profile',
      Users: 'Users',
      User: 'User',
      Role: 'Role',
      Automations: 'Automations',
      Automation: 'Automation',
      Pickup_Locations: 'Pickup Locations',
      Carriers: 'Carriers',
      Carrier: 'Carrier',
      Labels: 'Labels',
      Returns: 'Returns',
      Return: 'Return',
      Shipments: 'Shipments',
      Shipment: 'Shipment',
      Specifications: 'Specifications',
      Specification: 'Specification',     
      Stocks: 'Stocks',
      Stock: "Stock",
      Stock_Location: 'Stock Location',
      Discounts: 'Discounts',
      Discount_Template: 'Discount Template',
      Sales: 'Sales',
      Sale: 'Sale',
      Quotations: 'Quotations',
      Quotation: 'Quotation',
      Account: 'Account',
      Reset: 'Reset',
      Filter: 'Filter',
      Actions: 'Actions',
      Refresh: 'Refresh',
      Email: 'Email',
      Lines: 'Lines',
      Invoices: 'Invoices',
      Payments: 'Payments',
      Payment: 'Payment',
      Suppliers: "Suppliers",
      Supplier: "Supplier",
      Confirm_Action: "Confirm Action",
      Cancel: "Cancel",
      Confirm: "Confirm",
      Dashboard: "Dashboard",
      New_Sale: "New Sale",
      Counts: "Counts",
      Count: "Count",
      Cash_Management: "Cash Management",
      Unlock: "Unlock",
      Overview: "Overview",
      Paid: "Paid",
      Outstanding: "Outstanding",
      Pay: "Pay",
      Cashiers: "Cashiers",
      POS: "POS",
      Locked: "Locked",
      Switch_User: "Switch User",
      Order_Completed: "Order Completed",
      Outstanding_Amount: "Outstanding Amount",
      PIN_Amount: "PIN Amount",
      Start: "Start",
      Received_Amount: "Received Amount",
      To_be_received: "To be received",
      To_pay_back: "To pay back",
      PIN: "PIN",
      Cash: "Cash",
      Invoice: "Invoice",
      Accounting: "Accounting",
      Continue: "Continue",
      Submit: "Submit",
      Create_Invoice: "Create Invoice",
      Close_POS: "Close POS",
      Search_customer: "Search customer",
      Search_product: "Search product",
      Active: "Active",
      Search: "Search",
      Apply_Discounts: "Apply Discounts",
      Delete: "Delete",
      Lock_User: "Lock User",
      Start_Time: "Start Time",
      End_Time: "End Time",
      Select: "Select",
      Tickets: "Tickets",
      Ticket: "Ticket",
      Password: "Password",
      Login: "Login",
      Receipt: "Receipt",
      Title: "Title",
      Quantity: "Quantity",
      Create: "Create",
      Information: "Information",
      Comment: "Comment",
      Reference: "Reference",
      Unlock_POS: "Unlock POS",
      Stock_Locations: "Stock Locations",
      'Withdrawal_&_Deposit': 'Withdrawal & Deposit',
      'Open_&_Close': 'Open & Close',
      Locations: "Locations",
      Location: "Location",
      General: "General",
      Start_Amount: "Start Amount",
      Open_POS: "Open POS",
      'Withdrawals_&_Deposits': "Withdrawals & Deposits",
      Total_Cash_Payments: "Total Cash Payments",
      Total_Cash: "Total Cash",
      End_Amount: "End Amount",
      Choose: "Choose",
      results: "results",
      Notifications: "Notifications",
      Notification: "Notification",
      Mutations: "Mutations",
      Mutation: "Mutation",
      Option: "Option",
      Options: "Options",
      Address: "Address",
      Line: "Line",
      Picklists: "Picklists",
      Picklist: "Picklist",
      New_Return: "New Return",
      Media: "Media",
      MPN: "MPN",
      SKU: "SKU",
      EAN: "EAN",
      Name: "Name",
      No_results: "No results",
      Loading: "Loading",
      Cashier: "Cashier",
      Tag: "Tag",
      Categories: "Categories",
      Category: "Category",
      Descriptions: "Descriptions",
      Description: "Description",
      Prices: "Prices",
      Price: "Price",
      Variations: "Variations",
      Variation: "Variation",
      History: "History",
      Total: "Total",
      selected: "selected",
      Add_product: "Add product",
      Groups: "Groups",
      Templates: "Templates",
      Rules: "Rules",
      Pages: "Pages",
      Page: "Page",
      Emails: "Emails",
      Webshop: "Webshop",
      Webshops: "Webshops",
      Tags: "Tags",
      Roles: "Roles",
      Menu: "Menu",
      Menus: "Menus",
      Items: "Items",
      Mutate: "Mutate",
      Number: "Number",
      Date: "Date",
      Copy_current_product: "Copy current product",
      Create_new_product: "Create new product",
      Form: "Form",
      Forms: "Forms",
      Fields: "Fields",
      Field: "Field",
      Send_email: "Send email",
      Add_option: "Add option",
      Scanner: "Scanner",
      Not_Registered: "Not registered",
      Create_label: "Create label",
      Pickup_Point: "Pickup Point",
      Post: "Post",
      Number_of_products: "Number of products",
      Amount_to_be_refunded: "Amount to be refunded",
      Total_Amount: "Total Amount",
      Revenue: "Revenue",
      Months: "Months",
      Years: "Years",
      untill: "untill",
      Custom: "Custom",
      Start_date: "Start date",
      End_date: "End date",
      Last_week: "Last week",
      Last_month: "Last month",
      Purchase: "Purchase",
      Purchases: "Purchases",
      Fees: "Fees",
      orders: "orders",
      ID: 'ID',
      Return_quantity: "Return quantity",
      Create_return: "Create return",
      Close: 'Close',
      Scan_product: "Scan product",
      Number_of_notifications: "Number of notifications",
      Latest_activity: "Latest activity",
      seconds_ago: "{n} second ago | {n} seconds ago",
      minutes_ago: "{n} minute ago | {n} minutes ago",
      hours_ago: "{n} hour ago | {n} hours ago",
      days_ago: "{n} day ago | {n} days ago",
      months_ago: "{n} month ago | {n} months ago",
      years_ago: "{n} year ago | {n} years ago",
      Previous: "Previous",
      Link_existing_prduct: "Link existing product",
      Status: "Status",
      Checked_in: "Checked in",
      Valid: "Valid",
      Invalid: "Invalid",
      Scan_ticket: "Scan ticket",
      checked_in: "Checked in",
      invalid: "Invalid",
      valid: "Valid",
      fail: "Fail",
      success: "Success",
      Check_in: "Check in",
      Success: 'Success',
      Failed: "Failed",
      jan: 'jan',
      feb: 'feb',
      mar: 'mar',
      apr: 'apr',
      may: 'may',
      jun: 'jun',
      jul: 'jul',
      aug: 'aug',
      sep: 'sep',
      oct: 'oct',
      nov: 'nov',
      dec: 'dec',
      january: 'january',
      february: 'february',
      march: 'march',
      april: 'april',
      june: 'june',
      july: 'july',
      august: 'august',
      september: 'september',
      october: 'october',
      november: 'november',
      december: 'december',
      Line_quantity: 'Line quantity',
      On_account: 'On account',
      In_cash: "In cash",
      Amount_received_in_cash: "Amount received in cash",
      PIN_amount: "PIN amount",
      Outstanding_amount: "Outstanding amount",
      Sale_number: "Sale number",
      Cancel_payment: "Cancel payment",
      Process_payment: "Process payment",
      Balance_on_account: "Balance on account",
      Limit_on_account: "Limit on account",
      Confirm_on_account: "Confirm on account",
      Start_PIN_terminal: "Start PIN terminal",
      Search_for_sales_number: "Search for sales number", 
      Specification_options: "Specification options",
      Open: "Open",
      Picklist_lines: "Picklist lines",
      Free_space: "Free space"
    }
  },

  nld: {
    validation: {
      requiredText: "is a verplicht veld",
      tryAgainText: "Probeer opnieuw"
    },
    nonAuthorized: {
      signIn: {
        title: "Welkom terug",
        subTitle: "Nieuw hier?",
        subSubTitle: "Maak een account",
        notAuthorized: "Aanmeldpoging mislukt.",
        emailAddress: "E-mail",
        password: "Wachtwoord",
        passwordLost: "Wachtwoord vergeten?",
        button: {
          tryAgain: "Probeer opnieuw"
        }
      },
      signUp: {
        title: "Maak een account aan voor je bedrijf",
        subTitle: "Heb je al een account?",
        subAddonTitle: "Log in",
        name: "Naam",
        emailAddress: "E-mail",
        password: "Wachtwoord",
        confirmPassword: "Bevestig Wachtwoord",
        checkboxAgreeTitle: "Ik ga akkoord met de",
        checkboxAgreeSubTitle: "Algemene voorwaarden",
        buttonSubmit: "Account aanmaken",
        buttonTryAgain: "Probeer nogmaals"
      },
      passwordReset: {
        title: "Je wachtwoord vergeten?",
        emailAddress: "E-mail",
      },
      buttons: {
        progressText: 'Ongeblik geduld..',
        continue: 'Doorgaan',
        Submit: 'Opslaan',
        Cancel: 'Annuleren',
        Loading: 'Laden',
        Back: 'Terug',
      }
    },
    authorized: {
      module: {
        sales: {
          quotations: {
            pageTitle: "Offertes",
            dropdownCreateText: "Offerte toevoegen"
          },
          invoices: {
            dropdownCreateText: "Factuur toevoegen"
          }
        }
      },
      languageLabels: {
        eng: "Engels",
        nld: "Nederlands"
      }
    },
    account: {
      signOut: 'Uitloggen',
      language: 'Taal'
    },
    error: {
      title: 'Pagina niet gevonden',
      message: 'De door jouw opgevraagde pagina bestaat niet.',
      button: 'Ga naar Dashboard'
    },
    buttons: {
      progressText: 'Ogenblik geduld..',
      continue: 'Doorgaan',
      Submit: 'Opslaan',
      Cancel: 'Annuleren',
      Loading: 'Laden',
      Back: 'Terug',
    },
    modals: {
      textLanguageTitle: 'Vertalingen'
    },
    table: {
      selection: {
        text: 'Alle {count} items op deze pagina zijn geselecteerd. {followUpText}',
        followUpText: 'Selecteer alle {totalCount} items.',
        followUpTextAll: 'Selectie ongedaan maken.'
      },
      confirm: {
        text: 'Deze bewerking heeft invloed op {n} item. Weet je zeker dat je door wilt gaan? | Deze bewerking heeft invloed op {n} items. Weet je zeker dat je door wilt gaan?',
      },
      selected: 'geselecteerd'
    },
    tree: {
      confirm: {
        text: 'Weet je zeker dat je wilt doorgaan?'
      }
    },
    phrases: {
      posClosed: "De kassa is momenteel gesloten. Open eerst de kassa.",
      generalApiError: "Sorry, het lijkt erop dat er enkele fouten zijn gedetecteerd, probeer het opnieuw.",
      No_invoices_and_payments_found: "Geen facturen en betalingen gevonden.",
      Drop_file_here_or_click_to_upload: "Sleep bestand(en) hier naar toe of klik om te uploaden",
      Terminal_no_response: "De PIN-terminal is niet bereikbaar. Controleer of de PIN-terminal aan staat en is verbonden met het internet.",
      Terminal_session_cancelled: "De PIN betaling is geannuleerd. Je kunt een nieuwe betaling starten."
    },
    titles: {
      delete: 'Verwijderen',
      filter: 'Filteren',
      createLabel: 'Label maken',
      selectLines: 'Selecteer regels',
      VAT_rate: 'BTW tarief',
      Basis: 'Basis',
      Amount: 'Bedrag',
      Subtotal: 'Subtotaal',
      Discount: 'Korting',
      Total_excl_VAT: 'Totaal excl. BTW',
      VAT: 'BTW',
      Total_incl_VAT: 'Totaal incl. BTW',
      Label: 'Label',
      Translations: 'Vertalingen',
      Subscription: 'Abonnement',
      Subscriptions: 'Abonnementen',
      Customer: 'Klant',
      Customers: 'Klanten',
      Product: 'Product',
      Products: 'Producten',
      Connections: 'Koppelingen',
      Connection: 'Koppeling',
      Profiles: 'Profielen',
      Profile: 'Profiel',
      Users: 'Gebruikers',
      User: 'Gebruiker',
      Role: 'Rol',
      Automations: 'Automatiseringen',
      Automation: 'Automatisering',
      Pickup_Locations: 'Ophaallocaties',
      Carriers: 'Vervoerders',
      Carrier: 'Vervoerder',
      Labels: 'Labels',
      Returns: 'Retouren',
      Return: 'Retour',
      Shipments: 'Zendingen',
      Shipment: 'Zending',
      Specifications: 'Specificaties',
      Specification: 'Specificatie',
      Stocks: 'Voorraden',
      Stock: "Voorraad",
      Stock_Location: 'Voorraadlocatie',
      Discounts: 'Kortingen',
      Discount_Template: 'Korting template',
      Sales: 'Verkopen',
      Sale: 'Verkoop',
      Quotations: 'Offertes',
      Quotation: 'Offerte',
      Account: 'Account',
      Reset: 'Ongedaan maken',
      Filter: 'Filter',
      Actions: 'Acties',
      Refresh: 'Verversen',
      Email: 'Email',
      Lines: 'Lijnen',
      Invoices: 'Facturen',
      Payments: 'Betalingen',
      Payment: "Betaling",
      Suppliers: "Leveranciers",
      Supplier: "Leverancier",
      Confirm_Action: "Actie bevestigen",
      Cancel: "Annuleren",
      Confirm: "Bevestigen",
      Dashboard: "Dashboard",
      New_Sale: "Nieuwe verkoop",
      Counts: "Tellingen",
      Count: "Telling",
      Cash_Management: "Geld beheer",
      Unlock: "Ontgrendelen",
      Overview: "Overzicht",
      Paid: "Betaald",
      Outstanding: "Openstaand",
      Pay: "Betalen",
      Cashiers: "Kassa medewerkers",
      POS: "Kassa",
      Locked: "Vergrendeld",
      Switch_User: "Gebruiker wisselen",
      Order_Completed: "Verkoop voltooid",
      Outstanding_Amount: "Openstaand bedrag",
      PIN_Amount: "PIN bedrag",
      Start: "Starten",
      Received_Amount: "Ontvangen bedrag",
      To_be_received: "Nog te ontvangen",
      To_pay_back: "Terug te betalen",
      PIN: "PIN",
      Cash: "Contant",
      Invoice: "Factuur",
      Accounting: "Boekhouding",
      Continue: "Doorgaan",
      Submit: "Opslaan",
      Create_Invoice: "Factuur maken",
      Close_POS: "Sluit kassa",
      Search_customer: "Zoek klant",
      Search_product: "Zoek product",
      Active: "Actief",
      Search: "Zoeken",
      Apply_Discounts: "Kortingen toepassen",
      Delete: "Verwijder",
      Lock_User: "Gebruiker vergrendelen",
      Start_Time: "Begintijd",
      End_Time: "Eindtijd",
      Select: "Selecteer",
      Tickets: "Tickets",
      Ticket: "Ticket",
      Password: "Wachtwoord",
      Login: "Inloggen",
      Receipt: "Kassabon",
      Title: "Titel",
      Quantity: "Aantal",
      Create: "Toevoegen",
      Information: "Informatie",
      Comment: "Opmerking",
      Reference: "Referentie",
      Unlock_POS: "Kassa ontgrendelen",
      Stock_Locations: "Voorraadlocaties",
      'Withdrawal_&_Deposit': 'Opnames & stortingen',
      'Open_&_Close': 'Openen & sluiten',
      Locations: "Locaties",
      Location: "Locatie",
      General: "Algemeen",
      Start_Amount: "Start bedrag",
      Open_POS: "Kassa openen",
      'Withdrawals_&_Deposits': "Opnames & Stortingen",
      Total_Cash_Payments: "Totaal contante betalingen",
      Total_Cash: "Totaal contant",
      End_Amount: "Eind bedrag",
      Choose: "Kies",
      results: "resultaten",
      Notifications: "Notificaties",
      Notification: "Notificatie",
      Mutations: "Mutaties",
      Mutation: "Mutatie",
      Option: "Optie",
      Options: "Opties",
      Address: "Adres",
      Line: "Lijn",
      Picklists: "Picklijsten",
      Picklist: "Picklijst",
      New_Return: "Nieuwe retour",
      Media: "Media",
      MPN: "MPN",
      SKU: "SKU",
      EAN: "EAN",
      Name: "Naam",
      No_results: "Geen resultaten",
      Loading: "Laden",
      Cashier: "Kassamedewerker",
      Tag: "Tag",
      Categories: "Categorieën",
      Category: "Categorie",
      Descriptions: "Omschrijvingen",
      Description: "Omschrijving",
      Prices: "Prijzen",
      Price: "Prijs",
      Variations: "Variaties",
      Variation: "Variatie",
      History: "Geschiedenis",
      Total: "Totaal",
      selected: "geselecteerd",
      Add_product: "Product toevoegen",
      Groups: "Groepen",
      Templates: "Templates",
      Rules: "Regels",
      Pages: "Pagina's",
      Page: "Pagina",
      Emails: "Emails",
      Webshop: "Webshop",
      Webshops: "Webshops",
      Tags: "Tags",
      Roles: "Rollen",
      Menu: "Menu",
      Menus: "Menu's",
      Items: "Items",
      Mutate: "Muteren",
      Number: "Nummer",
      Date: "Datum",
      Copy_current_product: "Kopieer huidig product",
      Create_new_product: "Maak nieuw product",
      Form: "Formulier",
      Forms: "Formulieren",
      Fields: "Velden",
      Field: "Veld",
      Send_email: "Email versturen",
      Add_option: "Optie toevoegen",
      Scanner: "Scanner",
      Not_Registered: "Niet geregistreerd",
      Create_label: "Label aanmaken",
      Pickup_Point: "Ophaalpunt",
      Post: "Bericht",
      Number_of_products: "Aantal producten",
      Amount_to_be_refunded: "Terug te betalen bedrag",
      Total_Amount: "Totaalbedrag",
      Revenue: "Omzet",
      Months: "Maanden",
      Years: "Jaren",
      untill: "tot",
      Custom: "Aangepast",
      Start_date: "Start datum",
      End_date: "Eind datum",
      Last_week: "Vorige week",
      Last_month: "Vorige maand",
      Purchase: "Inkoop",
      Purchases: "Inkopen",
      Fees: "Kosten",
      orders: "bestellingen",
      ID: 'ID',
      Return_quantity: "Retour aantal",
      Create_return: "Retour maken",
      Close: 'Sluiten',
      Scan_product: "Product scannen",
      Number_of_notifications: "Aantal notificaties",
      Latest_activity: "Laatste activiteit",
      seconds_ago: "{n} seconde geleden | {n} seconden geleden",
      minutes_ago: "{n} minuut geleden | {n} minuten geleden",
      hours_ago: "{n} uur geleden",
      days_ago: "{n} dag geleden | {n} dagen geleden",
      months_ago: "{n} maand geleden | {n} maanden geleden",
      years_ago: "{n} jaar geleden | {n} jaren geleden",
      Previous: "Vorige",
      Link_existing_prduct: "Koppel bestaand product",
      Status: "Status",
      Checked_in: "Ingecheckt",
      Valid: "Geldig",
      Invalid: "Ongeldig",
      Scan_ticket: "Ticket scannen",
      checked_in: "Ingecheckt",
      invalid: "Ongeldig",
      valid: "Geldig",
      fail: "Mislukt",
      success: "Succesvol",
      Check_in: "Inchecken",
      Success: 'Succesvol',
      Failed: "Mislukt",
      jan: 'jan',
      feb: 'feb',
      mar: 'mrt',
      apr: 'apr',
      may: 'mei',
      jun: 'jun',
      jul: 'jul',
      aug: 'aug',
      sep: 'sept',
      oct: 'okt',
      nov: 'nov',
      dec: 'dec',
      january: 'januari',
      february: 'februari',
      march: 'maart',
      april: 'april',
      june: 'juni',
      july: 'juli',
      august: 'augustus',
      september: 'september',
      october: 'oktober',
      november: 'november',
      december: 'december',
      Line_quantity: 'Lijn aantal',
      On_account: 'Op rekening',
      In_cash: "Contant",
      Amount_received_in_cash: "Contant ontvangen bedrag",
      PIN_amount: "PIN bedrag",
      Outstanding_amount: "Openstaand bedrag",
      Sale_number: "Verkoopnummer",
      Cancel_payment: "Betaling annuleren",
      Process_payment: "Betaling verwerken",
      Balance_on_account: "Saldo op rekening",
      Limit_on_account: "Limiet op rekening",
      Confirm_on_account: "Bevestig op rekening",
      Start_PIN_terminal: "Start PIN terminal",
      Search_for_sales_number: "Zoek naar verkoopnummer", 
      Specification_options: "Specificatie opties",
      Open: "Openen",
      Picklist_lines: "Picklijst lijnen",
      Free_space: "Vrije ruimte"
    }
  }
};

const i18n = createI18n({
  legacy: false,
  locale: "eng",
  fallbackLocale: "eng",
  globalInjection: true,
  messages
});

export default i18n;
