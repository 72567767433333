enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  TOKEN_CLEAR = "tokenClear",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",

  // Configuration
  CONFIGURATION = "configuration",
  APPLICATION = "application",

  // Main
  COMPONENTS = "components",
  COMPONENT = "component",

  // SALES
  SALES = "sales",
  SALE = "sale",
  SALE_EDIT = "saleEdit",
  SALE_CREATE = "saleCreate",
  SALE_DELETE = "saleDelete",
  SALE_EMAIL_CONFIRMATION = "SaleEmailConfirmation",
  SALE_APPLY_DISCOUNTS = "applyDiscounts",

    // LINES
    SALE_LINES = "salelines",
    SALE_LINE = "saleline",
    SALE_LINE_CREATE = "createSaleline",
    SALE_LINE_EDIT = "updateSalelines",
    SALE_LINE_DELETE = "saleLineDelete",
    SALE_LINES_UNRETURNED = "SaleLinesUnreturned",
    SALE_LINES_UNPICKED = "salelinesUnpicked",
  
    // PAYMENTS
    PAYMENTS = "payments",
    PAYMENT = "payment",
    PAYMENT_CREATE = "paymentCreate",
    PAYMENT_EDIT = "paymentEdit",
    PAYMENT_DELETE = "paymentDelete",

    INVOICE_PAYMENTS = "invoicePayments",
    INVOICE_PAYMENT = "invoicePayment",
    INVOICE_PAYMENT_CREATE = "invoicePaymentCreate",
    TRANSACTION_INVOICE_EDIT = "transactionInvoiceEdit",

    // INVOICES
    INVOICES = "invoices",
    INVOICE = "invoice",
    INVOICE_CREATE = "invoiceCreate",
    INVOICE_EDIT = "invoiceEdit",
    INVOICE_EMAIL = "invoiceEmail",

    INVOICE_LINES = "invoiceLines",
    INVOICE_LINE = "invoiceLine",

    // QUOTATIONS
    QUOTATIONS = "quotations",
    QUOTATION = "quotation",
    QUOTATION_CREATE = "quotationCreate",
    QUOTATION_EDIT = "quotationEdit",
    QUOTATION_DELETE = "quotationDelete",
    QUOTATION_EMAIL = "quotationEmail",
    QUOTATION_CONVERT = "quotationConvert",

      // LINES
      QUOTATION_LINES = "quotationLines",
      QUOTATION_LINE = "quotationLine",
      QUOTATION_LINE_EDIT = "quotationLineEdit",
      QUOTATION_LINE_CREATE = "quotationLineCreate",
      QUOTATION_LINE_DELETE = "quotationLineDelete",
  
  // CUSTOMERS
  CUSTOMER_DELETE = "customerDelete",
  CUSTOMER_CREATE = "customerCreate",
  CUSTOMER = "customer",
  CUSTOMER_EDIT = "customerEdit",
  CUSTOMERS = "customers",
  CUSTOMER_ADDRESS = "customersAddress",
  CUSTOMERS_ADDRESSES = "customersAddresses",
  CUSTOMER_ADDRESS_CREATE = "customersAddressCreate",
  CUSTOMER_ADDRESS_EDIT = "customersAddressEdit",
  
    // DISCOUNTS
    DISCOUNTS = "discounts",
    DISCOUNT = "discount",
    DISCOUNT_RULES = "discountRules",
    DISCOUNT_CREATE = "discountCreate",
    DISCOUNT_DELETE = "discountDelete",
    DISCOUNT_EDIT = "discountEdit",
    DISCOUNT_RULE = "discountRule",
    DISCOUNT_RULE_EDIT = "discountRuleEdit",
    DISCOUNT_RULE_CREATE = "discountRuleCreate",
    DISCOUNT_RULE_DELETE = "discountRuleDelete",

    // DISCOUNT GROUPS
    DISCOUNT_GROUPS = "discountGroups",
    DISCOUNT_GROUP_CREATE = "discountGroupCreate",
    DISCOUNT_GROUP_EDIT = "discountGroupEdit",
    DISCOUNT_GROUP = "discountGroup",
    DISCOUNT_GROUP_DELETE = "discountGroupDelete",

    // SUBSCRIPTIONS
    SUBSCRIPTIONS = "subscriptions",
    SUBSCRIPTION = "subscription",
    SUBSCRIPTION_CREATE = "subscriptionCreate",
    SUBSCRIPTION_EDIT = "subscriptionEdit",
    SUBSCRIPTION_DELETE = "subscriptionDelete",

    // SUBSCRIPTION LINES
    SUBSCRIPTION_LINES = "subscriptionLines",
    SUBSCRIPTION_LINE = "subscriptionLine",
    SUBSCRIPTION_LINE_CREATE = "subscriptionLineCreate",
    SUBSCRIPTION_LINE_EDIT = "subscriptionLineEdit",
    SUBSCRIPTION_LINE_DELETE = "subscriptionLineDelete",
  
  // PRODUCTS
  PRODUCTS = "products",
  PRODUCT = "product",
  PRODUCT_CREATE = "productCreate",
  PRODUCT_EDIT = "productEdit",
  PRODUCT_DELETE = "productDelete",
  PRODUCT_MEDIUM = "productMediaCurrent",

    // DESCRIPTIONS
    PRODUCT_DESCRIPTIONS = "productDescriptions",
    PRODUCT_DESCRIPTION = "productDescription",
    PRODUCT_DESCRIPTION_EDIT = "productDescriptionEdit",
    PRODUCT_DESCRIPTION_CREATE = "productDescriptionCreate",
    PRODUCT_DESCRIPTION_DELETE = "productDescriptionDelete",

    // SPECIFICATIONS
    PRODUCT_SPECIFICATIONS = "productSpecifications",
    PRODUCT_SPECIFICATION = "productSpecification",
    PRODUCT_SPECIFICATION_CREATE = "productSpecificationCreate",
    PRODUCT_SPECIFICATION_EDIT = "poductSpecificationEdit",
    PRODUCT_SPECIFICATION_DELETE = "productSpecificationDelete",
    PRODUCT_SPECIFICATION_OPTION_CREATE = "productSpecificationOptionCreate",
    PRODUCT_SPECIFICATION_OPTION_EDIT = "productSpecificationOptionEdit",
    PRODUCT_SPECIFICATION_OPTION_DELETE = "productSpecificationOptionDelete",
      PRODUCT_SPECIFICATION_OPTIONS = "productSpecificationOptions",
      PRODUCT_SPECIFICATION_OPTION = "productSpecificationOption",
      PRODUCT_SPECIFICATION_RELATIONS = "productSpecificationRelations",
      PRODUCT_SPECIFICATIONS_PRODUCT = "productSpecificationsProduct",
      PRODUCT_SPECIFICATIONS_RELATION_CREATE = "productSpecificationsCreate",
      PRODUCT_SPECIFICATIONS_RELATION_EDIT = "productSpecificationEdit",
      PRODUCT_SPECIFICATION_OPTION_RELATION_CREATE = "productSpecificationOptionRelationCreate",
      PRODUCT_SPECIFICATION_OPTION_RELATION_DELETE = "productSpecificationOptionRelationDelete",
      PRODUCT_SPECIFICATION_OPTIONS_PRODUCT = "productSpecificationOptionsProduct",

    // VARIATIONS
    PRODUCT_VARIATIONS = "productVariations",
    PRODUCT_VARIATION = "productVariation",
    PRODUCT_VARIATION_CREATE = "productVariationCreate",
    
    // CATEGORIES
    PRODUCTS_CATEGORY = "productsCategory",
    PRODUCTS_CATEGORIES = "productCategories",
    PRODUCTS_CATEGORIES_RELATIONS = "productCategoriesRelations",
    PRODUCTS_CATEGORIES_RELATION_CREATE = "productsCategoriesRelationCreate",
    PRODUCTS_CATEGORIES_RELATION_EDIT = "productsCategoriesRelationEdit",
    PRODUCTS_CATEGORIES_RELATION = "productsCategoriesRelation",
    PRODUCTS_CATEGORY_EDIT = "productsCategoryEdit",
    PRODUCT_CATEGORY_RELATION_DELETE = "productCategoryRelationDelete",

    // CATEGORY SPECIFICATIONS
    PRODUCT_CATEGORY_SPECIFICATIONS = "productCategorySpecifications",
    PRODUCT_CATEGORY_SPECIFICATION = "productCategorySpecification",
    PRODUCT_CATEGORY_SPECIFICATION_CREATE = "productCategorySpecificationCreate",
    PRODUCT_CATEGORY_SPECIFICATION_EDIT = "productCategorySpecificationEdit",
    
    // PRICES
    PRODUCT_PRICES = "productsPrices",
    PRODUCT_PRICE = "productsPrice",
    PRODUCT_PRICE_CREATE = "productPriceCreate",
    PRODUCT_PRICE_EDIT = "productPriceEdit",
    PRODUCT_PRICE_DELETE = "productPriceDelete",
    
    // STOCKS
    PRODUCT_STOCKS = "productsStocks",
    PRODUCTS_STOCK = "productsStock",
    PRODUCTS_STOCK_CREATE = "productsStockCreate",
    PRODUCTS_STOCK_EDIT = "productsStockEdit",

    // MEDIA
    PRODUCT_MEDIA = "productMedia",
    PRODUCT_MEDIA_CREATE = "mediaCreate",
    PRODUCT_MEDIA_EDIT = "mediaEdit",
    PRODUCT_MEDIA_DELETE = "productMediaDelete",

    // STOCKS
    STOCK_LOCATIONS = "stockLocations",
    STOCK_LOCATION = "stockLocation",
    STOCK_LOCATION_CREATE = "stockLocationCreate",
    STOCK_LOCATION_EDIT = "stockLocationsEdit",
    STOCK_LOCATION_DELETE = "stockLocationDelete",
    STOCKS_HISTORY = "stocksHistory",
    STOCK_LOCATION_TYPES = "stockLocationTypes",
    STOCK_LOCATION_TYPE = "stockLocationType",
    STOCK_LOCATION_TYPE_CREATE = "stockLocationTypeCreate",
    STOCK_LOCATION_TYPE_EDIT = "stockLocationTypeEdit",
    STOCK_LOCATION_TYPE_DELETE = "stockLocationTypeDelete",

    // SUPPLIERS
    PRODUCT_SUPPLIERS = "productSuppliers",
    PRODUCT_SUPPLIER = "productSupplier",
    PRODUCT_SUPPLIER_CREATE = "supplierCreate",
    PRODUCT_SUPPLIER_EDIT = "supplierEdit",
    SUPPLIER_DELETE = "supplierDelete",
    PRODUCT_SUPPLIER_RELATIONS = "productSupplierRelations",
    PRODUCT_SUPPLIERS_RELATION_CREATE = "productSuppliersRelationCreate",
    PRODUCT_SUPPLIERS_PRODUCT = "productSuppliersProduct",
    PRODUCT_SUPPLIERS_RELATION = "productSuppliersRelation",

  // SHIPMENTS
  SHIPMENT = "shipment",
  SHIPMENTS = "shipments",
  SHIPMENT_CREATE = "shipmentCreate",
  SHIPMENT_EDIT = "shipmentEdit",
  SHIPMENT_DELETE = "shipmentDelete",

  SHIPMENT_LINES_UNPREPARED = "shipmentLinesUnprepared",
  SHIPMENT_LINES = "shipmentLines",
  SHIPMENT_LINE = "shipmentLine",
  SHIPMENT_LINE_CREATE = "shipmentLineCreate",
  SHIPMENT_LINE_EDIT = "shipmentLineEdit",
  SHIPMENT_LINE_DELETE = "shipmentLineDelete",

  SHIPMENT_PREPARED_LINES = "shipmentPreparedLines",
  SHIPMENT_PREPARED_LINE = "shipmentPreparedLine",
  SHIPMENT_PREPARED_LINE_CREATE = "shipmentPreparedLineCreate",
  SHIPMENT_PREPARED_LINE_EDIT = "shipmentPreparedLineEdit",

  PICKLISTS = "picklists",
  PICKLIST = "picklist",
  PICKLIST_CREATE = "picklistCreate",
  PICKLIST_DELETE = "picklistDelete",
  PICKLIST_EDIT= "picklistEdit",

  PICKLIST_LINE = "picklistLine",
  PICKLIST_LINES = "picklistLines",

  PICKLIST_LINE_DELETE = "picklistLineDelete",
  PICKLIST_LINE_EDIT = "picklistLineEdit",

  // RETURNS
  RETURNS = "returns",
  RETURN = "return",
  RETURN_CREATE = "returnCreate",
  RETURN_EDIT = "returnEdit",
  RETURN_DELETE = "returnDelete",

  RETURN_LINES = "returnLines",
  RETURN_LINES_UNRECEIVED = "returnLinesUnreceived",
  RETURN_LINE = "returnLine",
  RETURN_LINE_EDIT = "returnLineEdit",
  RETURN_LINE_CREATE = "returnLineCreate",

  RETURN_RECEIVED_LINES = "returnReceivedLines",
  RETURN_RECEIVED_LINE = "returnReceivedLine",
  RETURN_RECEIVED_LINE_CREATE = "returnReceivedLineCreate",
  RETURN_RECEIVED_LINE_EDIT = "returnReceivedLineEdit",

  RETURN_RECEIVED_LINES_UNREFUNDED = "returnReceivedLinesUnrefunded",

  RETURN_TRANSACTION_CREATE = "returnTransactionCreate",

  // LABELS
  LABELS = "labels",
  LABEL = "label",
  LABEL_CREATE = "labelCreate",
  LABEL_EDIT = "labelEdit",
  LABEL_DELETE = "labelDelete",
  LABEL_PACKING_SLIP_EMAIL = "labelPackingSlipEmail",
  LABEL_LINES = "labelLines",

  // POS
  POS = "POS",
  POINT_OF_SALE = "PointofSale",
  POS_CREATE = "posCreate",
  POS_EDIT = "posEdit",
  POS_DELETE = "posDelete",

    // CASH MANAGEMENT
    CASH_LOG = "cashLog",
    CASH_LOGS = "cashLogs",
    CASH_LOG_CREATE = "cashLogCreate",
    CASH_LOG_EDIT = "cashLogEdit",
    CASH_LOG_DELETE = "cashLogDelete",

    // CASHIERS
    CASHIER = "cashier",
    CASHIERS = "cashiers",
    CASHIER_CREATE = "cashierCreate",
    CASHIER_EDIT = "cashierEdit",
    CASHIER_DELETE = "cashierDelete",

    // SESSIONS
    POS_SESSIONS = "PosSessions",
    POS_SESSION = "PosSession",
    POS_SESSION_CREATE = "posSessionCreate",
    POS_SESSION_EDIT = "posSessionEdit",
    POS_SESSION_DELETE = "posSessionDelete",

  // MANAGEMENT

    // PROFILES
    PROFILES = "profiles",
    PROFILE = "profile",
    PROFILE_CREATE = "profileCreate",
    PROFILE_EDIT = "profileEdit",
    PROFILE_DELETE = "profileDelete",
    PROFILE_CATEGORIES = "profileCategories",
    PROFILE_CATEGORY_CREATE = "profileCategoryCreate",
    PROFILE_CATEGORY_EDIT = "profileCategoryEdit",
    PROFILE_CATEGORY_DELETE = "profileCategoryDelete",

    // CONNECTIONS
    CONNECTIONS = "connections",
    CONNECTION = "connection",
    CONNECTION_CREATE = "connectionCreate",
    CONNECTION_EDIT = "connectionEdit",
    CONNECTION_DELETE = "connectionDelete",
    CONNECTION_VERIFY = "connectionVerify",

    CONNECTION_PRODUCTS = "connectionProducts",

    CONNECTION_PRODUCT_FILTERS = "connectionProductFilters",
    CONNECTION_PRODUCT_FILTER = "connectionProductFilter",
    CONNECTION_PRODUCT_FILTER_CREATE = "connectionProductFilterCreate",
    CONNECTION_PRODUCT_FILTER_EDIT = "connectionProductFilterEdit",
    CONNECTION_PRODUCT_FILTER_DELETE = "connectionProductFilterDelete",

    CONNECTION_MUTATIONS = "connectionMutations",

    // TRIGGERS
    TRIGGERS = "triggers",
    TRIGGER = "trigger,",
    TRIGGER_CREATE = "triggerCreate",
    TRIGGER_EDIT = "triggerEdit",

    // PICKUP LOCATIONS
    PICKUP_LOCATIONS = "pickupLocations",
    PICKUP_LOCATION = "pickupLocation",
    PICKUP_LOCATION_CREATE = "pickupLocationCreate",
    PICKUP_LOCATION_EDIT = "pickupLocationEdit",
    PICKUP_LOCATION_DELETE = "pickupLocationDelete",

    // USERS
    USERS = "users",
    USER = "user",
    USER_EDIT = "userEdit",
    USER_CREATE = "userCreate",
    USER_DELETE = "userDelete",

      // USER ROLES
      USER_ROLES = "userRoles",
      USER_ROLE = "userRole",
      USER_ROLE_EDIT = "userRoleEdit",
      USER_ROLE_CREATE = "userRoleCreate",
      ROLE_DELETE = "userRoleDelete",

      // USER PERMISSIONS
      USERS_PERMISSIONS = "usersPermissions",
      USERS_PERMISSION = "usersPermission",
      USERS_PERMISSION_EDIT = "usersPermissionEdit",
      USERS_PERMISSION_CREATE = "usersPermissionCreate",

    // TAGS
    TAGS = "tags",
    TAG = "tag",
    TAG_CREATE = "tagCreate",
    TAG_EDIT = "tagEdit",
    TAG_DELETE = "tagDelete",

    // AUTOMATIONS
    AUTOMATION = "automation",
    AUTOMATIONS = "automations",
    AUTOMATION_CREATE = "automationCreate",
    AUTOMATION_EDIT = "automationEdit",
    AUTOMATION_DELETE = "automationDelete",
    AUTOMATION_CONDITIONS = "automationConditions",
    AUTOMATION_CONDITION_CREATE = "automationConditionCreate",
    AUTOMATION_CONDITION_EDIT = "automationConditionEdit",
    AUTOMATION_CONDITION_DELETE = "automationConditionDelete",
    AUTOMATION_CONDITION = "automationCondition",

    AUTOMATION_ACTIONS = "automationActions",
    AUTOMATION_ACTION = "automationAction",
    AUTOMATION_ACTION_CREATE = "automationActionCreate",
    AUTOMATION_ACTION_DELETE = "automationActionDelete",
    AUTOMATION_ACTION_EDIT = "automationActionEdit",


    // ACCOUNT
    ACCOUNT = "account",
    ACCOUNT_EDIT = "accountEdit",

      // SUBSCRIPTIONS
      ACCOUNT_SUBSCRIPTIONS = "accountSubscriptions",
      ACCOUNT_SUBSCRIPTION = "accountSubscription",

      ACCOUNT_SUBSCRIPTION_LINES = "accountSubscriptionLines",

      // INVOICES
      ACCOUNT_INVOICES = "accountInvoices",

      // TASKS
      ACCOUNT_TASKS = "accountTasks",
      ACCOUNT_TASK = "accountTask",

      // LOGS
      ACCOUNT_LOGS = "accountLogs",
      ACCOUNT_LOG = "accountLog",

      // SETTINGS
      SETTING = "setting",
      SETTINGS = "settings",
      SETTING_CREATE = "settingCreate",
      SETTING_EDIT = "settingEdit",

    // TASKS 
    TASKS = "tasks",
    TASK = "task",
    TASK_CREATE = "taskCreate",
    TASK_EDIT = "taskEdit",

    // NOTIFICATION
    NOTIFICATION = "notification",
    NOTIFICATIONS = "notifications",
    NOTIFICATION_CREATE = "notificationCreate",

  // FILES
  FILE = "file",
  FILES = "files",
  FILE_CREATE = "fileCreate",
  FILE_EDIT = "fileEdit",
  FILE_DELETE = "fileDelete",

  // PACKAGES
  PACKAGE = "package",
  PACKAGES = "packages",
  PACKAGE_CREATE = "packageCreate",
  
  // TICKETS
  TICKETS = "tickets",
  TICKET = "ticket",
  TICKET_EDIT = "ticketEdit",
  TICKET_CREATE = "ticketCreate",

  // EMAILS
  EMAIL = "email",
  EMAILS = "emails",
  EMAIL_CREATE = "emailCreate",
  EMAIL_EDIT = "emailEdit",
  EMAIL_DELETE = "emailDelete",
}

enum Mutations {
  // Configuration
  SET_CONFIGURATION = "setConfiguration",
  SET_APPLICATION = "setApplication",
  SET_APPLICATION_STATUS = "setApplicationStatus",

  // Mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  PURGE_POS_TOKENS = "purgePosTokens",
  SET_TOKEN = "setToken",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  RESET_TOOLBAR = "resetToolbar",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",

  SET_TAGS = "setTags",
  SET_COMPONENTS = "setComponents",
  SET_COMPONENT = "setComponent",
  SET_TOOLBAR_ACTIONS = "setToolbarActions",

  // SALES
  SET_SALES = "setSales",
  SET_SALE = "setSale",
  SET_SALES_FILTER = "setSaleFilter",
  SET_SALES_FILTER_ACTIVATED = "setSalesFilterActivated",

  SET_SALE_LINES = "setSaleLines",
  SET_SALE_LINE = "setSaleLine",

  // QUOTATIONS
  SET_QUOTATIONS = "setQuotations",
  SET_QUOTATION = "setQuotation",
  SET_QUOTATIONS_FILTER = "setQuotationsFilter",
  SET_QUOTATION_LINES = "setQuotationLines",
  SET_QUOTATION_LINE = "setQuotationLine",

  // USERS
  SET_USER_ROLES = "setUserRoles",
  SET_USER_ROLE = "setUsersRole",
  SET_USER_ROLES_FILTER = "setUserRolesFilter",
  SET_USER_ROLES_FILTER_ACTIVATED = "setUserRolesFilterActivated",

  SET_STOCK_LOCATION_TYPES_FILTER = "setStockLocationTypesFilter",

  // LABELS
  SET_LABELS_FILTER = "setLabelsFilter",
  SET_LABELS_FILTER_ACTIVATED = "setLabelsFilterActivated",
  SET_LABEL = "setLabel",
  SET_LABELS = "setLabels",

  SET_LABEL_LINES = "setLabelLines",
  
  // INVOICES
  SET_INVOICES = "setInvoices",
  SET_INVOICE = "setInvoice",
  SET_INVOICES_FILTER = "setInvoicesFilter",

  SET_INVOICE_LINES = "setInvoiceLines",
  SET_INVOICE_LINE = "setInvoiceLine",
  SET_INVOICE_LINES_FILTER = "setInvoiceLinesFilter",

  // PAYMENTS
  SET_PAYMENT = "setPayment",
  SET_PAYMENTS = "setPayments",
  SET_PAYMENTS_FILTER = "setPaymentsFilter",
  SET_SALES_PAYMENTS_FILTER_ACTIVATED = "setSalesPaymentsFilterActivated",
  SET_TRANSACTIONS_FILTER = "setTransactionsFilter",

  SET_TRANSACTION_INVOICE = "setTransactionInvoice",
  SET_TRANSACTION_INVOICES =  "setTransactionInvoices",
  SET_TRANSACTION_INVOICES_FILTER = "setTransactionInvoicesFilter",

  // RETURNS
  SET_RETURNS = "setReturns",
  SET_RETURN = "setReturn",
  SET_RETURNS_FILTER = "setReturnsFilter",
  SET_RETURN_LINES = "setReturnLines",
  SET_RETURN_LINE = "setReturnLine",
  SET_RETURN_RECEIVED_LINES = "setReturnReceivedLines",
  SET_RETURN_RECEIVED_LINE = "setReturnReceivedLine",
  SET_RETURN_TRANSACTION = "setReturnTransaction",

  // CUSTOMERS
  SET_CUSTOMERS = "setCustomers",
  SET_CUSTOMERS_FILTER = "setCustomersFilter",
  SET_CUSTOMER = "setRelation",

  SET_C_ADDRESSES = "setCustomersAddresses",
  SET_C_ADDRESS = "setCustomerAddress",
  SET_C_ADDRESSES_FILTER = "setCustomersAddressesFilter",

    // DISCOUNTS
    SET_DISCOUNTS = "setDiscounts",
    SET_DISCOUNT = "setDiscount",
    SET_DISCOUNTS_FILTER = "setDiscountsFilter",

    SET_DISCOUNT_RULES = "setDiscountRules",
    SET_DISCOUNT_RULE = "setDiscountRule",
    SET_DISCOUNT_RULES_FILTER = "setDiscountRulesFilter",

    SET_DISCOUNT_GROUPS = "setDiscountGroups",
    SET_DISCOUNT_GROUP = "setDiscountGroup",
    SET_DISCOUNT_GROUPS_FILTER = "setDiscountGroupsFilter",

    // SUBSCRIPTIONS
    SET_SUBSCRIPTIONS = "setSubscriptions",
    SET_SUBSCRIPTION = "setSubscription",
    SET_SUBSCRIPTIONS_FILTER = "setSubscriptionsFilter",

    SET_SUBSCRIPTION_LINES = "setSubscriptionLines",
    SET_SUBSCRIPTION_LINE = "setSubscriptionLine",
    SET_SUBSCRIPTION_LINES_FILTER = "setSubscriptionLinesFilter",
  
  // TICKETS
  SET_TICKETS = "setTickets",
  SET_TICKET = "setTicket",
  SET_TICKETS_FILTER = "setTicketsFilter",

  // POS
  SET_CASH_LOG = "setCashLog",
  SET_CASH_LOGS = "setCashLogs",
  SET_CASH_LOGS_FILTER = "setCashLogsFilter",
  SET_CASH_LOGS_FILTER_ACTIVATED = "setCashLogsFilterActivated",

  // CASHIERS
  SET_CASHIER = "setCashier",
  SET_CASHIERS = "setCashiers",
  SET_CASHIERS_FILTER = "setCashiersFilter",
  SET_CASHIERS_FILTER_ACTIVATED = "setCashiersFilterActivated",

  // PRODUCTS
  SET_PRODUCT = "setProduct",
  SET_PRODUCTS = "setProducts",
  SET_PRODUCTS_FILTER = "setProductsFilter",
  SET_PRODUCTS_FILTER_ACTIVATED = "setProductsFilterActivated",

  SET_PICKLISTS_FILTER_ACTIVATED = "setPicklistsFilterActivated",

    // SUPPLIERS
    SET_PRODUCT_SUPPLIERS = "setProductSuppliers",
    SET_PRODUCT_SUPPLIER = "setProductSupplier",
    SET_PRODUCT_SUPPLIERS_FILTER = "setProductSuppliersFilter",

    // STOCKS
    SET_PRODUCTS_STOCKS = "setProductsStocks",
    SET_PRODUCTS_STOCK = "setProductsStock",
    SET_PRODUCT_STOCKS_FILTER = "setProductStocksFilter",

    SET_STOCK_LOCATIONS = "setStocksLocations",
    SET_STOCK_LOCATION = "setStocksLocation",
    SET_STOCK_LOCATIONS_FILTER = "setStockLocationsFilter",

    SET_STOCK_LOCATION_TYPES = "setStockLocationTypes",
    SET_STOCK_LOCATION_TYPE = "setStockLocationType",

    SET_STOCKS_HISTORY = "setStocksHistory",

    // SPECIFICATIONS
    SET_PRODUCT_SPECIFICATIONS_FILTER = "setProductSpecificaationsFilter",
    SET_PRODUCT_SPECIFICATIONS = "setProductSpecifications",
    SET_PRODUCT_SPECIFICATION = "setProductSpecification",

    SET_PRODUCT_SPECIFICATION_OPTIONS = "setProductSpecificationOptions",
    SET_PRODUCT_SPECIFICATION_OPTION = "setProductSpecificationOption",

    // VARIATIONS
    SET_PRODUCT_VARIATIONS = "setProductVariations",
    SET_PRODUCT_VARIATION = "setProductVariation",
    SET_PRODUCT_VARIATIONS_FILTER = "setProductVariationsFilter",

    // DESCRIPTIONS
    SET_PRODUCT_DESCRIPTIONS = "setProductDescriptions",
    SET_PRODUCT_DESCRIPTION = "setProductDescription",
    SET_PRODUCT_DESCRIPTIONS_FILTER = "setProductDescriptionsFilter",

    // CATEGORIES
    SET_PRODUCT_CATEGORIES_FILTER = "setProductsCategoriesFilter",
    SET_PRODUCT_CATEGORY = "setProductsCategory",
    SET_PRODUCT_CATEGORIES = "setProductsCategories",

    // CATEGORY SPECIFICATIONS
    SET_PRODUCT_CATEGORY_SPECIFICATIONS = "setProductCategorySpecifications",
    SET_PRODUCT_CATEGORY_SPECIFICATION = "setProductCategorySpecification",
    SET_PRODUCT_CATEGORY_SPECIFICATIONS_FILTER = "setProductCategorySpecificationsFilter",
   
    // PRICES
    SET_PRODUCTS_PRICES = "setProductsPrices",
    SET_PRODUCT_PRICE = "setProductsPrice",
    SET_PRODUCT_PRICES_FILTER = "setProductPricesFilter",

    // MEDIA
    SET_PRODUCT_MEDIA = "setProductMedia",
    SET_PRODUCT_MEDIUM = "setProductMedium",
    SET_PRODUCT_MEDIA_FILTER = "setProductMediasFilter",

  // PROFILES
  SET_PROFILES = "setProfiles",
  SET_PROFILE = "setProfile",
  SET_PROFILES_FILTER = "setProfilesFilter",

    // CATEGORIES
    SET_PROFILE_CATEGORIES = "setProfileCategories",
    SET_PROFILE_CATEGORY = "setProfileCategory",

  // AUTOMATIONS
  SET_AUTOMATION = "setAutomation",
  SET_AUTOMATIONS = "setAutomations",
  SET_AUTOMATIONS_FILTER = "setAutomationFilter",
  SET_AUTOMATION_CONDITIONS = "setAutomationConditions",
  SET_AUTOMATION_CONDITION = "setAutomationCondition",
  SET_AUTOMATION_CONDITIONS_FILTER = "setAutomationConditionsFilter",

  SET_AUTOMATION_ACTION = "setAutomationAction",
  SET_AUTOMATION_ACTIONS = "setAutomationActions",
  SET_AUTOMATION_ACTIONS_FILTER = "setAutomationActionsFilter",

  // SHIPMENTS
  SET_SHIPMENTS = "setShipments",
  SET_SHIPMENT = "setShipment",
  SET_SHIPMENTS_FILTER = "setShipmentsFilter",

  SET_SHIPMENT_LINES = "setShipmentLines",
  SET_SHIPMENT_LINE = "setShipmentLine",

  SET_SHIPMENT_PREPARED_LINES = "setPreparedLines",
  SET_SHIPMENT_PREPARED_LINE = "setPreparedLine",
  SET_SHIPMENT_PREPARED_LINES_FILTER = "setPreparedLinesFilter",

  SET_PICKLISTS = "setPicklists",
  SET_PICKLIST = "setPicklist",
  SET_PICKLISTS_FILTER = "setPicklistsFilter",
  
  SET_PICKLIST_LINES = "setPicklistLines",
  SET_PICKLIST_LINE = "setPicklistLine",
  SET_PICKLIST_LINES_FILTER = "setPicklistLinesFilter",
  
  // CONNECTIONS
  SET_CONNECTIONS = "setConnections",
  SET_CONNECTION = "setConnection",
  SET_CONNECTIONS_FILTER = "setConnectionsFilter",

  SET_CONNECTION_PRODUCT = "setConnectionProduct",
  SET_CONNECTION_PRODUCTS = "setConnectionProducts",
  SET_CONNECTION_PRODUCTS_FILTER = "setConnectionProductsFilter",

  SET_CONNECTION_PRODUCT_FILTERS = "setConnectionProductFilters",
  SET_CONNECTION_PRODUCT_FILTER = "setConnectionProductFilter",
  SET_CONNECTION_PRODUCT_FILTERS_FILTER = "setConnectionProductFiltersFilter",
  
  SET_CONNECTION_MUTATION = "setConnectionMutation",
  SET_CONNECTION_MUTATIONS = "setConnectionMutations",
  SET_CONNECTION_MUTATIONS_FILTER = "setConnectionMutationsFilter",

  // POS
  SET_POS = "setPOS",
  SET_POINT_OF_SALE = "setPointofSale",
  SET_POS_FILTER = "setPOSfilter",

  SET_POS_SESSION = "setPosSession",
  SET_POS_SESSIONS = "setPosSessions",
  SET_POS_SESSIONS_FILTER = "setPosSessionsFilter",
  SET_POS_SESSIONS_FILTER_ACTIVATED = "setPosSessionsFilterActivated",

  // PICKUP LOCATIONS
  SET_PICKUP_LOCATIONS = "setPickupLocations",
  SET_PICKUP_LOCATION = "setPickupLocation",

  // TRIGGERS
  SET_TRIGGERS = "setTriggers",
  SET_TRIGGER = "setTrigger",
  SET_TRIGGERS_FILTER = "setTriggersFilter",

  // TAGS
  SET_TAG = "setTag",
  SET_TAGS_FILTER = "setTagsFilter",

  // USERS
  SET_USERS = "setUsers",
  SET_USERS_FILTER = "setUsersFilter",
  SET_USERS_FILTER_ACTIVATED = "setUsersFilterActivated",
  
  SET_USERS_PERMISSIONS = "setUsersPermissions",
  SET_USERS_PERMISSION = "setUsersPermission",
  
  // FILES  
  SET_FILE = "setFile",
  SET_FILES = "setFiles",

  // PACKAGES
  SET_PACKAGE = "setPackage",
  SET_PACKAGES = "setPackages",
  SET_PACKAGES_FILTER = "setPackagesFilter",

  // EMAILS
  SET_EMAILS = "setEmails",
  SET_EMAIL = "setEmail",
  SET_EMAILS_FILTER = "setEmailsFilter",
  
  // SETTINGS
  SET_SETTING = "setSetting",
  SET_SETTINGS = "setSettings",

  // MANAGEMENT

    // ACCOUNT
    SET_ACCOUNT = "setAccount",

      // SUBSCRIPTIONS
      SET_ACCOUNT_SUBSCRIPTIONS = "setAccountSubscriptions",
      SET_ACCOUNT_SUBSCRIPTION = "setAccountSubscription",
      SET_ACCOUNT_SUBSCRIPTIONS_FILTER = "setAccountSubscriptionsFilter",

      SET_ACCOUNT_SUBSCRIPTION_LINES = "setAccountSubscriptionLines",
      SET_ACCOUNT_SUBSCRIPTION_LINES_FILTER = "setAccountSubscriptionLinesFilter",

      // INVOICES
      SET_ACCOUNT_INVOICES = "setAccountInvoices",
      SET_ACCOUNT_INVOICES_FILTER = "setAccountInvoicesFilter",

      // TASKS 
      SET_ACCOUNT_TASKS = "setAccountTasks",
      SET_ACCOUNT_TASK = "setAccountTask",
      SET_ACCOUNT_TASKS_FILTER = "setAccountTasksFilter",

      // LOGS
      SET_ACCOUNT_LOGS = "setAccountLogs",
      SET_ACCOUNT_LOG = "setAccountLog",
      SET_ACCOUNT_LOGS_FILTER = "setAccountLogsFilter",
    
    // ACCOUNT
    SET_TASKS = "setTasks",
    SET_TASK = "setTask",
    SET_TASKS_FILTER = "setTasksFilter",

    // NOTIFICATIONS
    SET_NOTIFICATIONS = "setNotifications",
    SET_NOTIFICATION = "setNotification",
    SET_NOTIFICATIONS_FILTER = "setNotificationsFilter",
}

export { Actions, Mutations };
