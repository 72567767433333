import { useContentStore } from "@/stores/content";

export type Field = {
  name: string;
  label: string;
  type: string;
  defaultValue: any;
  options: any[];
  config: any;
  validation: string;
  hidden: boolean;
};

export type Rule = {
  field: string;
  value: any;
  operator: string;
  data_source: 'route' | 'form';
};

export type Conditional = {
  targetField: string;
  result: 'show' | 'hide';
  logicType: 'any' | 'all';
  rules: Rule[];
};

export async function applyConditionalRules(fields: Field[], conditionals: Conditional[]): Promise<Field[]> {
  const contentStore = useContentStore();
  if (!conditionals || conditionals.length === 0) {
    return fields;
  }

  try {
    return fields.map(field => {
      const { name } = field;
      const fieldConditional = conditionals.find(conditional => conditional.targetField === name);

      if (fieldConditional) {
        const ruleResults = fieldConditional.rules.map(rule => {

          let result = false;
          if (rule.data_source === undefined || rule.data_source === 'form') {
            const checkField = fields.find(f => f.name === rule.field);
            if (!checkField) return false;
            result = checkField.defaultValue === rule.value;
          } else if (rule.data_source === 'route') {
            result = contentStore.data[rule.field] === rule.value;
          }

          return result;
        });

        let fieldHidden;

        if (fieldConditional.logicType === "any") {
          fieldHidden = !ruleResults.some(result => result);
        } else if (fieldConditional.logicType === "all") {
          fieldHidden = !ruleResults.every(result => result);
        }

        field.hidden = fieldHidden;
      }

      return field;
    });
  } catch (error) {
    console.error(error);
    return fields; // Return original fields in case of error
  }
}