import { defineStore } from "pinia";
import axios from "axios"
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useMenuStore } from "./menus";
import ApiService from "@/core/services/apiServicev3";
import config from "@/core/config/App";

interface RootState {
  app: any
  config: any
  components: any
  isLoading: boolean
  isActive: boolean
}

export const useAppStore = defineStore({
  id: "appStore",
  state: () =>
    ({
      app: null,
      config: null,
      components: null,
      isLoading: false,
      isActive: false
    } as RootState),
  getters: {
    allComponents(state) {
      return state.components;
    },
    getConfig(state) {
      return (name) => {
        let configValue = state.config;

        if (name) {
        const split = name.split('.');
        for (const part of split) {
          configValue = configValue[part] ?? [];
        }
      }
        return configValue;
      };
    },
    searchApiToken(state) {
      return state.app?.search_api_token;
    },
    searchApiEndpoint(state) {
      return state.app?.search_api_url;
    },
    appVersion(state) {
      return state.app?.version;
    },
    appUrl(state) {
      return state.app?.url_app;
    },
  },
  actions: {
    async initApp() {
      // Wait for all promises to complete
      this.isLoading = true;
      await Promise.all([this.application(), this.applicationComponents(), this.applicationConfig()]);
      this.isLoading = false;
    },
    async application() {
      const { data } = await ApiService.get('apiUrl', '/core');
      this.app = data.result.items;
    },
    async applicationConfig() {
      const { data } = await ApiService.get('apiUrl', '/core/config');
      this.config = data.result.items;
    },
    async applicationComponents() {
      const { data } = await ApiService.get('apiUrl', '/components');
      this.components = data.result.items;
    },
    async newTaskStatus(taskId: number, taskStatus = 3): Promise<void> {
      let amountChecks = 0;
      return new Promise<void>((resolve, reject) => {
        const interval = setInterval(async () => {
          amountChecks += 1;
          if (amountChecks === config.taskTotalChecks) {
            clearInterval(interval);
            reject(new Error('Maximum number of checks reached'));
          }

          try {
            const { data } = await ApiService.get('apiUrlSearch', `/indexes/queue_tasks/documents/${taskId}`)
            if (data.task_status === taskStatus) {
              clearInterval(interval);
              resolve();
            }
          } catch (error: any) {
            if (error.response && error.response.status === 404) {
              // If the task is not found (404), increment the amount of checks but keep the interval running for retry.
            } else {
              clearInterval(interval);
              resolve();
            }
          }
        }, config.tasksCheckTimer);
      });
    },
    async taskStatus(taskId: number): Promise<void> {
      let amountChecks = 0;
      return new Promise<void>((resolve, reject) => {
        const interval = setInterval(async () => {
          amountChecks += 1;
          if (amountChecks === config.taskTotalChecks) {
            clearInterval(interval);
            reject(new Error('Maximum number of checks reached'));
          }

          try {
            const { data } = await ApiService.get('apiUrlSearch', `/indexes/queue_tasks/documents/${taskId}`)
            if (data.task_status === 3) {
              clearInterval(interval);
              resolve();
            }
          } catch (error: any) {
            if (error.response && error.response.status === 404) {
              // If the task is not found (404), increment the amount of checks but keep the interval running for retry.
            } else {
              clearInterval(interval);
              resolve();
            }
          }
        }, config.tasksCheckTimer);
      });
    },
    async validateDocument(documentId: number, indexName: string): Promise<any | null> {
      return new Promise<any | null>((resolve, reject) => {
        let amountChecks = 0;
        const interval = setInterval(async () => {
          amountChecks += 1;
          if (amountChecks === config.documentTotalChecks) {
            clearInterval(interval);
            resolve(null); // Resolve with null if payment_url is not found after 100 checks
          } else {
            try {
              const { data } = await ApiService.get('apiUrlSearch', `/indexes/${indexName}/documents/${documentId}`);
              if (data) {
                clearInterval(interval);
                resolve(data); // Resolve with data when the document is found
              }
            } catch (error: any) {
              if (error && error.response.status === 404) {
                return;
              }
              clearInterval(interval);
              reject(error);
            }
          }
        }, config.documentCheckTimer);
      });
    }
  }
});