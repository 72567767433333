import { reactive } from "vue";
import { useAppStore } from "@/stores/app"

export default function useBadge() {

  interface Status {
    colorClass: string
    label: string
    tags: any
  }

  const appStore = useAppStore();
  const badge: Status = reactive({ colorClass: '', label: '', tags: []})

  const createBadge = async (data) => {
    const { fieldData, rowData, tags: tagsConfig } = data;
    const { field } = data.fieldData;
    if (fieldData.type == 'status') {
      const statuses = appStore.getConfig(fieldData.options.link);
      const status = statuses.find(status => status.id === rowData[field]);
      badge.colorClass = status?.class || "badge-light";
      badge.label = status?.label || "";

      if (rowData.tags) {
        const tags = JSON.parse(rowData.tags);

        for (const tag of tags) {
          const config = tagsConfig.find(tagConfig => tagConfig.id === tag);
          badge.tags.push({ label: config.name, colorClass: config.color })
        }
      }
    }
  }

  return {
    badge,
    createBadge
  }
}