import { ref, computed, watch } from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useStore } from "vuex";
import { languages } from 'prismjs';

export default function dateTime() {

    const store = useStore();

    const dateFormat = computed(() => {
        return store.getters.currentUser.date_format;
    });

    const language = computed(() => {
        return store.getters.currentUser.language;
    });

    const timeZone = computed(() => {
        return store.getters.currentUser.timezone;
    });

    function currentDateTime() {
        const test = new Date()
        test.setMilliseconds(0)
        return test.toISOString()
    }

    function showDateTime(data) {
        const lang = 'en';
        if (language.value == 'nld') {
            const lang = 'nl'
        } else if (language.value == 'eng') {
            const lang = 'en'
        }

        require('dayjs/locale/' + lang)
        dayjs.locale(lang)
        dayjs.extend(utc)
        dayjs.extend(timezone)

        dayjs.tz.setDefault(timeZone.value)

        const dateTime = dayjs(data).format(dateFormat.value) 
        // const toolTip = dayjs('2021-06-08T24:00:00').utc('z').local().tz('America/Detroit').format('ddd, MMM D, H:mm z')
        const toolTip = dayjs(data).format('ddd, MMM D, H:mm z')

        const object = {
            date: dateTime,
            tooltip: toolTip,
        }
        return data != null ? object : '';
    }

    return {
        showDateTime,
        currentDateTime
    }
}