import { defineStore } from "pinia";
import generateUniqueId from "@/composables/v3/components/helpers/useGenerateUniqueId";

interface Notification {
  id: string
  title: string;
  message: string;
  isVisible: boolean;
  showLoadingBar: boolean;
  loadingProgress: number;
  type: string;
}

interface RootState {
  notifications: Notification[];
}

export const useContentNotificationsStore = defineStore({
  id: "contentNotificationsStore",
  state: () =>
  ({
    notifications: [],
  } as RootState),
  getters: {
    // modalStatus(state) {
    //   return state.init;
    // },

  },
  actions: {
    async setNotification(title: string, message: string, status: string) {

      const template = {
        id: generateUniqueId(),
        title: title || 'Melding',
        message: message,
        isVisible: true,
        showLoadingBar: false,
        loadingProgress: 0,
        type: `is-${status}`,
      }

      this.notifications.push(template);

      const notification = this.notifications.find(notification => notification.id === template.id);

      if (notification) {
        setTimeout(() => {
          notification.showLoadingBar = true;
        }, 200);

        setTimeout(() => {
          this.deleteNotification(notification);
        }, 4500);
      }


    },
    deleteNotification(notification) {
      notification.isVisible = false;
      this.notifications = this.notifications.filter(item => item.isVisible);
    }
  }
});