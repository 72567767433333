import { defineStore } from "pinia";

interface RootState {
  pickableLines: any
}

export const usePicklistStore = defineStore({
  id: "picklistStore",
  state: () =>
  ({
    pickableLines: []
  } as RootState),
  getters: {
    totalQuantityPickingLines(state) {
      return state.pickableLines.reduce((total, line) => {
        return total + Math.abs(line.pickQuantity);
      }, 0);
    },
    totalQuantityPickableLines(state) {
      return state.pickableLines.length;
    },
    totalQuantityPickableItems(state) {
      return state.pickableLines.reduce((total, line) => {
        return total + Math.abs(line.quantity);
      }, 0);
    },
  },
  actions: {
    async getPickableLines(saleLines: any) {
      const list = [] as any

      for (const line of saleLines) {
        const lineQuantity = parseFloat(line.quantity);
        const pickedQuantity = parseFloat(line.picklist_quantity);
        const totalInclVat = parseFloat(line.net_total_with_vat);

        if (lineQuantity !== pickedQuantity) {

          const item = {
            id: line.id,
            productId: line.product_id,
            name: line.name,
            quantity: lineQuantity,
            price: totalInclVat / lineQuantity,
            pickQuantity: lineQuantity - pickedQuantity,
            range: { min: -pickedQuantity, max: lineQuantity - pickedQuantity },
          }

          list.push(item);
        }
      }

      this.pickableLines = list;
    },
    formatPickableLines(lines) {
      return lines
        .filter(line => line.pickQuantity !== 0)
        .map(line => ({ id: line.id, quantity: `${line.pickQuantity}` }));
    },
  }
});