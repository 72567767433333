import { defineStore } from "pinia";
import axios from "axios"
import { usePOSStore } from "@/views/pos/stores/pos";
import JwtService from "@/core/services/JwtService";
import { Modal } from "bootstrap";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useAuthStore } from "@/stores/auth";

interface RootState {
  users: any
  lock: any
  isLoading: boolean
}

export const useSwitchUsersStore = defineStore({
  id: "switchUsersStore",
  state: () =>
    ({
      users: null,
      lock: { userId: null, state: false, pin: { code1: null, code2: null, code3: null, code4: null } },
      isLoading: false
    } as RootState),
  getters: {
    switchUsersList(state) {
      return state.users;
    },
    selectedUserState(state) {
      return state.lock.state;
    }
  },
  actions: {
    async processSwitchUser() {
      const modal = document.getElementById('es_pos_lock');
      const instance = Modal.getOrCreateInstance(modal);
      instance.toggle();

      this.router.push({ name: 'sign-in' });
    },
    async switchUsers() {
      const posStore = usePOSStore();
    
      const tokenSessions = JSON.parse(JwtService.posTokens());

      if (tokenSessions) {
        const userList = [] as any;

        for (const session of tokenSessions) {
          if (!session.cashierId) continue;

          const user = {
            userId: session.userId,
            cashierId: session.cashierId,
            cashierDn: session.cashierDn,
            token: session.token
          }

          userList.push(user);
        }

        userList.sort((a,b) => b.cashierId === posStore.currentCashierId ? 1 : -1)
        this.users = userList;
      } else {
        this.$reset();
      }
    },
    async processSelectUser(id: number) {
      this.lock.state = true;
      this.lock.userId = id;

      const modal = document.getElementById('es_auth_pincode');
      const instance = Modal.getOrCreateInstance(modal);
      instance.toggle();

      setTimeout(() => {
        document.getElementById('lock_code_1')!.focus();
        }, 500)
     
    },
    async processAccessUser(pinSection, event) {
      const posStore = usePOSStore();
      const authStore = useAuthStore();
      
      const pinValue = Number(this.lock.pin[pinSection]);
      if (isNaN(pinValue) == true) {
        this.lock.pin[pinSection] = null;
      } else {
        const codes = this.lock.pin
        let pinCode = '';
        for (const code in codes) {
          if (codes[code]) {
            pinCode += codes[code]
          }
        }

        if (pinCode.length === 4) {
          const currentUser = this.users.find(user => user.userId === this.lock.userId);
          JwtService.saveToken(currentUser.token);
          this.isLoading = true;
          
          try {
            const status = await posStore.verifyAuthPincode(currentUser.cashierId, pinCode);

            await authStore.verifyAuth();

            if (status?.data.code === 200) {
              const modal = document.getElementById('es_auth_pincode');
              const instance = Modal.getOrCreateInstance(modal);
              instance.toggle();
              this.router.push({ path: '/pos'})
            } else {
              const input = document.getElementById('lock_code_1');
              input?.focus();
            }
          }

          finally {
            this.lock.pin.code1 = null;
            this.lock.pin.code2 = null;
            this.lock.pin.code3 = null;
            this.lock.pin.code4 = null;
            this.isLoading = false;
          }
        } else {
          event.target.nextSibling.focus();
        }
      }
    }
  }
});