import appConfig from "@/core/config/App";
import { useAppStore } from "@/stores/app";

  /**
 * Generates a URL for a given endpoint and contentUid.
 * @param {string} endpointKey - The key of the endpoint from the pdfEndpoints configuration.
 * @param {string} contentUid - The dynamic part of the URL to be replaced.
 * @returns {string} - The full URL with contentUid.
 */
export const getPdfUrl = (endpointKey: string, contentUid: string) => {

  const appStore = useAppStore();

  const endpointTemplate = appConfig.endpoints.pdf[endpointKey];
  
  if (!endpointTemplate) {
    throw new Error(`Endpoint ${endpointKey} not found in configuration.`);
  }

  // TODO: Fix when receipts has its own endpoint. Delete the following addon.
  const parameter = endpointKey === 'sales/receipts' ? '?view=PosReceipt' : '';

  return `${appStore.appUrl}${endpointTemplate}/${contentUid}${parameter}`;
};