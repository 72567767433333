import { defineStore } from "pinia";
import { usePOSStore } from "@/views/pos/stores/pos";
import { useCartStore } from "@/views/pos/stores/cart";
import axios from "axios"
import { fi } from "element-plus/es/locale";
import useApp from "@/composables/v2/useApp";
import { useAppStore } from "@/stores/app";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/apiServicev3";

interface RootState {
  customer: any
  searchResults: any
  searchValue: string | null
  isLoading: boolean
}

export const useCustomerCartStore = defineStore({
  id: "customerCartStore",
  state: () =>
    ({
      customer: null,
      searchResults: [],
      searchValue: null,
      isLoading: true,
    } as RootState),
  getters: {
    customerName(state) {
      return state.customer.customer_label;
    },
    getSearchValue(state) {
      return state.searchValue;
    },
    searchList(state) {
      return state.searchResults;
    },
    currentCustomer(state) {
      return state.customer;
    },
    onAccountStatus(state) {
      return state.customer?.on_account == 1 ? true : false;
    },
    onAccountBalance(state) {
      return Number(state.customer?.on_account_allowed);
    },
    onAccountLimit(state) {
      return Number(state.customer?.on_account_limit);
    },
    onAccountFreeSpace(state) {
      return Number(state.customer?.on_account_limit) - Number(state.customer?.on_account_allowed);
    },
    currentCustomerId(state) {
      return state.customer?.id;
    }
  },
  actions: {
    async initCustomer() {
      this.$reset();
    },
    async getCustomer(customerId: number) {

      if (customerId) {
        const { data } = await ApiService.get('apiUrlSearch', `/indexes/customers/documents/${customerId}`);

        this.customer = data;
        this.searchValue = this.customer.customer_label;
      } else {
        this.$reset();
      }
    },
    async searchCustomer(query: string) {
      const appStore = useAppStore();
      const posStore = usePOSStore();
      this.searchValue = query;
    
      try { 
        const config = {
          headers: { Authorization: `Bearer ${JwtService.getToken()}` }
        };

        const payload = {
          q: this.searchValue,
          sort: [
            "id:desc"
          ],
          matchingStrategy: "all",
          facets: ["*"],
          filter: [[`profile_id=${posStore.currentProfileId} AND customer_status=1`]],
          offset: 0,
        }

        const { data: searchResults } = await axios.post(`${appStore.searchApiEndpoint}/indexes/customers/search`, payload, config);
        this.searchResults = searchResults.hits;
      }
      finally {
        //
      }
    },
    async updateCustomer(customerId: number) {
      const posStore = usePOSStore();
      const cartStore = useCartStore();

      if (customerId) {
        const payload = {
          customer_id: customerId
        }
        
        try {
          cartStore.isLoading = true;
          const task = await cartStore.updateSale(payload);

          if (task) {
            await posStore.checkTaskStatus(task.task_id);
            await cartStore.applyDiscounts(customerId);
            // await cartStore.saleData(cartStore.saleId);
          }
        }
        finally {
          cartStore.isLoading = false;
          cartStore.initCartSession(cartStore.saleId);
        }
      }
    }
  }
});