import { defineStore } from "pinia";
import axios from "axios"
import { themeName } from "@/core/helpers/documentation";
import { useAppStore } from "@/stores/app";
import ApiSearch from "@/core/services/old/ApiSearch";
import ApiService from "@/core/services/apiServicev3";

interface RootState {
  current: any
  data: any
}

export const useInvoicesStore = defineStore({
  id: "invoicesStore",
  state: () =>
    ({
      current: null,
      data: null
    } as RootState),
  getters: {
    currentInvoice(state) {
      return state.current;
    },
    currentOutstandingAmount(state) {
      return state.current?.amount_outstanding;
    },
    allInvoices(state) {
      return state.data; 
    },
    currentInvoiceUid(state) {
      return state.current?.uid;
    }
  },
  actions: {
    async invoice(invoiceId: number) {
      const appStore = useAppStore();
      try {
        const { data } = await ApiService.get('apiUrlSearch', `/indexes/sales_invoices/documents/${invoiceId}`);
        this.current = data;
      }
      finally {
        //
      }
    },
    async invoices(saleId: number) {
      try {
        const payload = {
          filter: [[`sale_id=${saleId}`]],
          sort: [
            "id:desc"
          ],
          matchingStrategy: "all",
          facets: ["*"],
          offset: 0,
        }

        const { data } = await ApiService.post('apiUrlSearch', `/indexes/sales_invoices/search`, payload);

        this.data = data.hits;
      }
      finally {
        //
      }
    }
  }
});