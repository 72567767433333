import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

// import AuthModule from "@/store/modules/AuthModule";
// import AppModule from "@/store/modules/AppModule";

import BodyModule from "@/store/modules/BodyModule";
// import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";

// import TagsModule from "@/store/modules/TagsModule";
// import ComponentsModule from "@/store/modules/ComponentsModule";

// import SalesModule from "@/store/modules/sales/SalesModule";
//   import LinesModule from "@/store/modules/sales/LinesModule";
//   import InvoicesModule from "@/store/modules/sales/InvoicesModule";
//     import InvoicesLinesModule from "@/store/modules/sales/InvoicesLinesModule";
//   import PaymentsModule from "@/store/modules/sales/PaymentsModule";
//   import TransactionsInvoicesModule from "@/store/modules/sales/TransactionsInvoicesModule";
//   import QuotationsModule from "@/store/modules/sales/QuotationsModule";
//     import QuotationsLinesModule from "@/store/modules/sales/QuotationsLinesModule";

// import CustomersModule from "@/store/modules/customers/CustomersModule";
//   import CustomersTicketsModule from "@/store/modules/customers/CustomersTicketsModule";
//   import CustomersDiscountsModule from "@/store/modules/customers/CustomersDiscountsModule";
//     import CustomersDiscountRulesModule from "@/store/modules/customers/CustomersDiscountRulesModule";
//   import CustomersDiscountsGroupsModule from "@/store/modules/customers/CustomersDiscountsGroupsModule";
//   import CustomersAddressesModule from "@/store/modules/customers/CustomersAddressesModule";
//   import CustomersSubscriptionsModule from "@/store/modules/customers/CustomersSubscriptionsModule";
//     import CustomersSubscriptionsLinesModule from "@/store/modules/customers/CustomersSubscriptionsLinesModule";

// import ProductsModule from "@/store/modules/products/ProductsModule";
//   import ProductsDescriptionsModule from "@/store/modules/products/ProductsDescriptionsModule";
//   import ProductsCategoriesModule from "@/store/modules/products/ProductsCategoriesModule";
//     import ProductsCategoriesSpecificationsModule from "@/store/modules/products/ProductsCategoriesSpecificationsModule";
//   import ProductsSpecificationsModule from "@/store/modules/products/ProductsSpecificationsModule";
//     import ProductsSpecificationOptionsModule from "@/store/modules/products/ProductsSpecificationOptionsModule";
//   import ProductsVariationsModule from "@/store/modules/products/ProductsVariationsModule";
//   import ProductsPrices from "@/store/modules/products/ProductsPrices";
//   import ProductsMedia from "@/store/modules/products/ProductsMedia";
//   import ProductsSuppliersModule from "@/store/modules/products/ProductsSuppliersModule";

//   import ProductsStocksModule from "@/store/modules/products/ProductsStocksModule";
//   import ProductsStocksLocationsModule from "@/store/modules/products/ProductsStocksLocationsModule";
//   import ProductsStocksLocationTypesModule from "@/store/modules/products/ProductsStocksLocationTypesModule";
//   import ProductsStocksHistoryModule from "@/store/modules/products/ProductsStocksHistoryModule";

// import ShipmentsModule from "@/store/modules/shipments/ShipmentsModule";
//   import ShipmentsLinesModule from "@/store/modules/shipments/ShipmentsLinesModule";
//   import ShipmentsLinesPreparedModule from "@/store/modules/shipments/ShipmentsLinesPreparedModule";

// import PicklistsModule from "@/store/modules/picklists/PicklistsModule";
//   import PicklistsLinesModule from "@/store/modules/picklists/PicklistsLinesModule";

// import ReturnsModule from "@/store/modules/returns/ReturnsModule";
//   import ReturnsLinesModule from "@/store/modules/returns/ReturnsLinesModule";
//   import ReturnsLinesReceivedModule from "@/store/modules/returns/ReturnsLinesReceivedModule";
//   import ReturnsTransactionsModule from "@/store/modules/returns/ReturnsTransactionsModule";

// import POSCashManagementModule from "@/store/modules/pos/POSCashManagementModule";
// import POSCashiersModule from "@/store/modules/pos/POSCashiersModule";
// import POSSessionsModule from "@/store/modules/pos/POSSessionsModule";

// // Packages
// import PackagesModule from "@/store/modules/shipments/PackagesModule";

// // Labels
// import LabelsModule from "@/store/modules/labels/LabelsModule";
// import LabelsLinesModule from "@/store/modules/labels/LabelsLinesModule";

// // Management
// import ManagementProfilesModule from "@/store/modules/management/ManagementProfilesModule";
//   import ProfilesCategoriesModule from "@/store/modules/management/ProfilesCategoriesModule";
// import ManagementPickupLocationsModule from "@/store/modules/management/ManagementPickupLocationsModule";
// import ManagementPOSModule from "@/store/modules/management/ManagementPOSModule";
// import ManagementConnectionsModule from "@/store/modules/management/ManagementConnectionsModule";
//   import ManagementConnectionsMutationsModule from "@/store/modules/management/ManagementConnectionsMutationsModule";
//   import ManagementConnectionsProductsModule from "@/store/modules/management/ManagementConnectionsProductsModule";
//   import ManagementConnectionsProductsFiltersModule from "@/store/modules/management/ManagementConnectionsProductsFiltersModule";
// import ManagementAutomationsModule from "@/store/modules/management/ManagementAutomationsModule";
// import ManagementAutomationsConditionsModule from "@/store/modules/management/ManagementAutomationsConditionsModule";
// import ManagementAutomationsActionsModule from "@/store/modules/management/ManagementAutomationsActionsModule";
// import ManagementSettingsModule from "@/store/modules/management/ManagementSettingsModule";

// import ManagementAccountSubscriptionsModule from "@/store/modules/management/ManagementAccountSubscriptionsModule";
//   import ManagementAccountSubscriptionLinesModule from "@/store/modules/management/ManagementAccountSubscriptionLinesModule";
// import ManagementAccountInvoicesModule from "@/store/modules/management/ManagementAccountInvoicesModule";
// import ManagementAccountTasksModule from "@/store/modules/management/ManagementAccountTasksModule";
// import ManagementAccountLogsModule from "@/store/modules/management/ManagementAccountLogsModule";
// import ManagementAccountModule from "@/store/modules/management/ManagementAccountModule";

// import TasksModule from "@/store/modules/TasksModule";
// import NotificationsModule from "@/store/modules/NotificationsModule";

// import UsersModule from "@/store/modules/management/UsersModule";
// import UsersRolesModule from "@/store/modules/management/UsersRolesModule";
// import UsersPermissionsModule from "@/store/modules/management/UsersPermissionsModule";

// import FilesModule from "@/store/modules/FilesModule";
// import EmailsModule from "@/store/modules/EmailsModule";

config.rawError = true;

const store = createStore({
  modules: {
    // AuthModule,
    // AppModule,

    // TagsModule,
    // ComponentsModule,

    // SalesModule,
    //   LinesModule,
    //   InvoicesModule,
    //     InvoicesLinesModule,
    //   PaymentsModule,
    //   TransactionsInvoicesModule,
    //   QuotationsModule,
    //     QuotationsLinesModule,


    // CustomersModule,
    //   CustomersAddressesModule,
    //   CustomersDiscountsGroupsModule,
    //   CustomersDiscountsModule,
    //   CustomersDiscountRulesModule,
    //   CustomersSubscriptionsModule,
    //   CustomersSubscriptionsLinesModule,
    //   CustomersTicketsModule,

    // ProductsModule,
    //   ProductsDescriptionsModule,
    //   ProductsCategoriesModule,
    //     ProductsCategoriesSpecificationsModule,
    //   ProductsSpecificationsModule,
    //     ProductsSpecificationOptionsModule,
    //   ProductsVariationsModule,
    //   ProductsPrices,
    //   ProductsMedia,
    //   ProductsSuppliersModule,
    //   ProductsStocksModule,
    //   ProductsStocksLocationsModule,
    //   ProductsStocksLocationTypesModule,
    //   ProductsStocksHistoryModule,

    // PicklistsModule,
    // PicklistsLinesModule,

    // ShipmentsModule,
    //   ShipmentsLinesModule,
    //   ShipmentsLinesPreparedModule,

    // ReturnsModule,
    //   ReturnsLinesModule,
    //   ReturnsLinesReceivedModule,
    //   ReturnsTransactionsModule,
    //   LabelsModule,
    //   LabelsLinesModule,

    // POSCashManagementModule,
    // POSCashiersModule,
    // POSSessionsModule,
    
    // PackagesModule,

    // ManagementProfilesModule,
    // ManagementPickupLocationsModule,
    //   ProfilesCategoriesModule,
    //   ManagementConnectionsModule,
    //     ManagementConnectionsMutationsModule,
    //     ManagementConnectionsProductsModule,
    //     ManagementConnectionsProductsFiltersModule,
    // ManagementPOSModule,
    // ManagementAutomationsModule,
    // ManagementAutomationsConditionsModule,
    // ManagementAutomationsActionsModule,
    // ManagementAccountSubscriptionsModule,
    //   ManagementAccountSubscriptionLinesModule,
    // ManagementAccountInvoicesModule,
    // ManagementAccountTasksModule,
    // ManagementAccountLogsModule,
    // ManagementAccountModule,

    // TasksModule,
    // NotificationsModule,
    
    // UsersModule,
    //   UsersRolesModule,
    //   UsersPermissionsModule,
      
    //   ManagementSettingsModule,

    BodyModule,
    // BreadcrumbsModule,
    ConfigModule,

    // FilesModule,
    // EmailsModule
  }
});

export default store;
