export const formatContentActions = (actions) => {
  const formattedActions = (actions || []).map(action => ({
    name: action.action,
    icon: action.icon,
    label: action.label,
    method: action.method,
    permissions: action.permission,
    isLoading: false
  }));

  return formattedActions;
};
