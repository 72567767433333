import { defineStore } from "pinia";
import { useAppStore } from "./app";

interface RootState {
  data: any | null
  current: any | null
  isLoading: boolean
}

export const useMenuStore = defineStore({
  id: "menuStore",
  state: () =>
    ({
      data: null,
      current: { submenu: { items: null, isLoading: true } },
    } as RootState),
  getters: {
    currentSubmenu(state) {
      return state.current.submenu.items;
    },
    submenuLoading(state) {
      return state.current.submenu.isLoading;
    }
  },
  actions: {
    async generateSubmenu(name: any, check: any) {
      const appStore = useAppStore();
      this.current.submenu.items = null
      this.current.submenu.isLoading = true;
      const menuItems = appStore.getConfig('menus.submenu')[name];
      const newMenu = [] as any;
    
      for (let i = 0; i < menuItems.length; i++) {
        const obj = menuItems[i];
        let addStatus = false;
        if (obj.visible) {
          const fieldData = check[obj.visible.field];
          for (const [key, value] of Object.entries(obj.visible.show)) {
            if (value === fieldData) {
              addStatus = true;
              break;
            }
          }
        } else {
          addStatus = true;
        }
    
        if (addStatus) {
          newMenu.push({
            icon: obj.icon,
            label: obj.label,
            path: { route: name, level: obj.route }
          });
        }
      }
      this.current.submenu.items = newMenu;
      this.current.submenu.isLoading = false;
    }    
  }
});