import { defineStore } from "pinia";
import axios from "axios"
import { useProfileStore } from "@/stores/profiles"
import ApiService from "@/core/services/apiServicev3";
import { useProductStore } from "@/stores/products";
import useApp from "@/composables/v2/useApp";
import { useAppStore } from "../app";
import { useAppComponentsStore } from "../app/components/components";
import posConfig from "@/views/pos/config/pos";

interface RootState {
  data: null
}

export const usePaymentStore = defineStore({
  id: "paymentStore",
  state: () =>
  ({
    data: null
  } as RootState),
  getters: {
  },
  actions: {
    async createPayment(payload): Promise<{ status: string, data: any }> {

      const response = {
        status: 'error',
        data: null
      }

      try {
        const { data } = await ApiService.post("apiUrl", "/sales/payment", payload);

        response.status = 'success';
        response.data = data;

      }
      catch (error) {
        //
      }

      return response;
    }
  }
});