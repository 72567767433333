export const formatConditionals = async (conditionals) => {

  if (conditionals) {
    const data = [] as any;

    for (const conditional of JSON.parse(conditionals)) {
      const template = {
        targetField: conditional.field,
        logicType: conditional.logic_type,
        result: conditional.result,
        rules: conditional.conditionals
      }

      data.push(template)
    }

    return data;
  }
};
