import { defineStore } from "pinia";
import { useProfileStore } from "@/stores/profiles"
import { useContentStore } from "./content";
import ApiService from "@/core/services/apiServicev3";

interface RootState {
  charts: any | null
  isLoading: boolean
}

export const useConnectionsStore = defineStore({
  id: "connectionStore",
  state: () =>
    ({
      charts: null,
      isLoading: false,
    } as RootState),
  getters: {
  },
  actions: {
    async createMutation (type: 'invoice' | 'payment', id: string, connectionId: string) {
      const contentStore = useContentStore();
      const profileStore = useProfileStore();

      const profileId = contentStore.routeData.profile_id;
      const profile = await profileStore.profile(profileId);
      
      const payload = type === 'invoice'
        ? { invoice_id: id, connection_id: profile.default_accounting_id }
        : { invoice_payment_id: id, connection_id: profile.default_accounting_id };
    
      try {
        const { data } = await ApiService.post('apiUrl', '/connections/mutation', payload)
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    }
  }
});