import { ref } from "vue";
import { useContentModalsStore } from "@/stores/content/modals";

export function useModalMouseEvents(modalId: string) {
  const contentModalsStore = useContentModalsStore();
  const isMouseDownInside = ref(false);
  const isScrolling = ref(false);

  const handleMouseDown = (event: MouseEvent) => {
    const modalWrapper = document.querySelector(".eliteswitch-ui-layout-modal-wrapper");
    if (modalWrapper && modalWrapper.contains(event.target as Node)) {
      isMouseDownInside.value = true;
    } else {
      isMouseDownInside.value = false;
    }

    isScrolling.value = false; // Reset scrolling status on mouse down
  };

  const handleMouseMove = () => {
    isScrolling.value = true; // Indicate that scrolling has occurred
  };

  const handleMouseUp = (event: MouseEvent) => {
    if (isMouseDownInside.value || isScrolling.value) {
      return; // Prevent closing if the mouse was down inside or if scrolling occurred
    }
    contentModalsStore.closeModal(modalId);
  };

  return {
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
  };
}