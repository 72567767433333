const posConfig = {
  search: {
    indexName: "products",
    indexType: "search",
    indexLimit: 20,
  },
  amountPincodeNumbers: 4,
  syncAmountTotalChecks: 20,
  taskTotalChecks: 60,
  tasksCheckTimer: 1000,
  validatePaymentCheckTimer: 1000,
  validatePaymentTotalChecks: 60,
  paymentActions: [
    {
      label: "Receipt",
      icon: "printer",
      config: { operation: "directPrint", type: 'receipt', endpoint: 'sales/receipts', check: { state: true, field: 'default_printer_a6_id' } }
    },
    {
      label: "Invoice",
      icon: "printer",
      config: { operation: "directPrint", type: 'invoice', endpoint: 'sales/invoices', check: { state: true, field: 'default_printer_a4_id' } }
    },
    {
      label: "Receipt",
      icon: "tablet-text-up",
      config: { operation: "openReceipt", check: { state: false, field: '' } }
    },
    {
      label: "Invoice",
      icon: "files",
      config: { operation: "openInvoice", check: { state: false, field: '' } }
    },
  ],
  paymentMethods: [
    {
      id: 'pin',
      label: 'PIN',
      icon: 'credit-card',
      isSelected: false,
      isUsable: false
    },
    {
      id: 'cash',
      label: 'In_cash',
      icon: 'bill',
      isSelected: false,
      isUsable: false
    },
    {
      cId: 'on_account',
      id: 'onAccount',
      label: 'On_account',
      icon: 'document',
      isSelected: false,
      isUsable: false
    }
  ],
  shortcuts: [
    {
      label: "New_Sale",
      action: { link: null, process: "createSale" },
      icon: "handcart",
      session: true,
    },
    {
      label: "Return",
      action: { process: "return", method: "modal", target: "es_modal_return_create" },
      icon: "arrow-circle-left",
      session: true,
    },
    {
      label: "Lock_User",
      action: { link: null, process: "lockUser" },
      icon: "lock",
      session: false,
    },
    {
      label: "Unlock_POS",
      action: { link: null, process: "unlockPos" },
      icon: "arrow-circle-left",
      session: false,
    },
    {
      label: "Withdrawal_&_Deposit",
      action: { link: null, process: "withdrawalDeposit" },
      icon: "arrow-right-left",
      session: true,
    },
    {
      label: "Open_&_Close",
      action: { process: "openClose", method: "modal", target: "es_modal_pos_open_close" },
      icon: "calculator",
      session: false,
    }
  ]

}

export default posConfig;