const ID_TOKEN_KEY = "esAuth" as string;
const POS_TOKEN_KEY = "posTokens" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const posTokens = (): any => {
  return window.localStorage.getItem(POS_TOKEN_KEY);
}

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const destroyPosTokens = (): void => {
  window.localStorage.removeItem(POS_TOKEN_KEY);
}

export const savePosToken = (userId: number): void => {
  const currentPosTokens = window.localStorage.getItem(POS_TOKEN_KEY);

  if (!currentPosTokens) {
    window.localStorage.setItem(POS_TOKEN_KEY, JSON.stringify([{ userId: userId, token: getToken() }]))
  } else {
    const storage = JSON.parse(currentPosTokens);

    let exists = false;
    for (const user of storage) {
      if (userId === user.userId) {
        exists = true
      }
    }

    if (!exists) {
      storage.push({ userId: userId, token: getToken() })
      window.localStorage.setItem(POS_TOKEN_KEY, JSON.stringify(storage));
    } else {
      const tokenLine = storage.find(token => token.userId === userId);

      if (tokenLine.token != getToken()) {
        const index = storage.indexOf(tokenLine);
        tokenLine.token = getToken();
        storage[index] = tokenLine;
        window.localStorage.setItem(POS_TOKEN_KEY, JSON.stringify(storage));
      }
    }
  }
}

export const updatePosToken = (userId: number, cashierId: number, cashierDn): void => {
  const currentPosTokens = window.localStorage.getItem(POS_TOKEN_KEY);

  if (currentPosTokens) {
    const storage = JSON.parse(currentPosTokens);
    const tokenLine = storage.find(token => token.userId === userId);

    if (tokenLine) {
      const index = storage.indexOf(tokenLine);
      tokenLine.cashierId = cashierId;
      tokenLine.cashierDn = cashierDn;
      storage[index] = tokenLine
      window.localStorage.setItem(POS_TOKEN_KEY, JSON.stringify(storage))
    }
  }
}

export default { getToken, saveToken, destroyToken, savePosToken, destroyPosTokens, posTokens, updatePosToken };
