export default function useShortcodes() {

  const extractShortcode = (string: string) => {
    const segments = [] as any;
    let index = 0;
  
    while (index < string.length) {
      const atIndex = string.indexOf('@(', index);
      if (atIndex === -1) {
        segments.push(string.slice(index).trim());
        break;
      }
  
      const beforeShortcode = string.slice(index, atIndex).trim();
      if (beforeShortcode) segments.push(beforeShortcode);
  
      let openParentheses = 0;
      let innerIndex = atIndex + 2; // Skip '@('
      let shortcodeEndIndex = -1;
  
      while (innerIndex < string.length) {
        if (string[innerIndex] === '(') {
          openParentheses++;
        } else if (string[innerIndex] === ')') {
          if (openParentheses > 0) {
            openParentheses--;
          } else {
            shortcodeEndIndex = innerIndex + 1;
            break;
          }
        }
        innerIndex++;
      }
  
      if (shortcodeEndIndex === -1) {
        // Invalid shortcode, treat '@(' as normal text
        segments.push(string.slice(atIndex, atIndex + 2).trim());
        index = atIndex + 2;
      } else {
        const shortcode = string.slice(atIndex, shortcodeEndIndex).trim();
        const shortcodeParts = shortcode.slice(2, -1).split('.'); // Remove '@(' and ')' and split by '.'
        segments.push({ type: 'shortcode', shortcodeParts });
        index = shortcodeEndIndex;
      }
    }
  
    return segments;
  }

  function buildString(inputArray, contentStore) {
    let result = "";

    for (let i = 0; i < inputArray.length; i++) {
      const item = inputArray[i];
  
      if (typeof item === "string") {
        result += item;
      } else if (item.type === "shortcode" && Array.isArray(item.shortcodeParts)) {
        const part1 = item.shortcodeParts[0];
        const part2 = item.shortcodeParts[1];
        const shortcodeContent = contentStore[part1][part2];
        result += shortcodeContent;
      }
  
      // Add a space after each item (except for the last one) in the inputArray
      if (i < inputArray.length - 1) {
        result += " ";
      }
    }
  
    return result;
  }

  return {
    extractShortcode,
    buildString
  }
}