import { ref } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { useRouter, useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import { Modal } from "bootstrap";
import { usePOSStore } from "@/views/pos/stores/pos";
import { useToolbarStore } from "@/stores/toolbar";
import { useI18n } from "vue-i18n";
import { useAppStore } from "@/stores/app";

const toolbarStatus = ref(false);
const toolbarInit = ref();
const emailAddress = ref();
const emailAddressOriginal = ref();
const method = ref();
const uid = ref();
const idSelection = ref();
const routeId = ref();
const currentToolbarAction = ref();
const modalRef = ref<null | HTMLElement>(null);
const fieldLoading = ref(false);

export default function useInit() {
  const appStore = useAppStore();
  const toolbarStore = useToolbarStore();
  const posStore = usePOSStore();
  const store = useStore();
  const router = useRouter();
  const reload = ref()
  const { t } = useI18n();
  const componentList = ref([]) as any

  const initTemplate = (data) => {
    componentList.value = data
  }

  const listenTo = async (name) => {
    const template = [] as any
    const array = await componentList.value.map((component) => {

      const components = component.settings.listenTo.components;
      for(let i = 0; i < components.length; i++ ){
        if (typeof template[components[i]] === 'undefined') {
          template[components[i]] = []
        }
        template[components[i]].push(component.name)
      }

      for (const [key, value] of Object.entries(template)) {
        if (key == name) {
          reload.value = value
        }
      }
    })
    reload.value = ''
  }

  const toolbar = async (data) => {
    // Reset fields to default
    emailAddress.value = ''
    emailAddressOriginal.value = ''

    const { init, name, dispatchAction, currentData, currentId } = data
    const action = dispatchAction ? dispatchAction : ''
    const getter = currentData ? currentData : ''

    if (init == true) {
      toolbarInit.value = data;

      if (!currentData) {
        await store.dispatch(Actions[action], currentId);
      }
        store.commit(Mutations.SET_TOOLBAR_ACTIONS, { actions: store.getters.action(name), status: init, uid: store.getters[getter].uid, routeId: Number(currentId) });
        toolbarStatus.value = true
    } else if (init == false) {
      store.commit(Mutations.RESET_TOOLBAR)
      toolbarStatus.value = false
    }
  }

  const getToolbarAction = async (toolbarAction) => {
    currentToolbarAction.value = toolbarAction
    method.value = currentToolbarAction.value.method
    // uid.value = store.getters.getToolbar.uid;
    uid.value = toolbarStore.currentToolbar.uid;
    // routeId.value = store.getters.getToolbar.routeId;
    routeId.value = toolbarStore.currentToolbar.routeId;  

    const { action } = toolbarAction
    const appSettings = store.getters.appMain
    const redirect = { init: false, route: '' }

    let endpoint

    // Set configuration
    if (method.value == "SALE_DELETE") {
      redirect.init = true;
      redirect.route = '/sales'
    } else if(method.value == "QUOTATION_DELETE") {
      redirect.init = true;
      redirect.route = '/sales/quotations'
    } else if (method.value == "SHIPMENT_DELETE") {
      redirect.init = true;
      redirect.route = '/shipments'
    } else if (method.value == "RETURN_DELETE") {
      redirect.init = true;
      redirect.route = '/returns'
    } else if (method.value == "CARRIER_DELETE") {
      redirect.init = true;
      redirect.route = '/management/carriers'
    } else if (method.value == "PRODUCT_DELETE") {
      redirect.init = true;
      redirect.route = '/products'
    } else if (method.value == "CUSTOMER_DELETE") {
      redirect.init = true;
      redirect.route = '/customers'
    } else if (method.value == "DISCOUNT_DELETE") {
      redirect.init = true;
      redirect.route = '/customers/discounts'
    } else if (method.value == "SUBSCRIPTION_DELETE") {
      redirect.init = true;
      redirect.route = '/customers/subscriptions'
    } else if (method.value == "PRODUCT_SPECIFICATION_DELETE") {
      redirect.init = true;
      redirect.route = '/products/specifications'
    } else if (method.value == "SUPPLIER_DELETE") {
      redirect.init = true;
      redirect.route = '/products/suppliers'
    } else if (method.value == "CONNECTION_DELETE") {
      redirect.init = true;
      redirect.route = '/management/connections'
    } else if (method.value == "USER_DELETE") {
      redirect.init = true;
      redirect.route = '/management/users'
    } else if (method.value == "ROLE_DELETE") {
      redirect.init = true;
      redirect.route = '/management/users/roles'
    }

    if (action === 'delete') {
      store.dispatch(Actions[method.value], { id: routeId.value }).then(async() => {
        Swal.fire({
          allowOutsideClick: false,
          text: "Successfully removed",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Continue",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          }
        }).then(() => {
          if (redirect.init == true) {
            router.push({ path: redirect.route });
          }
        })
      }).catch(() => {
        Swal.fire({
          text: t('phrases.generalApiError'),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t('titles.Continue'),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        })
      });
    } else if (action === 'view_pdf') {
      if (method.value == "SALE_PDF_CONFIRMATION") {
        endpoint = "api/sale/pdf/confirmation/" + uid.value
      } else if (method.value == "QUOTATION_PDF") {
        endpoint = "api/sale/pdf/quotation/" + uid.value
      } else if (method.value == "PICKLIST_PDF") {
        endpoint = "api/picklist/pdf/" + uid.value
      } else if (method.value == "LABEL_PDF") {
        endpoint = "api/label/pdf/" + uid.value
      } else if (method.value == "RETURN_SLIP_PDF") {
        endpoint = "api/return/pdf/return-slip/" + uid.value
      } else if (method.value == "SALE_PDF_INVOICE") {
        endpoint = "api/sales/invoice/pdf/" + uid.value
      } else if (method.value == "STOCK_LOCATION_LABEL") {
        endpoint = "api/products/stocks/locations/label/pdf/" + uid.value
      } else {
        return;
      }
      
      window.open(appStore.appUrl + "/" + endpoint, "_blank");
    } else if (action === "send_email") {
      if (method.value == "SALE_EMAIL_CONFIRMATION" || method.value == "QUOTATION_EMAIL" || method.value == "SHIPMENT_LABEL_PACKING_SLIP_EMAIL") {
        // emailAddress.value = await getEmailAddress(toolbarInit.value.dispatchAction, toolbarInit.value.currentId, toolbarInit.value.currentData)
        emailAddress.value = await getEmailAddress(toolbarStore.currentToolbar.dispatchAction, toolbarStore.currentToolbar.routeId, toolbarStore.currentToolbar.currentData)
        emailAddressOriginal.value = emailAddress.value
      }
    } else if (action === 'open_in_pos') {
      router.push({ path: '/pos/' + posStore.currentPOSId + '/checkout/' + routeId.value });
    } else if (action === 'open_accounting') {      
      toolbarStore.showModal = true;

      toolbarStore.refreshKey += 1;

      setTimeout(() => {
        const modal = document.getElementById('es_actions_modal_accounting');
        const instance = Modal.getOrCreateInstance(modal);
        instance.toggle();
      },200)

    } else if (action === 'sale_create') {
      store.dispatch(Actions[method.value], { id: routeId.value }).then(async(data) => {
        Swal.fire({
          allowOutsideClick: false,
          text: "Successfully converted",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: t('titles.Continue'),
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          }
        }).then(() => {
            router.push({ path: '/sales/' + data.item_id });
        })
      }).catch(() => {
        Swal.fire({
          text: t('phrases.generalApiError'),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t('titles.Continue'),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        })
      });
    } else if (action === 'sale_apply_discounts') {
      const toolbarStore = useToolbarStore();

      await toolbarStore.processToolbarAction(action);

      store.dispatch(Actions[method.value], { id: routeId.value }).then(async(data) => {
        Swal.fire({
          allowOutsideClick: false,
          text: "Successfully applied",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: t('titles.Continue'),
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          }
        }).then(() => {
          store.dispatch(Actions.SALE, routeId.value)
        })
      }).catch(() => {
        Swal.fire({
          text: t('phrases.generalApiError'),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t('titles.Continue'),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        })
      });
    } else if (action === 'verify') {
      try {
        store.dispatch(Actions[method.value], { routeId: routeId.value })
      }
      finally {
        //
      }
    }
  }

  const getEmailAddress = async (action, id, get) => {
    fieldLoading.value = true
    let emailAddress;

    idSelection.value = id;

    await store.dispatch(Actions[action], id).then(async() => {    
    const customerId = store.getters[get].customer_id

    emailAddress = await store.dispatch(Actions.CUSTOMER, customerId).then(() => {  
        fieldLoading.value = false
        return store.getters.currentCustomer.email
      })

      return emailAddress
      
    })
    return emailAddress
  }

  const getEmailAddressV2 = async (type, id, uniqueId, fieldWatcher, saveAction, itemId) => {
    fieldLoading.value = true
    method.value = saveAction

    const customerId = id
    uid.value = uniqueId
    idSelection.value = itemId

    const data = await store.dispatch(Actions.CUSTOMER, customerId).then(() => {
      fieldLoading.value = false;
      const fieldData = store.getters.currentCustomer[fieldWatcher]

      return (fieldData == null || fieldData == '')  ? store.getters.currentCustomer.email : fieldData
    })
    
    emailAddress.value = data
  }

  const sentEmail = (email) => {
    // Default payload
    const payload = {
      id: idSelection.value
    }

    if (email != emailAddressOriginal.value) {
      payload['email'] = email;
    }

    //Dispatch action
    store.dispatch(Actions[method.value], payload).then(() => {

      // Hide Modal
      hideModal(modalRef.value)

      Swal.fire({
        allowOutsideClick: false,
        text: "Sent successfully",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: t('titles.Continue'),
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        }
      })
    }).catch(() => {
      Swal.fire({
        text: t('phrases.generalApiError'),
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t('titles.Continue'),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      })
    });
  }

  return {
    initTemplate,
    listenTo,
    componentList,
    reload,
    toolbar,
    getToolbarAction,
    getEmailAddress,
    toolbarStatus,
    emailAddress,
    sentEmail,
    modalRef,
    fieldLoading,
    getEmailAddressV2
  }
}