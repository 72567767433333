import { defineStore } from "pinia";
import ApiService from "@/core/services/apiServicev3";

interface RootState {
  isLoading: boolean
}

export const useSupplierStore = defineStore({
  id: "supplierStore",
  state: () =>
    ({
      isLoading: false,
    } as RootState),
  getters: {
  },
  actions: {
    async getSupplier(supplierId: number) {
      const response = {
        status: 'error',
        data: null
      } as any

      try {

        const { data } = await ApiService.get('apiUrlSearch', `/indexes/products_suppliers/documents/${supplierId}`);

        if (data) {
          response.status = 'success'
          response.data = data;
        }

      } catch (error) {
        console.error(error);
      }

      return response;
    }
  }
});