import config from "@/core/config/App";

export default function priceDisplay(price, source?) {
  // const appConfig = useAppConfig();
  
  // if (isNaN(price) || price === null || price === undefined) {
  //   return 'Prijs niet beschikbaar'
  // } else if (price === 0 && appConfig.app.displayPriceFreeLabel) {
  //   return 'Gratis'
  // }

  // const appStore = useAppStore();

  const style = config.app.displayCurrency
    ? { style: 'currency', currency: config.app.displayCurrency }
    : {};

  const decimals = source === 'cart' ? 2 : (price % 1 === 0 && config.app.displayPriceDecimals !== null) ? config.app.displayPriceDecimals : 2;

  const formatPrice = (price, decimals) => {
    return new Intl.NumberFormat(config.app.displayLocale, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
      ...style,
    }).format(price);
  };

  const formattedPrice = formatPrice(price, decimals);

  // if (source !== 'cart') {
  //   const strippedPrice = formattedPrice.replace(/[\,.]/g, '');

  //   if (strippedPrice === price.toString()) {
  //     return formattedPrice + ',-';
  //   }
  // }

  return formattedPrice;

};