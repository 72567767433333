import { defineStore } from "pinia";
import ApiService from "@/core/services/apiServicev3";

interface RootState {
  default: any
}

export const useWebshopStore = defineStore({
  id: "webshopStore",
  state: () =>
    ({
      //
    } as RootState),
  getters: {
  },
  actions: {
    async rebuildWebshop(webshopId: number) {
      await ApiService.post('apiUrl', 'webshop/rebuild', { webshop_id: webshopId })
    },
  }
});