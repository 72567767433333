import { defineStore } from "pinia";
import { ref } from "vue";
import axios from "axios"
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useMenuStore } from "./menus";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/apiServicev3";
import { useSwitchUsersStore } from "@/views/pos/stores/switchusers";

interface RootState {
  user: null | any
  isAuthenticated: boolean
  reset: any
}

export const useAuthStore = defineStore({
  id: "authStore",
  state: () =>
    ({
      user: null,
      isAuthenticated: false,
      reset: { token: null, password: null, email: null, isLoading: false }
    } as RootState),
  getters: {
    tokenState(state) {
      return state.reset.token != null ? true : false
    },
    resetLoadingState(state) {
      return state.reset.isLoading;
    },
    userLanguage(state) {
      return state.user?.language;
    },
    userId(state) {
      return state.user?.id;
    },
    userTimezone(state) {
      return state.user?.timezone;
    },
    userDateFormat(state) {
      return state.user?.date_format;
    },
    userEmail(state) {
      return state.user?.email;
    },
    userDisplayName(state) {
      return state.user?.name;
    },
    userRole(state) {
      return state.user?.user_role;
    }
  },
  actions: {
    async login(credentials) {
      try {
        const { data } = await ApiService.post("apiUrl", "/user/create-token", credentials);
        this.isAuthenticated = true;
        JwtService.saveToken(data.result.token);

        await this.verifyAuth();
      }
      catch(error) {
        this.purgeAuth();
      }
      finally {
        //
      }
    },
    async logout() {
      try {
        await ApiService.post("apiUrl", "/user/revoke-token");
      }
      finally {
        this.purgeAuth();
        this.purgePosAuth();
      }
    },
    async verifyAuth() {
      if (JwtService.getToken()) {
        // ApiService.setHeader();

        try {
          const { data } = await ApiService.get("apiUrl", '/user');
          this.user = data.result.item;
          this.isAuthenticated = true;
          JwtService.savePosToken(this.user.id);
        }
        catch {
          this.purgeAuth();
        }
        finally {
          //
        }
      } else {
        this.purgeAuth();
      }
    },
    async purgeAuth() {
      this.$reset();
      JwtService.destroyToken();
      this.router.push({ path: '/sign-in'})
    },
    purgePosAuth() {
      const switchUsersStore = useSwitchUsersStore();
      JwtService.destroyPosTokens();
      switchUsersStore.$reset();
    },
    async resetPassword() {
      this.reset.isLoading = true;

      try {
        await axios.post('/user/reset-password', { email: this.reset.email });
      }
      finally {
        this.reset.isLoading = false;
      }
    },
    async newPassword() {
      this.reset.isLoading = true;
      try {
        await axios.post('/user/new-password', { token: this.reset.token, password: this.reset.password });
      }
      finally {
        this.reset.isLoading = false;
      }
    }
  }
});