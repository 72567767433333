import { defineStore } from "pinia";
import ApiService from "@/core/services/apiServicev3";

interface RootState {
  isLoading: boolean
}

export const usePurchaseStore = defineStore({
  id: "purchaseStore",
  state: () =>
    ({
      isLoading: false,
    } as RootState),
  getters: {
  },
  actions: {
    async getPurchaseProductSupplierPrice(productId: number, supplierId: number) {
      const response = {
        status: 'error',
        data: null
      } as any

      try {
        const payload = {
          sort: ['id:desc'],
          matchingStrategy: 'all',
          facets: ['*'],
          filter: [
            [`supplier_id=${supplierId}`],
            [`product_id=${productId}`]
          ],
          offset: 0,
          limit: 20
        };

        const { data } = await ApiService.post('apiUrlSearch', `/indexes/products_suppliers_relations/search`, payload);

        if (data.hits.length) {
          response.status = 'success'
          response.data = data.hits[0];
        }
      } catch (error) {
        console.error(error);
      }

      return response;
    }
  }
});